import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-materialize";

import M from "materialize-css";
import { changePrice } from "../../../redux/action/contracts";
import { notify } from "../../../helpers/helper";

const ChangePrice = ({ selectedContract: scrap , index }) => {
  const [price, setPrice] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.contracts);

  function sendChangePrice() {
    if (!price) return;
    let data = { to: price };
    dispatch(
      changePrice(data, scrap._id, () => {
        notify(
          "Price change request sent to network for approval",
          "cercle-purple"
        );
        let elem = document.getElementById("change-price-modal"+index);
        if (!elem) return;
        let instance = M.Modal.getInstance(elem);
        instance.close();
      })
    );
  }

  return (
    <div>
      {loading && <ProgressBar />}
      <p className="bold mb-1">Change Price</p>

      <div className="flex space-between mb-2">
        <div className="flex gap-1 align-center ">
          <img
            src="../images/scrap.png"
            style={{ width: "50px", height: "50px" }}
            alt="scrap"
          />
          <div className="flex column ">
            <p style={{ textTransform: "uppercase" }}>{scrap?.scrap.name}</p>
            <p className="grey-text">{scrap?.scrap.variant}</p>
          </div>
        </div>

        <div className="green-text flex align-center p-1 light-green  lighten-5  ">
          <p> ₹{scrap?.price?.toFixed(2)} Current Price </p>
        </div>
      </div>

      <p>New Amount :</p>
      <input
        type="number"
        placeholder="Enter New Price"
        className="browser-default input-style "
        onChange={(e) => setPrice(e.target.value)}
        value={price}
        min="0"
      />

      {scrap.variesEvery === 0 && (
        <p style={{ fontSize: 12 }} className="cercle-purple-text">
          Price can be changed anytime
        </p>
      )}
      {scrap.variesEvery !== 0 && (
        <p style={{ fontSize: 13 }} className="grey-text text-darken-1">
          Price can be changed again in {scrap.variesEvery} days time.
        </p>
      )}
      {scrap.priceChangeInProgress && (
        <p style={{ fontSize: 12 }} className="">
          Price change in progress. Awaiting buyer&apos;s response.
        </p>
      )}

      <div className="flex align-center gap-1 justify-end ">
        <button
          className="btn-outline"
          onClick={() => {
            let elem = document.getElementById("change-price-modal"+index);
            let instance = M.Modal.getInstance(elem);
            instance.close();
          }}
        >
          Cancel
        </button>

        <button
          className="btn"
          onClick={sendChangePrice}
          disabled={loading || !price}
        >
          Send Request
        </button>
      </div>
    </div>
  );
};

export default ChangePrice;
