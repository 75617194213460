export const pickupsReducer = (
  state = {
    loading: false,
    availablePickups: [],

    quantityOverTimeLoading: false,
    quantityOverTime: [],

    history: [],
    historyLoading: false,

    pickup: {},
    networkHistory: [],
    networkHistoryLoading: false,

    accepting: false,

    inventory: [],
    loadingInventory: false,

    recycledBatches: [],
    loadingRecycledBatches: false,

    creatingRecycledBatch: false,

    orderLoading: false,
    orders: [],
    order: {},
  },
  action
) => {
  switch (action.type) {
    case "LOADING_PICKUPS":
      return { ...state, loading: true };

    case "STOP_LOADING_PICKUPS":
      return { ...state, loading: false };

    case "GOT_AVAILABLE_PICKUPS":
      return { ...state, loading: false, availablePickups: action.payload };

    case "GOT_PICKUP_HISTORY":
      return { ...state, loading: false, history: action.payload };

    case "GOT_PICKUP":
      return { ...state, loading: false, pickup: action.payload };

    case "CANNOT_GET_AVAILABLE_PICKUPS":
      return { ...state, loading: false };

    case "GETTING_QUANTITY_OVER_TIME":
      return { ...state, quantityOverTimeLoading: true };

    case "GOT_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: false,
        quantityOverTime: action.payload,
      };

    case "CANNOT_GET_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: false,
      };

    case "GETTING_PICKUP_HISTORY_WITH_A_NETWORK":
      return { ...state, networkHistoryLoading: true };

    case "GOT_PICKUP_HISTORY_WITH_A_NETWORK":
      return {
        ...state,
        networkHistoryLoading: false,
        networkHistory: action.payload,
      };

    case "CANNOT_GET_PICKUP_HISTORY_WITH_A_NETWORK":
      return {
        ...state,
        networkHistoryLoading: false,
      };

    case "GETTING_PICKUPS_HISTORY":
      return { ...state, historyLoading: true };

    case "GOT_PICKUPS_HISTORY":
      return {
        ...state,
        historyLoading: false,
        history: action.payload,
      };

    case "CANNOT_GET_PICKUPS_HISTORY":
      return {
        ...state,
        historyLoading: false,
      };

    case "ACCEPTING_PICKUP":
      return { ...state, accepting: true };

    case "ACCEPTED_PICKUP":
      return { ...state, accepting: false };

    case "CANNOT_ACCEPT_PICKUP":
      return { ...state, accepting: false };

    case "GETTING_INVENTORY":
      return { ...state, loadingInventory: true };

    case "GOT_INVENTORY":
      return {
        ...state,
        loadingInventory: false,
        inventory: action.payload,
      };

    case "CANNOT_GET_INVENTORY":
      return {
        ...state,
        loadingInventory: false,
      };

    case "GETTING_RECYCLED_BATCHES":
      return { ...state, loadingRecycledBatches: true };

    case "GOT_RECYCLED_BATCHES":
      return {
        ...state,
        loadingRecycledBatches: false,
        recycledBatches: action.payload,
      };

    case "CANNOT_GET_RECYCLED_BATCHES":
      return {
        ...state,
        loadingRecycledBatches: false,
      };

    case "CREATING_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: true };

    case "CREATED_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: false };

    case "CANNOT_CREATE_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: false };

    case "CREATING_ORDER":
      return { ...state, orderLoading: true };

    case "CREATED_ORDER":
      return { ...state, orderLoading: false };

    case "CANNOT_CREATE_ORDER":
      return { ...state, orderLoading: false };

    case "GETTING_ORDERS":
      return { ...state, orderLoading: true };

    case "GOT_ORDERS":
      return { ...state, orderLoading: false, orders: action.payload };

    case "CANNOT_GET_ORDERS":
      return { ...state, orderLoading: false };

    case "GETTING_SINGLE_ORDER":
      return { ...state, orderLoading: true };

    case "GOT_SINGLE_ORDER":
      return { ...state, orderLoading: false, order: action.payload };

    case "CANNOT_GET_SINGLE_ORDER":
      return { ...state, orderLoading: false };

    default:
      return state;
  }
};
