import React from "react";
import { useNavigate } from "react-router-dom";
import Chips from "./Chips";
import { isTabletOrMobile } from "../../../helpers/helper";
import { finishSetup } from "../../../redux/action/organization";
import { useDispatch } from "react-redux";

const SelectNetworkOptions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="container  first-time-container flex column align-center space-between">
      <div className="flex column align-center">
        <Chips active={5} />
        <p className="bold mt-2 mb-2">Step 5 out of 5 </p>

        <h3>Time for your network!</h3>
        <p className="grey-text" style={{ fontSize: "20px" }}>
          Add your network of waste processors.
        </p>
      </div>

      <div className="dialogue-box">
        <p>How would you like to proceed?</p>

        <div className="flex full-width mt-1 gap-1 justify-center">
          <div
            className="p-1 flex column align-center justify-center
       mr-1 pointer"
            style={{
              width: isTabletOrMobile ? "50%" : "35%",
              height: "100%",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              borderRadius: "5px",
            }}
            role="button"
            onClick={() => navigate("/step5?network=personal")}
          >
            <img
              src={`/images/guy_on_phone.png`}
              alt="guy on phone"
              className="responsive-img"
            />
            <p className="text-center mt-1 cercle-purple-text">
              {" "}
              I have a network of waste processors{" "}
            </p>
          </div>

          <div
            className="p-1 flex column align-center justify-center
       ml-1 pointer"
            style={{
              width: isTabletOrMobile ? "50%" : "35%",
              height: "100%",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              borderRadius: "5px",
            }}
            role="button"
            onClick={() => navigate("/step5?network=external")}
          >
            <img
              src={`/images/guy_on_magnifier.png`}
              alt="guy on phone"
              className="responsive-img"
            />
            <p className="text-center mt-1 cercle-purple-text">
              {" "}
              Choose from our network of verifed and authorized waste processors{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="flex gap-1">
        <button
          className="btn  mb-1 mt-1 first-time-but green"
          type="submit"
          disabled={true}
        >
          Next
        </button>

        <button
          className="btn  mb-1 mt-1 first-time-but white cercle-purple-text"
          type="submit"
          onClick={() => {
            dispatch(
              finishSetup({}, () => {
                window.location.href = "/";
              })
            );
          }}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default SelectNetworkOptions;
