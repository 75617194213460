import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import { Modal } from "react-materialize";
import {
  addLocation,
  deleteLocation,
  getInvitations,
} from "../../../redux/action/organization";
import AddLocation from "../../firstTime/components/AddLocation";
import { toast } from "react-toastify";

function Locations() {
  const { organization, loading } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  const { members } = organization;
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvitations());
  }, [dispatch]);

  const [values, setValues] = React.useState({
    location: {
      lng: "",
      lat: "",
      address: "",
      nickname: "",
    },
  });

  function addNewLocation() {
    dispatch(
      addLocation(values.location, () => {
        const locationModal = document.getElementById("add-location-modal");
        const instance = window.M.Modal.getInstance(locationModal);
        instance.close();
        toast.success("Location Added successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setValues({
          location: {
            nickname: "",
            address: "",
            lat: "",
            lng: "",
          },
        });
      })
    );
  }

  return (
    <div>
      <div className="flex space-between ">
        <p className="bold-title"></p>

        <Modal
          trigger={
            <button
              className="btn-small mt-1"
              disabled={!account.permissions?.includes("add-location")}
            >
              Add New Location
            </button>
          }
          id="add-location-modal"
          actions={[]}
          header="Add New Location"
        >
          <AddLocation
            addLocation={addNewLocation}
            values={values}
            setValues={setValues}
            loading={loading}
          />
        </Modal>
      </div>

      <div className="full-width box mt-2">
        <table
          className={
            !isTablet
              ? "striped  payment-history  full-width"
              : " striped  payment-history small-text full-width isTablet"
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Nickname</th>
              <th>address</th>
              <th>Action </th>
            </tr>
          </thead>

          <tbody>
            {organization.locations?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{item.nickname}</td>

                  <td className="lowercase">{item.address}</td>

                  <td>
                    <Dropdown
                      trigger={
                        <div>
                          <i class="material-symbols-outlined">more_vert</i>
                        </div>
                      }
                    >
                      {/* <span onClick={() => {}}>{"View Location Data"}</span> */}
                      {account.permissions?.includes("remove-location") && (
                        <Modal
                          header="Delete Location"
                          actions={[]}
                          trigger={<span>{"Delete Location"}</span>}
                        >
                          <p>
                            Are you sure you want to delete this location? You
                            will have to re assign all the network associated
                            with this location to another location.
                          </p>

                          <div className="flex gap-1 mt-1">
                            <button
                              className="btn-small"
                              onClick={() => {
                                dispatch(
                                  deleteLocation(item._id, () => {
                                    toast.success(
                                      "Location deleted successfully",
                                      {
                                        position: toast.POSITION.BOTTOM_LEFT,
                                        hideProgressBar: true,
                                        autoClose: 3000,
                                      }
                                    );
                                  })
                                );
                              }}
                            >
                              Yes
                            </button>
                            <Button
                              className="btn-small white purple-text"
                              modal="close"
                            >
                              Cancel
                            </Button>
                          </div>
                        </Modal>
                      )}
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {(members?.length === 0 || !members) && (
          <div className="flex column align-center full-width white  mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "3rem" }}
            >
              group
            </span>
            <p className="grey-text mt-1 ">
              Currently you don&apos;t have any user.
            </p>

            <p className="grey-text">
              You can invite other colleagues to use this dashboard
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Locations;
