import React from "react";
import { Modal } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { IWMURL } from "../redux/config/urlConfig";
import { acceptPrice } from "../redux/action/contracts";
import CounterPrice from "../pages/pickups/components/CounterPrice";

export const DriverAssignedToast = ({ notification }) => {
  return (
    <div className="driver-assigned-toast">
      <p
        className="chart-header cercle-purple-text"
        style={{ fontSize: 16, fontWeight: "bold" }}
      >
        Logistics Assigned
      </p>
      <p className="">
        Driver and Vehicle has been assigned to your pickup. Here are the
        details
      </p>

      {notification?.drivers?.map((driver, index) => {
        return (
          <div key={index}>
            <div className="flex mt-1">
              <img
                src={`${IWMURL}/account/read/photo/${driver._id}`}
                alt="driver"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />

              <div className="ml-1">
                <p>{driver.name}</p>
                <p>{driver.phoneNumber} </p>
              </div>
            </div>
            <div className="flex mt-1" key={index}>
              <img
                src={`${IWMURL}/vehicles/read/image/${notification?.vehicles[index]?._id}`}
                alt="driver"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />

              <div className="ml-1">
                <p>{notification?.vehicles[index]?.vehicleNumber}</p>
                <p>{notification?.vehicles[index]?.capacity} Tonnes</p>
              </div>
            </div>

            <button
              className="btn-small mt-1"
              onClick={() => {
                window.location.href = `/pickup/${notification?.pickup}`;
              }}
            >
              View Pickup
            </button>
          </div>
        );
      })}
    </div>
  );
};

export const PriceChangeToast = ({ notification, closeToast }) => {
  const { loading } = useSelector((state) => state.contracts);
  const dispatch = useDispatch();
  if (!notification) return null;
  const { contract } = notification;

  return (
    <div className="driver-assigned-toast">
      <p
        className="chart-header cercle-purple-text"
        style={{ fontSize: 16, fontWeight: "bold" }}
      >
        Price Change Request
      </p>
      <p className="">{notification.title}</p>

      <div>
        <div className="flex mt-1">
          <img
            src={`${IWMURL}/scraps/read/image/${contract?.scrap?._id}`}
            alt="scrap"
            style={{
              width: "50px",
              height: "50px",
            }}
          />

          <div className="ml-1">
            <p>Old Price: {contract.price} </p>
            <p>New Price: {contract.priceChange?.processor?.to}</p>
          </div>
        </div>

        <div className="flex ">
          <button
            className="btn mt-1"
            onClick={() => {
              dispatch(
                acceptPrice(contract._id, () => {
                  closeToast();
                })
              );
            }}
          >
            {loading ? <ClipLoader color="white" size={20} /> : "Accept"}
          </button>

          <Modal
            id="counter-price-modal"
            trigger={<button className="btn-outline mt-1 ml-1">Counter</button>}
            actions={[]}
            options={{
              onCloseEnd: () => {
                closeToast();
              },
            }}
          >
            <CounterPrice selectedContract={contract} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export const PriceCounterToast = ({ notification, closeToast }) => {
  const { loading } = useSelector((state) => state.contracts);
  const dispatch = useDispatch();
  if (!notification) return null;
  const { contract } = notification;

  return (
    <div className="driver-assigned-toast">
      <p
        className="chart-header cercle-purple-text"
        style={{ fontSize: 16, fontWeight: "bold" }}
      >
        Price Countered
      </p>
      <p className="">{notification.title}</p>

      <div>
        <div className="flex mt-1">
          <img
            src={`${IWMURL}/scraps/read/image/${contract?.scrap?._id}`}
            alt="scrap"
            style={{
              width: "50px",
              height: "50px",
            }}
          />

          <div className="ml-1">
            <p>{notification.body}</p>
          </div>
        </div>

        <div className="flex ">
          <button
            className="btn mt-1"
            onClick={() => {
              dispatch(
                acceptPrice(contract._id, () => {
                  closeToast();
                })
              );
            }}
          >
            {loading ? <ClipLoader color="white" size={20} /> : "Accept"}
          </button>

          <Modal
            id="counter-price-modal"
            trigger={<button className="btn-outline mt-1 ml-1">Counter</button>}
            actions={[]}
            options={{
              onCloseEnd: () => {
                closeToast();
              },
            }}
          >
            <CounterPrice selectedContract={contract} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export const DefaultToast = ({ notification, closeToast }) => {
  if (!notification) return null;
  return (
    <div className="">
      <p
        className="cercle-purple-text"
        style={{ fontSize: 16, fontWeight: "bold" }}
      >
        {notification.title ? notification.title : notification.body}
      </p>
      <p className="">{notification.body}</p>
    </div>
  );
};
