import React from "react";
import { useMediaQuery } from "react-responsive";
function DashboardBox({ title, amount, desc, img }) {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <div
      className={
        isMobile
          ? "flex white p-1 box gap-1 full-width"
          : " flex white p-1 box gap-1  "
      }
    >
      <img src={img} className="dashboard-card-img" alt="bashboard-box" />
      <div className="flex column ">
        <p className="cercle-purple-text">{title}</p>
        <b className="bold">{amount}</b>
        <p className="grey-text"> {desc}</p>
      </div>
    </div>
  );
}

export default DashboardBox;
