import { AuthURL } from "../redux/config/urlConfig";
import { BiUser } from "react-icons/bi";

function UserProfileImage({ userInfo, style, URL }) {
  if (URL) return <div></div>;
  if (!userInfo)
    return (
      <div
        className="purple lighten-5 circle  flex align-center justify-center   "
        style={{ padding: "7px" }}
      >
        <BiUser size={26} color="#6f2da8" />
      </div>
    );

  if (!URL)
    return (
      <div>
        <img
          src="../images/user.png"
          alt="user"
          style={{ height: "90%", width: "110px" }}
        />
      </div>
    );

  return (
    <div>
      <img
        src={`${AuthURL}/account/read/profile-image/${
          userInfo?._id ? userInfo._id : "1"
        }`}
        className="circle"
        alt="user "
        style={style ? style : {}}
        crossOrigin="anonymous"
      />
    </div>
  );
}

export default UserProfileImage;
