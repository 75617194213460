import React from "react";
import { useMediaQuery } from "react-responsive";

function DownloadApp() {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <div className={"flex box p-1  gap-1 mb-2 white flex-wrap"}>
      <div className="flex">
        <img
          src="../images/download-app.png"
          alt="download-app"
          style={{ width: "180px", height: "160px" }}
        />
      </div>

      <div>
        <h3> Download The Wasteknot Apps On Playstore Now</h3>
        <p className="grey-text">Manage industrial waste with just a swipe!</p>
        <p className="grey-text">
          Traceability from its source to the end product
        </p>
        <div className="flex mv-1 gap-1 mt-1 ">
          <a href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsseller">
            <button className="btn-outline flex align-center ">
              <img
                src="../images/android.png"
                style={{ width: "20px", height: "20px", marginRight: "7px" }}
                alt="android"
              />
               Industries App
            </button>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsseller">
            <button className="btn-outline flex align-center ">
              <img
                src="../images/android.png"
                style={{ width: "20px", height: "20px", marginRight: "7px" }}
                alt="android"
              />
              Traders App
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
