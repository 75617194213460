import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmOTP } from "../redux/action/auth";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../modules";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../modules";

function ConfirmOTP() {
  const isTablet = useMediaQuery({ query: "(max-width:1240px)" });
  const dispatch = useDispatch();
  const location = useLocation();
  const { phoneNumber, session } = location.state;
  const navigate = useNavigate();
  const { showLoading } = useSelector((state) => state.loading);
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (OTP === "" || OTP.length < 4) return setOTPError("No OTP Entered.");
    dispatch(confirmOTP(phoneNumber, OTP, session, navigate));
  };

  return (
    <div className="auth-page">
      {/* {showLoading && <Loading />} */}

      <div className="auth-from">
        <form onSubmit={handleSubmit} className="full-width container">
          <div className="flex justify-center">
            <img src="/images/wasteknot_logo.png" alt="logo" className="logo" />
          </div>
          <h4 className="cercle-purple-text text-center">Confirm OTP</h4>
          <p className="grey-text text-center">
            Enter your details below to sign in
          </p>
          <div className="input-field-style">
            <span class="material-symbols-outlined primary">password</span>
            <input
              className="browser-default input-field "
              placeholder="Enter OTP sent to your phone number"
              type="number"
              onChange={(e) => {
                setOTP(e.target.value);
                setOTPError("");
              }}
              min={0}
              value={OTP}
            />
          </div>
          {OTPError && <p className="small-text red-text">{OTPError}</p>}

          <button className="btn full-width mt-1" type="submit">
            Next
          </button>
        </form>
      </div>
    </div>
  );
}

export default ConfirmOTP;
