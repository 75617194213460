import React from "react";
import { Tabs, Tab } from "react-materialize";
import AccountInfo from "./components/AccountInfo";
import ChangePassword from "./components/ChangePassword";
import ChangeMobileNumber from "./components/ChangeMobileNumber";
import NotificationSettings from "./components/NotificationSettings";
import AccountInfoHeader from "./components/AccountInfoHeader";
import { ComponentHeader } from "../../modules";

function Settings() {
  return (
    <div className="p-1  ">
      <div className=" container gap-1 ">
        <ComponentHeader title="Settings" />
        <AccountInfoHeader />
        <div className="white box ">
          <Tabs>
            <Tab title="Account Information" idx="account-info">
              <AccountInfo />
            </Tab>
            <Tab title="Change Password" idx="change-password">
              <ChangePassword />
            </Tab>
            <Tab title="Change Mobile Number" idx="change-mobile-number">
              <ChangeMobileNumber />
            </Tab>
            <Tab title="Notification Settings" idx="notification-settings">
              <NotificationSettings />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Settings;
