import React from "react";
import { BeatLoader } from "react-spinners";

function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="flex justify-center column align-center ">
        <img src="/images/wasteknot_logo.png" alt="logo" className="logo" />
        <p className="bold">Wasteknot Dashboard</p>
        <BeatLoader color="#6f2da8" size={10} />
        <p className=" cercle-purple-text ">Loading...</p>
      </div>
    </div>
  );
}
export default LoadingPage;
