import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Frame from "./layout/Frame";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmOTP, Groups, Login } from "./pages";
import Settings from "./pages/settings/Settings";

import store from "./redux/store";
import PickupsHistory from "./pages/PickupsHistory";
import PickupTimeLine from "./pages/PickupTimeLine";
import PaymentsHistory from "./pages/PaymentHistory";
import 'react-tooltip/dist/react-tooltip.css'
import SinglePickup from "./pages/SinglePickup";
import Dashboard from "./pages/dashboard/Dashboard";
import Pickups from "./pages/pickups/Pickups";
import Reports from "./pages/reports/Reports";
import Organization from "./pages/organization/Organization";
import LoadingPage from "./layout/Loading";
import { getAccount } from "./redux/action/auth";
import { getOrganization } from "./redux/action/organization";
import Step1 from "./pages/firstTime/components/Step1";
import Step2 from "./pages/firstTime/components/Step2";
import Step3 from "./pages/firstTime/components/Step3";
import Step4 from "./pages/firstTime/components/Step4";
import Step5 from "./pages/firstTime/components/Step5";
import Network from "./pages/networks/Network";
import SingleNetwork from "./pages/networks/components/SingleNetwork";
import { notifyError } from "./helpers/helper";
import PickupsReycler from "./pages/pickups/PickupsReycler";
import Inventory from "./pages/inventory/Inventory";
import SingleOrder from "./pages/pickups/components/SingleOrder";
import CreateLot from "./pages/inventory/children/CreateLot";
import Aggregators from "./pages/Aggregators";
import LotHistory from "./pages/inventory/children/LotHistory";
function App() {
  const tokenPresent = localStorage.getItem("__wasteknot");

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        {tokenPresent ? <Authenticated /> : <Unauthenticated />}
      </BrowserRouter>
    </Provider>
  );
}

const Unauthenticated = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/confirm-OTP" element={<ConfirmOTP />} />
    </Routes>
  );
};

const Authenticated = () => {
  const [caseType, setCaseType] = React.useState("loading");

  const dispatch = useDispatch();

  const FirstTime = () => {
    return (
      <Routes>
        <Route path="/" element={<Step1 />} />
        <Route path="/step2" element={<Step2 />} />
        <Route path="/step3" element={<Step3 />} />
        <Route path="/step4" element={<Step4 />} />
        <Route path="/step5" element={<Step5 />} />
      </Routes>
    );
  };

  const AccessDenied = () => {
    return (
      <Routes>
        <Route path="/network-error" element={<AccessDenied />} />
        <Route path="/" element={<AccessDenied />} />
      </Routes>
    );
  };

  const Standard = () => {
    const { organization } = useSelector((state) => state.organization);

    return (
        <Frame>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/pickups"
              element={
                organization.type === "industry" ? (
                  <Pickups />
                ) : (
                  <PickupsReycler />
                )
              }
            />
            <Route path="/pickup/:id" element={<SinglePickup />} />
            <Route path="/timeline/:id" element={<PickupTimeLine />} />
            <Route path="/pickups-history" element={<PickupsHistory />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/payment-history" element={<PaymentsHistory />} />
            <Route path="/setting" element={<Settings />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/order/:id" element={<SingleOrder />} />
            <Route path="/network" element={<Network />} />
            <Route path="/lot-history" element={<LotHistory />} />
            <Route path="/aggregators" element={<Aggregators />} />
            <Route path="/create-lot" element={<CreateLot />} />
            <Route path="/network/:id" element={<SingleNetwork />} />
          </Routes>
        </Frame>
    );
  };

  useEffect(() => {
    dispatch(getAccount());
    dispatch(
      getOrganization((organization) => {
        if (
          organization.type !== "industry" &&
          organization.type !== "recycler"
        ) {
          notifyError(
            "Access Denied, You are not allowed to access this dashboard"
          );

          setTimeout(() => {
            localStorage.removeItem("__wasteknot");
            window.location.reload();
          }, 2000);
          return;
        }

        if (organization.setupComplete) setCaseType("standard");
        else setCaseType("first_time");
      })
    );
  }, [dispatch]);

  return (
    <>
      {((caseType) => {
        switch (caseType) {
          case "standard":
            return <Standard />;

          case "first_time":
            return <FirstTime />;

          case "loading":
            return <LoadingPage />;

          case "somethingWentWrong":
            return <AccessDenied />;

          default:
            return <Standard />;
        }
      })(caseType)}
    </>
  );
};

export default App;
