import React, { useEffect, useState } from "react";
import { Dropdown } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { XAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from "recharts";

import { ClipLoader } from "react-spinners";
import { getMonthlyQuantityOverTime } from "../../../redux/action/analytics";
// import {  } from "react-spinners";

function MainChart() {
  const { monthlyQuantityOverTime, monthlyQuantityOverTimeLoading } =
    useSelector((state) => state.analytics);
  const [number, setNumber] = useState(3);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMonthlyQuantityOverTime(number));
  }, [dispatch, number]);

  return (
    <div className="quantity-channelized-chart p-1 box white">
      <div className="flex space-between align-center">
        <p className="chart-header">
          Monthly Quantity (Tonnes) of Channelized Waste and Number of Pickup
          Requests:
        </p>

        {monthlyQuantityOverTimeLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <Dropdown
            id="monthsDropdown"
            trigger={
              <div className="flex align-center">
                <p
                  className="cercle-purple-text"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Last {number} Months
                </p>
                <span className="material-symbols-outlined cercle-purple-text">
                  arrow_drop_down
                </span>
              </div>
            }
          >
            <span onClick={() => setNumber(3)}>3 Months</span>
            <span onClick={() => setNumber(6)}>6 Months</span>
            <span onClick={() => setNumber(12)}>1 Year</span>
            <span onClick={() => setNumber(24)}>2 Years</span>
          </Dropdown>
        )}
      </div>

      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={monthlyQuantityOverTime}>
          <defs>
            <linearGradient id="colorUv" x1="1" x2="0">
              <stop offset="20%" stopColor="#FF9100" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#FF507D" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="colorUY" x1="1" x2="0">
              <stop offset="20%" stopColor="#fff" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#ebe0f4" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="quantity"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <Area
            type="monotone"
            dataKey="count"
            fillOpacity={1}
            fill="url(#colorUY)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MainChart;
