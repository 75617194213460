import React, { useState } from "react";
import { Dropdown, Modal } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { CompanyLogo } from "../modules";
import { UserProfileImage, Notification } from "../modules";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";
import { socket } from "../redux/config/socket";
import { seenNotifications } from "../redux/action/notifications";
import CodeInputSearch from "../layout/CodeInputSearch";

function Header({ setOpenAside, openAside, userInfo }) {
  const dispatch = useDispatch();
  const [logout, setLogout] = useState(false);
  const logoutUser = () => {
    dispatch(logoutAction());
  };
  const { account } = useSelector((state) => state.account);
  const { organization } = useSelector((state) => state.organization);
  const { profile } = useSelector((state) => state.notifications);

  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <header className="flex align-center space-between  full-width  header">
      <div className="flex align-center  ">
        <div
          className={openAside ? "open-menu-icon pointer" : "menu-icon pointer"}
          onClick={() => setOpenAside(!openAside)}
        >
          {openAside ? (
            <span className="material-symbols-outlined menu-icon icon hover">
              close
            </span>
          ) : (
            <span className="material-symbols-outlined menu-icon icon hover ">
              segment
            </span>
          )}
        </div>
        <div className="company-profile flex mh-1  align-center ">
          <CompanyLogo
            userInfo={userInfo}
            style={{ width: "45px", height: "45px", objectFit: "cover" }}
          />
          <div className="flex column ml-1 justify-start align-start ">
            <b className="capitalize  truncate">{organization?.name}</b>
            <p style={{ color: "#666" }}>{organization.type}</p>
          </div>
        </div>
      </div>

      <div className="flex align-center">
        <div className="flex align-center gap-2 ">
         { organization?.type === "recycler" &&  <Dropdown
            id="search"
            options={{
              alignment: "right",
              closeOnClick:false
            }}
            trigger={
              <div>
                <img
                  src="../images/encrypt.png"
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
            }
            className="user-profile-drop-down "
          ><CodeInputSearch />
            
          </Dropdown>}

          <Dropdown
            options={{
              onCloseEnd: () => {
                setTimeout(() => {
                  dispatch(seenNotifications());
                }, 1000);
              },
            }}
            trigger={
              <div role="button" className="notification-icon">
                {profile.new && (
                  <div className="new-notification-indicator">
                    {profile.count > 99 ? "99+" : profile.count}
                  </div>
                )}
                <span className="material-symbols-outlined primary icon mh-1 cercle-purple-text ">
                  notifications
                </span>
              </div>
            }
            className="header-notification"
          >
            <Notification />
          </Dropdown>
        </div>

        {!isMobile && (
          <Dropdown
            id="Dropdown_7"
            options={{
              alignment: "right",
            }}
            trigger={
              <div className="user-profile">
                <UserProfileImage
                  style={{ width: "45px", height: "45px", objectFit: "cover" }}
                  userInfo={userInfo}
                />
                {!isTablet ? (
                  <div>
                    <p className="ml-1 capitalize">{account?.name}</p>
                    <p
                      className=" ml-1 capitalize grey-text"
                      style={{ fontSize: 13, color: "#666" }}
                    >
                      {
                        organization.members?.find(
                          (member) => member.account?._id === account._id
                        )?.role
                      }
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
            className="user-profile-drop-down "
          >
            <Link to="/setting">
              <div className="flex ml-1 gap-1 ">
                <span className="material-symbols-outlined cercle-purple-text">
                  manage_accounts
                </span>
                <p className="semi-bold cercle-purple-text ">Account Setting</p>
              </div>
            </Link>
            <span>
              <div
                className="flex ml-1 gap-1 "
                href="#modal1"
                node="button"
                onClick={() => setLogout(!logout)}
              >
                <span className="material-symbols-outlined cercle-purple-text">
                  logout
                </span>
                <p className="semi-bold cercle-purple-text ">Logout </p>
              </div>
              <Modal
                id="modal1"
                open={logout}
                options={{
                  onCloseEnd: () => setLogout(false),
                }}
              >
                <b className="normal-size">Confirm Logout </b>
                <p className="semi-bold">Are you sure you want to logout?</p>
                <div className="flex  justify-end gap-2  ">
                  <button className="green btn-small " onClick={logoutUser}>
                    Yes
                  </button>

                  <button
                    className="red btn-small"
                    onClick={() => setLogout(false)}
                  >
                    No
                  </button>
                </div>
              </Modal>
            </span>
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default Header;
