import React, { useState } from "react";
import { RadioGroup } from "react-materialize";
import { Tooltip } from "react-tooltip";

function AddTrack() {
  const [testReport, setTestReport] = useState(false);
  const [addNewTrader, setAddNewTrader] = useState(false);

  return (
    <div>
      <div className="flex align-center space-between ">
        <span className="flex align-center gap-1 ">
          <b className="bold cercle-purple-text ">Add New Track</b>
          <span
            class="material-symbols-outlined grey-text "
            data-tooltip-id={"add-new-track"}
          >
            help
          </span>
          <Tooltip
            id={"add-new-track"}
            style={{ backgroundColor: "#111", width: "50%" }}
          >
            <div>
              <b>Add Single Truck:</b>
              <p>
                Quickly input details for one truck at a time. Enter supplier
                info, quantity, and more using a simple form. Perfect for
                individual truck entries.
              </p>
            </div>
          </Tooltip>
        </span>

        <label
          for="file-input"
          className="vehicle-photo flex align-center gap-1 full-width"
          style={{ height: "40px", width: "25%" }}
        >
          <img src="../images/xlsx_icon.png" style={{ width: "25px" }} />

          <span className="flex align-center gap-1 ">
            <p>Upload xlsx file</p>
            <span
              class="material-symbols-outlined grey-text  "
              style={{
                fontSize: "20px",
              }}
              data-tooltip-id={"upload-xlsx-file"}
            >
              help
            </span>
          </span>
          <Tooltip
            id={"upload-xlsx-file"}
            style={{ backgroundColor: "#111", width: "50%" }}
          >
            <div>
              <b>Add via XLSX File:</b>
              <p>
                Upload an XLSX file to add multiple trucks in one go. Create the
                file with supplier details, and quantities. Effortlessly add
                batches of trucks at once for efficiency.
              </p>
            </div>
          </Tooltip>
        </label>
      </div>
      <b>Select Trader:</b>

      <div className="flex align-start gap-1 full-width ">
        <div className="full-width">
          <select className="icons browser-default input-style">
            <option value="" disabled selected>
              Choose trader
            </option>
            <option value=" Umbrella Corporation" class="left">
              Umbrella Corporation
            </option>
            <option value=" Massive Dynamic (Fringe)" class="left">
              Massive Dynamic (Fringe)
            </option>
            <option value="Globex Corporation (The Simpsons)" class="left">
              Globex Corporation (The Simpsons)
            </option>

            <option value="Soylent Corp (Soylent Green)" class="left">
              Soylent Corp (Soylent Green)
            </option>
          </select>
          {addNewTrader && (
            <input
              className="browser-default input-style "
              type="text"
              placeholder="enter new trader info "
            />
          )}
        </div>
        <span
          class="material-symbols-outlined  hover  grey-text"
          style={{ fontSize: "35px", marginTop: "10px" }}
          onClick={() => setAddNewTrader(!addNewTrader)}
        >
          add_circle
        </span>
      </div>

      <div className="flex align-center gap-2 mt-1 ">
        <div className="full-width">
          {" "}
          <b>Scrap Type</b>
          <select className="icons browser-default input-style  ">
            <option value="" disabled selected>
              Choose scrap type
            </option>
            <option value="Plastic-LDPE" class="left">
              Plastic-LDPE
            </option>
            <option value="Plastic-PP" class="left">
              Plastic-PP
            </option>
            <option value="Plastic-LLDPE" class="left">
              Plastic-LLDPE
            </option>
          </select>
        </div>
        <div className="full-width  ">
          <b>Received Date</b>
          <input type="date" className="browser-default  input-style" />
        </div>
      </div>
      <div className="flex align-center gap-2 mt-1 ">
        <div className="full-width">
          <b>Enter Quantity:</b>
          <input
            type="number"
            className="browser-default input-style "
            placeholder="Enter Quantity"
          />
        </div>

        <div className="full-width">
          <b>Enter Contamination level:</b>
          <input
            type="number"
            className="browser-default input-style "
            placeholder="Enter Contamination level"
          />
        </div>
      </div>

      <div className="flex align-center mt-1 gap-2">
        <div className="full-width">
          <b className="bold title  ">Form of input watse:</b>

          <div>
            <RadioGroup
              label=""
              name=""
              options={[
                {
                  label: "Grinded Plastic Waste",
                  value: "Grinded Plastic Waste",
                },
                {
                  label: "Plastic Waste Bale",
                  value: "Plastic Waste Bale",
                },
              ]}
              value=""
              withGap
            />
          </div>
        </div>
        <div className="full-width">
          <b className="bold title  ">Recycled Content Overview:</b>

          <div>
            <RadioGroup
              label=""
              name=""
              options={[
                {
                  label: "Post Consumer",
                  value: "post-consumer",
                },
                {
                  label: "Post Industrial ",
                  value: "Post-Industrial ",
                },
              ]}
              value=""
              withGap
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div className=" flex gap-2  mt-1">
          <span className="flex   column full-width ">
            <b className="cercle-purple-text">Update Invoices</b>
            <label
              for="file-input"
              className="vehicle-photo flex column full-width"
              style={{ height: "50px", width: "100%" }}
            >
              <span
                class="material-symbols-outlined upload-photo-icon "
                style={{
                  fontSize: "35px",
                }}
              >
                cloud_upload
              </span>
            </label>
            <input
              type="file"
              id="file-input"
              onChange={() => setTestReport(true)}
            />
          </span>

          <span className="flex   column full-width ">
            <b className="cercle-purple-text">Update E-way Bill</b>
            <label
              for="file-input"
              className="vehicle-photo flex column full-width"
              style={{ height: "50px", width: "100%" }}
            >
              <span
                class="material-symbols-outlined upload-photo-icon "
                style={{
                  fontSize: "35px",
                }}
              >
                cloud_upload
              </span>
            </label>
            <input
              type="file"
              id="file-input"
              onChange={() => setTestReport(true)}
            />
          </span>
        </div>
      </div>
      <hr color="#efefef" />
    </div>
  );
}

export default AddTrack;
