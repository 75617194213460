import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { Button } from "react-materialize";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        latitude: 49.2827291,
        longitude: -123.1207375,
      },
    };
    this.showPosition = this.showPosition.bind(this);
  }
  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          mapCenter: {
            longitude: latLng.lng,
            latitude: latLng.lat,
          },
        });

        this.props.setValues({
          ...this.props.values,
          location: {
            longitude: latLng.lng,
            latitude: latLng.lat,
            address,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  getCurrentLocation() {
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }
  }

  showPosition(position) {
    this.setState({
      mapCenter: {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      },
    });

    this.getAddressUsinglatlng(
      position.coords.latitude,
      position.coords.longitude
    );
  }

  moveMarker(e, m) {
    this.setState({
      mapCenter: {
        latitude: m.position.lat(),
        longitude: m.position.lng(),
      },
    });

    this.getAddressUsinglatlng(m.position.lat(), m.position.lng());
  }

  componentDidMount() {
    this.getCurrentLocation();
  }

  getAddressUsinglatlng(lat, lng) {
    let instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];
    instance
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`
      )
      .then((res) => {
        this.setState({ address: res.data.results[0].formatted_address });

        this.props.setValues({
          ...this.props.values,
          location: {
            longitude: lng,
            latitude: lat,
            address: res.data.results[0].formatted_address,
            nickname: this.props.values.location.nickname,
          },
        });
      })
      .catch((ex) => {});
  }

  render() {
    const containerStyle = {
      position: "relative",
      width: "100%",
      height: 300,
      marginTop: "10px",
      boxShadow:
        "4px 4px 8px rgba(0,0,0,0.2),-4px -4px 8px rgba(255,255,255,1)",
    };
    return (
      <div id="googleMaps" className="google-map">
        <div className="flex space-between align-center ">
          <p className="bold">Add A New Location</p>

          <Button
            modal="close"
            className="btn"
            onClick={() => {
              this.props.addLocation();
            }}
            disabled={!this.props.values.location.nickname}
          >
            Save Location
          </Button>
        </div>

        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label htmlFor="">Search Location</label>
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className:
                    "video-form location-search-input browser-default input-style mt-1 select-item",
                })}
                style={{ padding: "5px" }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                        padding: 5,
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        padding: 5,
                      };
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <div className="mt-1">
          <label htmlFor="">Add a Nickname</label>
          <input
            type="text"
            id="id"
            onChange={(e) => {
              this.props.setValues({
                ...this.props.values,
                location: {
                  ...this.props.values.location,
                  nickname: e.target.value,
                },
              });
            }}
            className="browser-default input-style"
            placeholder="Enter A Nickname for the address"
            value={this.props.values.location.nickname}
          />
        </div>

        <Map
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.latitude,
            lng: this.state.mapCenter.longitude,
          }}
          center={{
            lat: this.state.mapCenter.latitude,
            lng: this.state.mapCenter.longitude,
          }}
          containerStyle={containerStyle}
        >
          <Marker
            title={"Your location"}
            position={{
              lat: this.state.mapCenter.latitude,
              lng: this.state.mapCenter.longitude,
            }}
            draggable={true}
            onDragend={this.moveMarker.bind(this)}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo",
})(MapContainer);
