import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { DashboardCard } from "../../modules";
import { ComponentHeader, Notification } from "../../modules";
import { getAnalyticsOverview } from "../../redux/action/analytics.js";
import { getNotifications } from "../../redux/action/notifications.js";
import DashboardPickupHistoryTable from "./components/DashboardPickupsHistory";
import MainChart from "./components/MainChart";
import NextMilestone from "./components/NextMilestone";
import PaymentHistoryTable from "./components/PaymentHistoryTable";
import SustainabilityCredits from "./components/SustainabilityCredits";
import DownloadApp from "./components/DownlonadApp";
import { getReports } from "../../redux/action/analytics.js";

function Dashboard() {
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });
  const { overview } = useSelector((state) => state.analytics);
  const { account } = useSelector((state) => state.account);
  const { organization } = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalyticsOverview());
    dispatch(getNotifications());
    dispatch(getReports());
  }, [dispatch]);

  return (
    <div className="flex ">
      <div className="dashboard-content ">
        <ComponentHeader title="Overview" />

        <div className="flex gap-2  ">
          <div className="flex column gap-2  flex-1">
            <DashboardCard
              title="TOTAL PICKUPS"
              amount={
                overview.totalPickupsThisMonth
                  ? overview.totalPickupsThisMonth + " Pickups"
                  : 0 + " Pickups"
              }
              desc="This Month"
              img="../images/calendar.png"
            />
            {organization.type === "recycler" ? (
              <DashboardCard
                title="QUANTITY RECYCLED"
                amount={
                  overview.totalQuantityRecycled
                    ? overview.totalQuantityRecycled + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="Overall"
                img="../images/calendar.png"
              />
            ) : (
              <DashboardCard
                title="QUANTITY CHANNELIZED"
                amount={
                  overview.totalQuantityChannelized
                    ? overview.totalQuantityChannelized + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="overall"
                img="../images/calendar.png"
              />
            )}
          </div>

          <MainChart />
        </div>

        <div className="mt-2">
          <NextMilestone />
        </div>

        <div className={isTablet ? "column mt-2" : "mt-2"}>
          <PaymentHistoryTable />
        </div>

        {account.permissions?.includes("read-analytics") && (
          <SustainabilityCredits overview={overview} />
        )}

        <div className="mt-2">
          <DashboardPickupHistoryTable />
        </div>

        <div className="mt-2">
          <DownloadApp />
        </div>
      </div>

      {!isTablet && (
        <aside
          className="dashboard-sidebar"
          style={{ overflowY: "auto", position: "fixed", right: "1%" }}
        >
          <Notification />
        </aside>
      )}
    </div>
  );
}

export default Dashboard;
