import axios from "axios";
import { authenticationService, IWMService } from "./config";

function getAuthURL() {
  return `${authenticationService.baseURL}`;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT");
  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

function getIWMURL() {
  return `${IWMService.baseURL}`;
}

function getIWM() {
  let Auth = axios.create(IWMService);
  const IWMToken = localStorage.getItem("__wasteknot");

  if (IWMToken)
    Auth.defaults.headers.common["X-Authentication-Token"] = IWMToken;
  return Auth;
}

export const Auth = getAuth();
export const AuthURL = getAuthURL();
export const IWM = getIWM();
export const IWMURL = getIWMURL();
