import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {
  calculateInvoiceTotal,
  calculateTotal,
  decimalToPercentage,
} from "../../../helpers/helper";
import { ClipLoader } from "react-spinners";

function PaymentHistoryTable({ data }) {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { history, loading } = useSelector((state) => state.pickups);
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    const completed = history.filter((item) => item.completed).slice(-4);
    setCompleted(completed);
  }, [history]);

  return (
    <div
      className={
        isTablet ? "box full-width mv-1 white " : " box full-width white "
      }
    >
      <div className="flex space-between align-center ph-1 ">
        <b className="bold">Payments & Invoices</b>
        {loading ? (
          <ClipLoader color={"#6f2da8"} loading={loading} size={20} />
        ) : (
          <Link to="/payment-history" className="cercle-purple-text">
            View All
          </Link>
        )}
      </div>

      <table className="striped responsive-table">
        <thead>
          <tr>
            <th>Invoice Date</th>
            <th>Price</th>
            <th>GST</th>
            <th>Amount</th>
            <th>TCS</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {completed?.map((item, index) => {
            return (
              <tr key={index} className="tableHover">
                <td>{new Date(item.date).toDateString()}</td>
                <td>Rs. {item.price}/kg</td>
                <td>5%</td>
                <td>Rs. {calculateTotal(item.price, item.quantity)}</td>
                <td>Rs.{item.TCS}</td>
                <td>
                  Rs.
                  {calculateInvoiceTotal(
                    item.price,
                    item.quantity,
                    item.TCS
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {(completed?.length === 0 || !completed) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined
          grey-text
          "
            style={{ fontSize: "5rem" }}
          >
            receipt_long
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any payment.
          </p>
        </div>
      )}
    </div>
  );
}

export default PaymentHistoryTable;
