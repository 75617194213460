import React, { useEffect } from "react";
import { Switch } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { updatePreference } from "../../../redux/action/auth";
import { ClipLoader } from "react-spinners";

function NotificationSettings() {
  const [preferences, setPrefences] = React.useState({
    allowEmailNotifications: false,
    allowPushNotifications: false,
  });

  const { account, loadingAccount } = useSelector((state) => state.account);

  useEffect(() => {
    if (!account.preferences) return;
    setPrefences(account.preferences);
  }, [account]);

  const dispatch = useDispatch();

  return (
    <div className="white p-2 full-width  container">
      <div className="mb-2">
        <p className="bold">Change Your Notifications Settings </p>
        <p className="small-text grey-text">
          Change Your Current Account Notifications Settings
        </p>
        <hr />
      </div>

      <div className="flex mb-2  gap-2 ">
        <p className="mr-2">Enable Push Notifications </p>
        <Switch
          offLabel="Off"
          onChange={function noRefCheck() {
            setPrefences({
              ...preferences,
              allowPushNotifications: !preferences.allowPushNotifications,
            });
          }}
          checked={preferences?.allowPushNotifications}
          onLabel="On"
        />
      </div>

      <div className="flex gap-2 white">
        <p className="mr-2">Enable Email Notifications </p>

        <Switch
          offLabel="Off"
          checked={preferences?.allowEmailNotifications}
          onChange={function noRefCheck() {
            setPrefences({
              ...preferences,
              allowEmailNotifications: !preferences.allowEmailNotifications,
            });
          }}
          onLabel="On"
        />
      </div>

      <div className="flex justify-end " > 
      <button
        className="btn-small mt-2"
        disabled={
          account.preferences?.allowEmailNotifications ===
            preferences.allowEmailNotifications &&
          account.preferences?.allowPushNotifications ===
            preferences.allowPushNotifications
        }
        onClick={() => dispatch(updatePreference(preferences))}
      >
        {loadingAccount ? (
          <ClipLoader color={"#fff"} size={20} />
        ) : (
          "Save Changes"
        )}
      </button>
      </div>
    </div>
  );
}

export default NotificationSettings;
