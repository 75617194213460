import { IWM } from "../config/urlConfig";
import { toast } from "react-toastify";
import { handleError } from "../../helpers/helper";

export const getOverView = () => async (dispatch) => {
  dispatch({ type: "GETTING_OVERVIEW" });
  IWM.get("/pickup/read/overview")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: "GOT_OVERVIEW", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_OVERVIEW" });
      handleError(error);
    });
};





export const addGroup = (group) => async (dispatch) => {
  dispatch({ type: "ADD_GROUP_BGIN" });
  IWM.post("/group/create/", group)
    .then((res) => {
      dispatch({ type: "ADD_GROUP_SUCCESS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "ADD_GROUP_ERROR" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getReports = () => async (dispatch) => {
  dispatch({ type: "GETTING_REPORTS" });
  IWM.get("/pickup/read/reports")
    .then((res) => {
      dispatch({ type: "GOT_REPORTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_REPORTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};
