import React from "react";
import isEmpty from "lodash/isEmpty";
import DT from "date-and-time";
import { Modal, Button } from "react-materialize";

function Contract({ index, contract }) {
  if (isEmpty(contract)) return;

  return (
    <div className="box contract p-1 white  " key={index}>
      <div className="flex space-between">
        <img
          src="../images/contract.png"
          alt="contract"
          className="contract-img"
        />
        <div>
          <p className="semi-bold primary">EXPIRES IN</p>
          <p className="bold-title">
            {DT.subtract(new Date(contract.end), new Date())
              .toDays()
              ?.toFixed(0)}{" "}
            Days
          </p>
        </div>
      </div>

      <div className="mv-1">
        <b className="primary">Waste Generator</b>
        <p>{contract?.generator?.name}</p>
      </div>
      <div className="mv-1">
        <b className="primary">Waste Processor</b>
        <p>{contract?.processor?.name}</p>
      </div>
      <div className="mv-1">
        <b className="primary">Start Date</b>
        <p>{new Date(contract.start).toDateString()}</p>
      </div>
      <div className="mv-1">
        <b className="primary">End Date</b>
        <p>{new Date(contract.end).toDateString()}</p>
      </div>
      <div className="flex justify-center ">
        <Modal
        className='white'
          actions={[
            <Button flat modal="close" node="button" waves="green">
              Close
            </Button>,
          ]}
          bottomSheet={false}
          fixedFooter
          id="Modal-12"
          open={false}
          options={{
            dismissible: true,
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
          }}
          trigger={
            <button node="button" className="btn-outline">
              View Contract Details
            </button>
          }
        >
        
        </Modal>
      </div>
    </div>
  );
}

export default Contract;
