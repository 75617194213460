import { IWM } from "../config/urlConfig";

export const getAllScraps = () => async (dispatch) => {
  dispatch({ type: "GETTING_ALL_SCRAPS" });
  IWM.get("/scraps/read")
    .then((res) => {
      dispatch({ type: "GOT_ALL_SCRAPS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ALL_SCRAPS" });
    });
};




