const initialState = {
  group: {},
  loadingGroup: false,
  locations: [],
  loadingGroupVehicles: false,
  vehicles: [],
  drivers: [],
  overview: {},
  accounts: [],
  reports: {},
  metrics: {},
  pickupsHistory: [],
  notifications: [],
  loadingGroupReports: false,
  loadingGroupAccounts: false,
  loadingGroupOverview: false,
  loadingGroupDrivers: false,
  loadingGroupMetrics: false,
  loadingGroupPickupsHistory: false,
  loadingLocations: false,
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case "GETTING_GROUP":
      return { ...state, loadingGroup: true };

    case "GOT_GROUP":
      return { ...state, group: action.payload, loadingGroup: false };

    case "CANNOT_GET_GROUP":
      return { ...state, loadingGroup: false };

    case "GETTING_LOCATIONS":
      return { ...state, loadingLocations: true };

    case "GOT_LOCATIONS":
      return { ...state, locations: action.payload, loadingLocations: false };

    case "CANNOT_GET_LOCATIONS":
      return { ...state, loadingLocations: false };

    case "GETTING_GROUP_VEHICLES":
      return { ...state, loadingGroupVehicles: true };

    case "GOT_GROUP_VEHICLES":
      return {
        ...state,
        vehicles: action.payload,
        loadingGroupVehicles: false,
      };

    case "CANNOT_GET_GROUP_VEHICLES":
      return { ...state, loadingGroupVehicles: false };

    case "GETTING_GROUP_DRIVERS":
      return { ...state, loadingGroupDrivers: true };

    case "GOT_GROUP_DRIVERS":
      return { ...state, drivers: action.payload, loadingGroupDrivers: false };

    case "CANNOT_GET_GROUP_DRIVERS":
      return { ...state, loadingGroupDrivers: false };

    case "GETTING_GROUP_OVERVIEW":
      return { ...state, loadingGroupOverview: true };

    case "GOT_GROUP_OVERVIEW":
      return {
        ...state,
        overview: action.payload,
        loadingGroupOverview: false,
      };

    case "CANNOT_GET_GROUP_OVERVIEW":
      return { ...state, loadingGroupOverview: false };

    case "GETTING_GROUP_ACCOUNTS":
      return { ...state, loadingGroupAccounts: true };

    case "GOT_GROUP_ACCOUNTS":
      return {
        ...state,
        accounts: action.payload,
        loadingGroupAccounts: false,
      };

    case "CANNOT_GET_GROUP_ACCOUNTS":
      return { ...state, loadingGroupAccounts: false };

    case "GETTING_GROUP_REPORTS":
      return { ...state, loadingGroupReports: true };

    case "GOT_GROUP_REPORTS":
      return {
        ...state,
        reports: action.payload,
        loadingGroupReports: false,
      };

    case "CANNOT_GET_GROUP_REPORTS":
      return { ...state, loadingGroupReports: false };

    case "GETTING_GROUP_METRICS":
      return { ...state, loadingGroupMetrics: true };

    case "GOT_GROUP_METRICS":
      return {
        ...state,
        metrics: action.payload,
        loadingGroupMetrics: false,
      };

    case "CANNOT_GET_GROUP_METRICS":
      return { ...state, loadingGroupMetrics: false };

    case "GETTING_GROUP_PICKUPS_HISTORY":
      return { ...state, loadingGroupPickupsHistory: true };

    case "GOT_GROUP_PICKUPS_HISTORY":
      return {
        ...state,
        pickupsHistory: action.payload,
        loadingGroupPickupsHistory: false,
      };

    case "CANNOT_GET_GROUP_PICKUPS_HISTORY":
      return { ...state, loadingGroupPickupsHistory: false };

    case "GETTING_GROUP_NOTIFICATIONS":
      return { ...state, loadingGroupNotifications: true };

    case "GOT_GROUP_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload.reverse(),
        loadingGroupNotifications: false,
      };

    case "CANNOT_GET_GROUP_NOTIFICATIONS":
      return { ...state, loadingGroupNotifications: false };

    default:
      return state;
  }
}
