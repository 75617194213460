import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
function SingleAggregator({
  data,
  name,
  colors,
  setLotData,
  lotData,
  setLotUniqueKey,
  updateData,
  virtualBinsCode,
  wasteType,
  updateFilter,
}) {
  const [uniqueKey, setUniqueKey] = useState(Math.random());

  const handleLotData = (index) => {
    if (index >= 0 && index < series[0].data.length) {
      const valueToMove = series[0].data[index];
      const newSeries = [...series];
      const newSeries1 = [...lotData];

      newSeries[0].data.splice(index, 1);
      newSeries1[0].data.push(valueToMove);
      setSeries(newSeries);
      setLotData(newSeries1);
      setUniqueKey(Math.random());
      setLotUniqueKey(Math.random());
      updateData(valueToMove.y, name);
    }
  };
  const [series, setSeries] = useState([
    {
      data: data,
    },
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: { show: false },
      type: "treemap",
      width: 300,
      height: 300,
      events: {
        click: (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) => {
          handleLotData(dataPointIndex);
        },
      },
    },
    title: {
      text: "",
      align: "left",
      enabled: false,
    },

    colors: colors,
  });

  useEffect(() => {
    setUniqueKey(Math.random());
  }, [updateFilter]);

  return (
    <div className="white  box" style={{ width: "49.5%" }}>
      <div className="flex align-center space-between ">
        <span>
          <p style={{ fontWeight: "900" }}>#{name}</p>
          <span className="flex align-center  ">
            <img
              src="../images/Plastic-PP.png"
              style={{ width: "20px", height: "20px" }}
            />
            <p className="small-text ">{wasteType}</p>
          </span>
        </span>
        <span
          style={{
            fontSize: "11px",
            fontWeight: "700",
            backgroundColor: colors[0],
            padding: "0 5px",
            borderRadius: "2px",
            color: "#fff",
          }}
          className="flex align-center justify-center gap-1 "
        >
          <span className=" small-text material-symbols-outlined">code</span>
          <b> {virtualBinsCode}</b>
        </span>
      </div>
      <ApexCharts
        key={uniqueKey}
        options={chartOptions}
        series={series}
        type="treemap"
      />
    </div>
  );
}

export default SingleAggregator;
