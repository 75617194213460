import React from "react";
import DashboardBox from "./DashboardBox";
import { useSelector } from "react-redux";

function SustainabilityCredits() {
  const { overview } = useSelector((state) => state.analytics);
  const { reports } = useSelector((state) => state.analytics);

  return (
    <div className="full-width mt-1">
      <div className="mv-1 ">
        <p className="bold mb-1">Your Sustainability Credits Earned</p>
      </div>

      <div className="flex gap-1 flex-wrap">
        <DashboardBox
          title="CERCLEX CREDITS"
          amount={overview?.cercle_credits}
          desc="T&C's Apply"
          img="../images/cerclex.png"
        />

        <DashboardBox
          title="PLASTIC CREDITS"
          amount={reports?.plastic_credits ? reports?.plastic_credits : 0}
          desc="T&C's Apply"
          img="../images/plastic.png"
        />

        <DashboardBox
          title="CARBON CREDITS"
          desc="T&C's Policy"
          amount={reports?.carbon_credits ? reports?.carbon_credits : 0}
          img="../images/carbon.png"
        />
      </div>
    </div>
  );
}

export default SustainabilityCredits;
