import React from "react";
import { useState } from "react";
import TimeLine from "../layout/TimeLine";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPickup } from "../redux/action/pickups";
import DT from "date-and-time";
import { Carousel, Divider } from "react-materialize";
import { Modal } from "react-materialize";
import { IMAGES_URL, IWMURL } from "../redux/config/urlConfig";
import { ComponentHeader } from "../modules";
import isEmpty from "lodash/isEmpty";

function PickupTimeLine({ pickup, showDetails, setShowDetails }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { organization } = useSelector((state) => state.organization);

  // const { pickup, loading } = useSelector((state) => state.pickups);
  // const [showDocsModal, setShowDocsModal] = useState(false);

  if (isEmpty(pickup)) return null;

  return (
    <>
      <div className={!isTabletOrMobile && "container justify-center flex "}>
        <div className="time-line-page">
          <div className="align-center justify-center flex  ">
            {!isTabletOrMobile && (
              <div className="ml-2 mr-2 " style={{ width: "50vw" }}></div>
            )}
            <TimeLine color={"#34A853"} />
            <div style={{ width: "100%" }} className="ml-2  ">
              <p>
                {DT.format(new Date(pickup.createdAt), "DD/MM/YYYY - HH:MM")}
              </p>
              <div
                className=" box time-line-box mr-2 "
                style={{ borderColor: "#34A853" }}
              >
                <div
                  className="space-between align-center flex hover "
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <b>Raised pickup request</b>
                  {!showDetails ? (
                    <span class=" primary material-symbols-outlined">
                      keyboard_double_arrow_down
                    </span>
                  ) : (
                    <span class="material-symbols-outlined purple-text">
                      keyboard_double_arrow_up
                    </span>
                  )}
                </div>

                {showDetails && (
                  <div className="mt-1">
                    <p>
                      {organization.name} raised a pickup request for{" "}
                      {pickup.scrap?.name}-{pickup.scrap.variant} on{" "}
                      {DT.format(new Date(pickup.date), "dddd DD MMMM, YYYY")}
                    </p>

                    <div className="flex justify-end">
                      <div className="user-chip">
                        <img src="/images/user.png" alt="user" />
                        <p>Generator</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {pickup.accepted && (
            <div className="align-center justify-center flex">
              {isTabletOrMobile && <TimeLine color={"#FFAB00"} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                {DT.format(new Date(pickup.createdAt), "DD/MM/YYYY - HH:MM")}
                <div
                  className=" box time-line-box  "
                  style={{ borderColor: "#FFAB00" }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Pickup accepted</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        {pickup.contradictingOrg?.name} accepted the pickup
                        request and will be assigning driver and a vehicle
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={"#FFAB00"} />}
              {!isTabletOrMobile && (
                <div style={{ width: "100%" }} className="ml-2"></div>
              )}
            </div>
          )}

          {pickup.assigned && (
            <div className="align-center justify-center flex">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: "50vw" }}></div>
              )}
              <TimeLine color={"#FF5B67"} />
              <div style={{ width: "100%" }} className="ml-2">
                <p>
                  {DT.format(new Date(pickup.assignedOn), "DD/MM/YYYY - HH:MM")}
                </p>
                <div
                  className=" box time-line-box mr-2 "
                  style={{ borderColor: "#FF5B67" }}
                >
                  <div
                    className="space-between align-center flex hover hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Driver and Vehicle assigned</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>

                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Driver, vehicle has been assigned for the pickup. You
                        shall be notified on pickup day upon arrival.
                      </p>

                      <Modal
                        trigger={
                          <button className="btn-small pink">
                            View Logistics Info
                          </button>
                        }
                      >
                        <p className="bold">Logistics</p>

                        <table>
                          <tbody>
                            {pickup.drivers.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={`${IWMURL}/account/read/photo/${item._id}`}
                                      alt="driver"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </td>
                                  <td className="capitalize">{item?.name}</td>
                                  <td>+{item.phoneNumber}</td>
                                </tr>
                              );
                            })}
                          </tbody>

                          <tbody>
                            {pickup.vehicles.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src={`${IWMURL}/vehicles/read/image/${pickup?.vehicles[index]?._id}`}
                                      alt="driver"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </td>
                                  <td className="capitalize">
                                    {item.vehicleNumber}
                                  </td>
                                  <td>{item.capacity} Tonnes</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {pickup.driverNavigationEnded && (
            <div className="align-center justify-center flex">
              {isTabletOrMobile && <TimeLine color={"#FF821C"} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                {DT.format(
                  new Date(pickup.driverNavigationEndedOn),
                  "DD/MM/YYYY - HH:MM"
                )}
                <div
                  className=" box time-line-box  "
                  style={{ borderColor: "#FF821C" }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Driver arrived at pickup location</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Driver(s) arrived at the pickup location to pickup the
                        waste
                      </p>
                      {pickup.drivers.map((driver, i) => {
                        return (
                          <ul key={i} className="mt-1">
                            <li className="capitalize">
                              <p className="capitalize">
                                Driver : {driver?.name}
                              </p>
                              <p className="capitalize">
                                Vehicle No. : {pickup.vehicles[i].vehicleNumber}
                              </p>
                              <Divider />
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={"#FF821C"} />}
              {!isTabletOrMobile && (
                <div style={{ width: "100%" }} className="ml-2"></div>
              )}
            </div>
          )}

          {pickup.completed && (
            <div className="align-center justify-center flex">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: "50vw" }}></div>
              )}
              <TimeLine color={"#34A853"} />
              <div style={{ width: "100%" }} className="ml-2">
                <p>
                  {DT.format(
                    new Date(pickup.completedOn),
                    "DD/MM/YYYY - HH:MM"
                  )}
                </p>
                <div
                  className=" box time-line-box mr-2 "
                  style={{ borderColor: "#34A853" }}
                >
                  <div
                    className="space-between align-center flex hover hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Bills and Documents uploaded</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary ">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>

                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Bills and other relevant Documents for the pickup has
                        been uploaded
                      </p>

                      <div className="">
                        <button
                          className="btn-small blue mr-1"
                          onClick={() => {
                            window.open(
                              `${IWMURL}/pickups/read/invoiceManifest/${pickup._id}`,
                              "_blank"
                            );
                          }}
                        >
                          View Invoice Manifest
                        </button>
                        <button
                          className="btn-small blue lighten-1 mt-1"
                          onClick={() => {
                            window.open(
                              `${IWMURL}/pickups/read/eWayBill/${pickup._id}`,
                              "_blank"
                            );
                          }}
                        >
                          View eWay Bill
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {pickup.completed && (
            <div className="align-center justify-center flex">
              {isTabletOrMobile && <TimeLine color={"#FFAB00"} />}
              <div className="ml-2 mr-2 time-line-left-step ">
                <p>15/08/2022 - 9.00 am</p>
                <div
                  className=" box time-line-box  "
                  style={{ borderColor: "#FFAB00" }}
                >
                  <div
                    className="space-between align-center flex hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Pickup is completed</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary ">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>
                  {showDetails && (
                    <div className="mt-1 mb-1">
                      <p>
                        Pickup request for {pickup.quantity} Tonne(s) of{" "}
                        {pickup?.scrap?.name}-{pickup.scrap.variant} has been
                        completed
                      </p>
                      <b className="mt-1">Quantity: {pickup.quantity} Tonnes</b>
                    </div>
                  )}
                  <Modal
                    trigger={
                      <button className="btn-small green">View Images</button>
                    }
                  >
                    {pickup.images.length ? (
                      <Carousel
                        carouselId="Carousel-36"
                        images={pickup.images.map((item,i) => {
                          return `${IWMURL}/pickups/read/image/${pickup._id}?count=${i+1}`;
                        })}
                        options={{
                          fullWidth: true,
                          indicators: true,
                        }}
                      />
                    ) : (
                      <div className="center">
                        <p>No Images uploaded</p>
                      </div>
                    )}
                  </Modal>
                </div>
              </div>
              {!isTabletOrMobile && <TimeLine color={"#FFAB00"} />}
              {!isTabletOrMobile && (
                <div style={{ width: "100%" }} className="ml-2"></div>
              )}
            </div>
          )}

          {pickup.recycled && (
            <div className="align-center justify-center flex">
              {!isTabletOrMobile && (
                <div className="ml-2 mr-2 " style={{ width: "50vw" }}></div>
              )}
              <TimeLine color={"#FF5B67"} />
              <div style={{ width: "100%" }} className="ml-2">
                <p>15/08/2022 - 9.00 am</p>
                <div
                  className=" box time-line-box mr-2 "
                  style={{ borderColor: "#FF5B67" }}
                >
                  <div
                    className="space-between align-center flex hover hover "
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    <b>Materials are sent to recycler</b>
                    {!showDetails ? (
                      <span class=" primary material-symbols-outlined">
                        keyboard_double_arrow_down
                      </span>
                    ) : (
                      <span class="material-symbols-outlined primary ">
                        keyboard_double_arrow_up
                      </span>
                    )}
                  </div>

                  {showDetails && (
                    <div className="mt-1">
                      <p>
                        Driver Raj and Kumar arrived at requested location and
                        completed the load at 2 vechicles
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PickupTimeLine;

// <section className="bordered flex space-between p-1 white mb-2 gap-1">
//   <div className="flex align-center gap-1">
//     <img
//       src="https://thumbs.dreamstime.com/b/scrap-metal-6427932.jpg"
//       className="app-image"
//       alt="scrap"
//     />
//     <p>
//       {pickup.scrap?.name}-{pickup.scrap?.variant}
//     </p>
//   </div>
//   <p>{new Date(pickup.date).toDateString()}</p>
//   <p> {pickup.pickupLocation?.address} </p>

//   <p>{pickup.numberOfVehicles}</p>

//   {pickup.completed ? (
//     <p className="green-text">Completed</p>
//   ) : (
//     <p className="orange-text">Pending</p>
//   )}
// </section>
