import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar } from "react-materialize";
import M from "materialize-css";
import { counterPrice } from "../../../redux/action/contracts";
import { notify } from "../../../helpers/helper";

const CounterPrice = ({ selectedContract: scrap }) => {
  const [price, setPrice] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.contracts);

  function sendCounterPrice() {
    if (!price) return;
    let data = { to: price };
    dispatch(
      counterPrice(data, scrap._id, () => {
        notify("Your proposal has been sent successfully!", "cercle-purple");
        let elem = document.getElementById("counter-price-modal");
        let instance = M.Modal.getInstance(elem);
        instance.close();
      })
    );
  }

  return (
    <div style={styles.container}>
      {loading && <ProgressBar />}
      <p className="bold">Counter Price</p>

      <div
        style={{ alignItems: "center", marginVertical: 15 }}
        className="mt-1 mb-1 flex space-between  "
      >
        <div className="flex gap-1 align-center ">
          <img
            src="../images/scrap.png"
            style={{ width: "50px", height: "50px" }}
            alt="scrap"
          />
          <div className="flex column ">
            <p style={{ textTransform: "uppercase" }}>{scrap?.scrap.name}</p>
            <p className="grey-text">{scrap?.scrap.variant}</p>
          </div>
        </div>
        <div className="green-text flex align-center p-1 light-green  lighten-5  ">
          <p>Price Change to ₹{scrap?.priceChange?.processor?.to} </p>
        </div>
      </div>

      <input
        placeholder="Enter the price you'd like to sell at"
        onChange={(e) => setPrice(e.target.value)}
        type="number"
        className="browser-default input-style "
      />

      <p style={{ fontSize: 12 }} className="cercle-purple-text"></p>

      <div className="flex align-center gap-1 justify-end mt-2">
        <button
          className="btn-outline"
          modal={"close"}
          onClick={() => {
            let elem = document.getElementById("counter-price-modal");
            let instance = M.Modal.getInstance(elem);
            instance.close();
          }}
        >
          Cancel
        </button>

        <button
          className="btn"
          onClick={sendCounterPrice}
          disabled={loading || !price}
        >
          Send Request
        </button>
      </div>
    </div>
  );
};

const styles = {
  card: {
    width: 80,
    height: 80,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
};

export default CounterPrice;
