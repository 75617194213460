import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculateTotal } from "../../../helpers/helper";
import { getPickupsHistory } from "../../../redux/action/pickups";

function ReportsPickupHistory() {
  const navigate = useNavigate();
  const { history } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickupsHistory());
  }, [dispatch]);

  return (
    <div className="box  full-width white ">
      <div className="flex ph-1  align-center space-between ">
        <b className="bold">Pickups History</b>
        <Link to="/pickups-history" className="cercle-purple-text">
          View All
        </Link>
      </div>

      <table className="striped  full-width responsive-table">
        <thead>
          <tr>
            <th>Pickup Date </th>
            <th>Processor</th>
            <th>Price</th>
            <th>GST</th>
            <th>Total</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {history.slice(-4).map((item, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigate("/pickup/" + item._id)}
                className="tableHover"
              >
                <td>{new Date(item.date).toDateString()}</td>
                <td>{item.processor?.name}</td>
                <td>Rs. {item.price}/kg</td>
                <td>N/A</td>
                <td>Rs.{calculateTotal(item.price, item.quantity)}</td>

                <td>
                  <div
                    className={
                      1
                        ? "seccuss-Status small-text"
                        : " pending-status small-text"
                    }
                  >
                    {item.completed ? (
                      <span style={{ color: "green" }}>Completed</span>
                    ) : (
                      <span style={{ color: "orangered" }}>pending</span>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(history?.length === 0 || !history) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            fire_truck
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any pickups.
          </p>
        </div>
      )}
    </div>
  );
}

export default ReportsPickupHistory;
