import React from "react";
import {
  XAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
  YAxis,
} from "recharts";

import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

function QuantityOverTime({ organization,endDate,startDate }) {

  const { quantityOverTime, quantityOverTimeLoading } = useSelector(
    (state) => state.analytics
  );


  return (
    <div className=" p-2 mb-2 box white " style={{ height: "300px" }}>
      <div className="flex space-between">
        <p className="bold">Waste Tonnage Channelized</p>

        {quantityOverTimeLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <div className="flex hover ">
            
          </div>
        )}
      </div>

      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          data={quantityOverTime}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="1" x2="0">
              <stop offset="20%" stopColor="#FF9100" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#FF507D" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis dataKey="quantity" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="quantity"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default QuantityOverTime;
