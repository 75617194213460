import React from "react";
import Chips from "./Chips";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  deleteLocation,
} from "../../../redux/action/organization";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Map from "../../pickups/components/Map";
import AddLocation from "./AddLocation";
import { toast } from "react-toastify";
import { Modal } from "react-materialize";

const Step3 = () => {
  const { organization, loadingLocations: loading } = useSelector(
    (state) => state.organization
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    location: {
      lng: "",
      lat: "",
      address: "",
      nickname: "",
    },
  });

  function submit(e) {
    e.preventDefault();
  }

  function addNewLocation() {
    dispatch(
      addLocation(values.location, () => {
        toast.success("Location Added successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setValues({
          location: {
            nickname: "",
            address: "",
            lat: "",
            lng: "",
          },
        });
      })
    );
  }

  return (
    <div className="flex">
      <form
        className="container first-time-container flex column align-center space-between "
        onSubmit={submit}
        style={{ marginBottom: "0vh" }}
      >
        <div className="flex column align-center">
          <Chips active={3} />
          <p className="bold mt-2 mb-2">Step 3 out of 5 </p>

          <div className="flex align-center">
            <h3>Add a location to your organization</h3>
            <Modal
              trigger={
                <button className="btn-small ml-1 hide-on-large-only">
                  View_Added
                </button>
              }
              bottomSheet={true}
            >
              <SideBar organization={organization} loading={loading} />
            </Modal>
          </div>
          <p className="grey-text">
            Add locations of factories, offices, warehouses, etc. that you may
            request pickups from.
          </p>
        </div>

        <div>
          <AddLocation
            values={values}
            setValues={setValues}
            addLocation={addNewLocation}
            loading={loading}
          />
        </div>

        <button
          className="btn mb-1 first-time-button green mt-2"
          onClick={() => navigate("/step4")}
          disabled={loading || organization.locations?.length === 0}
        >
          {loading ? <ClipLoader size={20} color="#fff" /> : "Next"}
        </button>
      </form>

      <SideBar
        organization={organization}
        loading={loading}
        hideOnSmallDevices={true}
      />
    </div>
  );
};

const SideBar = ({ organization, loading, hideOnSmallDevices }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`first-time-aside ${
        hideOnSmallDevices && "hide-on-med-and-down"
      }`}
    >
      <div className="flex align-center gap-1 ">
        <h3>Added Locations</h3>
        <ClipLoader size={20} color="#6f2da8" loading={loading} />
      </div>

      <div className="first-time-aside-list-container full-width">
        {!organization.locations?.length ? (
          <div
            className="flex justify-center column align-center  p-2 "
            style={{ height: "70%" }}
          >
            <i
              className="material-symbols-outlined grey-text"
              style={{ fontSize: 40 }}
            >
              location_pin
            </i>
            <p className="grey-text small-text">No locations added yet</p>
          </div>
        ) : (
          organization.locations.map((location, index) => (
            <div
              className="full-width p-1 flex column align-end"
              style={{ borderBottom: "1px solid #ececec" }}
              key={index}
            >
              <div className="full-width">
                <p>{location.nickname}</p>
                <p className="grey-text">{location.address}</p>
              </div>

              <button
                className="btn-floating purple lighten-4"
                onClick={() => {
                  dispatch(
                    deleteLocation(location._id, () => {
                      toast.success("Location deleted successfully", {
                        position: toast.POSITION.BOTTOM_LEFT,
                        hideProgressBar: true,
                        autoClose: 3000,
                      });
                    })
                  );
                }}
              >
                <i className="material-icons">delete</i>
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Step3;
