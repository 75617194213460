import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-materialize";
import { getNetwork } from "../../redux/action/organization";
import { getAllScraps } from "../../redux/action/scraps";
import { getContracts } from "../../redux/action/contracts";
import RaisePickups from "./components/RaisePickups";
import ContractsList from "./components/ContractsList";
import { IWMURL } from "../../redux/config/urlConfig";
import ChangePrice from "./components/ChangePrice";

function Pickups() {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { network: myNetwork } = useSelector((state) => state.organization);
  const { contracts } = useSelector((state) => state.contracts);
  const [scrapContracts, setScrapContracts] = useState([]);
  const [network, setNetwork] = useState({});
  const [pickupLocation, setPickupLocation] = useState("");
  const dispatch = useDispatch();
  const [scrap, setScrap] = useState("");
  const [scrapContract, setScrapContract] = useState("");

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getAllScraps());
    dispatch(getContracts());
  }, [dispatch]);

  useEffect(() => {
    setNetwork(myNetwork[0]);
  }, [myNetwork]);

  useEffect(() => {
    const existingContract = contracts.find(
      (contract) =>
        contract.scrap._id === scrap &&
        contract.processor._id === network?.organization?._id
    );
    setScrapContract(existingContract);
  }, [contracts, network, scrap]);

  useEffect(() => {
    setScrapContracts(
      contracts.filter(
        (contract) => contract?.processor?._id === network?.organization?._id
      )
    );
  }, [contracts, network]);

  return (
    <div className=" full-height p-1">
      {/* <div className="flex">
        <p className="bold">Raise Pickup</p>
      </div> */}

      <div className="flex  full-height ">
        <div className="">
          <div className="hide-on-med-and-up flex justify-end">
            <Modal
              bottomSheet={true}
              trigger={
                <Button className="btn-small cercle-purple">
                  View Matched Network
                </Button>
              }
            >
              <ContractsList
                setNetwork={setNetwork}
                selectedNetwork={network}
                scrap={scrap}
                setPickupLocation={setPickupLocation}
                pickupLocation={pickupLocation}
              />
            </Modal>
          </div>
          <RaisePickups
            selectedNetwork={network}
            scrapContracts={scrapContracts}
            scrap={scrap}
            setScrap={setScrap}
            setPickupLocation={setPickupLocation}
            pickupLocation={pickupLocation}
            scrapContract={scrapContract}
          />

          {scrapContract && (
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="mt-1"
            >
              <label htmlFor="" className="red-text">
                Valid Scrap Contract Found
              </label>
              <div className="box white ">
                <div className="flex align-center  flex-wrap ">
                  <img
                    src={`${IWMURL}/scraps/read/image/${scrapContract.scrap._id}`}
                    className="dashboard-card-img"
                    alt="dashboard-scrap"
                  />

                  <div className="ml-1 mr-1 flex gap-1">
                    <div className="mr-1">
                      <p
                        className="bold cercle-purple-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {scrapContract?.scrap?.name}-
                        {scrapContract?.scrap?.variant}
                      </p>
                      <p className="bold">{scrapContract.price + "/kg"}</p>
                    </div>

                    <div className="flex gap-1 mr-1 align-center">
                      <div>
                        <p className="small-text cercle-purple-text">
                          START DATE
                        </p>
                        <p className="small-text grey-text">
                          {new Date(scrapContract.start).toDateString()}
                        </p>
                      </div>

                      <div>
                        <p className="small-text cercle-purple-text">
                          END DATE
                        </p>
                        <p className="small-text grey-text">
                          {new Date(scrapContract.end).toDateString()}
                        </p>
                      </div>
                    </div>

                    {!scrapContract.accepted ? (
                      <p className="orange-text">Pending Approval</p>
                    ) : (
                      <div className="mr-1 flex column justify-center">
                        <label className="cercle-purple-text capitalize">
                          PRICE TYPE
                        </label>

                        <p className=" capitalize">
                          {" "}
                          {scrapContract.priceType} Price
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    {scrapContract.priceType === "variable" &&
                    scrapContract.accepted &&
                    new Date(scrapContract.priceEndsOn) < new Date() ? (
                      <Modal
                        trigger={
                          <Button className="btn-small cercle-purple">
                            Change Price
                          </Button>
                        }
                        id="change-price-modal"
                        actions={[]}
                      >
                        <ChangePrice
                          selectedContract={scrapContract}
                          setWaste={() => {}}
                        />
                      </Modal>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isTablet && (
          <div className="raise-pickups-sidebar  full-height  p-1">
            <p className="bold">Available Waste Processors</p>
            <ContractsList
              setNetwork={setNetwork}
              selectedNetwork={network}
              scrap={scrap}
              setPickupLocation={setPickupLocation}
              pickupLocation={pickupLocation}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Pickups;
