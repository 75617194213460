import React from "react";
import Chips from "./Chips";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { updateOrganization } from "../../../redux/action/organization";
import { useNavigate } from "react-router-dom";
import { isTabletOrMobile } from "../../../helpers/helper";

const Step1 = () => {
  const { account } = useSelector((state) => state.account);
  const { organization, loading } = useSelector((state) => state.organization);
  const [organizationName, setOrganizationName] = React.useState("");
  const [startedEditing, setStartedEditing] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  


  function submit(e) {
    e.preventDefault();
    if (organizationName) {
      dispatch(
        updateOrganization({ name: organizationName }, () => {
          navigate("/step2");
          setStartedEditing(false);
          setOrganizationName("");
        })
      );
    }
    if (organization.name !== "Default Organization") navigate("/step2");
  }

  return (
    <>
      {organization.name === "Default Organization" ? (
        <form
          className="container  first-time-container flex column align-center space-between "
          onSubmit={submit}
        >
          <div className="flex column align-center">
            <Chips active={1} />
            <p className="bold mt-2 mb-2">Step 1 out of 5 </p>

            <h3>Let&apos;s setup your profile!</h3>
            <p className="grey-text" style={{ fontSize: "20px" }}>
              Hi 👋 {account?.name}, Please enter your organization's name to
              continue.
            </p>
          </div>

          <div className="dialogue-box">
            <p className="small-text">Enter your organization's name</p>
            <input
              type="text"
              placeholder="Eg. John Doe Company"
              className="browser-default"
              value={organizationName}
              style={{
                width: isTabletOrMobile ? "100%" : "70%",
                height: "50px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                fontSize: "18px",
                color: "#666",
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "20px",
              }}
              onChange={(e) => {
                setStartedEditing(true);
                setOrganizationName(e.target.value);
              }}
            />
          </div>

          <button
            className="btn mb-1 mt-1 first-time-button green"
            type="submit"
            disabled={
              organization.name === "Default Organization" && !organizationName
            }
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Next"
            )}
          </button>
        </form>
      ) : (
        <form
          className="container  first-time-container flex column align-center space-between "
          onSubmit={submit}
        >
          <div className="flex column align-center">
            <Chips active={1} />
            <p className="bold mt-2 mb-2">Step 1 out of 5 </p>

            <h3>Confirm Your Organization Name</h3>
            <p className="grey-text" style={{ fontSize: "20px" }}>
              Hi 👋 {account?.name}, Please confirm your organization name to
              continue.
            </p>
          </div>

          <div className="dialogue-box">
            <p className="small-text">Is this the name of your organization?</p>
            <input
              type="text"
              placeholder="Enter Your Organization Name"
              className="browser-default"
              value={startedEditing ? organizationName : organization.name}
              style={{
                width: isTabletOrMobile ? "100%" : "70%",
                height: "50px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                fontSize: "20px",
                color: "#666",
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "20px",
              }}
              onChange={(e) => {
                setStartedEditing(true);
                setOrganizationName(e.target.value);
              }}
            />
          </div>

          <button
            className="btn  mb-1 mt-1 first-time-button green"
            type="submit"
          >
            {loading ? (
              <ClipLoader color={"#fff"} loading={loading} size={20} />
            ) : (
              "Next"
            )}
          </button>
        </form>
      )}
    </>
  );
};

export default Step1;
