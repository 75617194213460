import React from "react";
import { Chart } from "react-google-charts";
import aggregators from "./inventory/children/aggregators.json";

function Aggregators() {
  const data = [
    ["From", "To", "Quantity"],
    ["Fade", "HDPE", 1],
    ["Fade", "LLDPE", 1],
    ["Fade", "PP", 1],
    ["Fade", "LDPE", 1],
    ["HDPE", "FAcx02", 1],
    ["LLDPE", "FAcx03", 1],
    ["PP", "FAcx04", 1],
    ["LDPE", "FAcx01", 1],
  ];

  return (
    <div className="p-2">
      <b>Virtual Input Bins</b>
      <div className="flex-wrap align-center align-center gap-2 flex">
        {aggregators.map((item) => {
          return (
            <div className="white p-1 box " style={{ width:"49%" , height:"250px"}} >
              <b>#{item.name}</b>
              <Chart
                chartType="Sankey"
                width="100%"
                height="90%"
                data={item.virtualBins}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Aggregators;
