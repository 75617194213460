import { IWM } from "../config/urlConfig";

export const getNotifications = () => async (dispatch) => {
  dispatch({ type: "GETTING_NOTIFICATIONS" });
  try {
    const res = await IWM.get("/notifications/read");
    dispatch({ type: "GOT_NOTIFICATIONS", payload: res.data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: "CANNOT_GET_NOTIFICATIONS",
      payload: ex.response?.data,
    });
  }
};

export const getNotificationsProfile = () => async (dispatch) => {
  dispatch({ type: "GETTING_NOTIFICATIONS_PROFILE" });
  try {
    const res = await IWM.get("/notifications/read/profile");
    dispatch({ type: "GOT_NOTIFICATIONS_PROFILE", payload: res.data });
  } catch (ex) {
    dispatch({
      type: "CANNOT_GET_NOTIFICATIONS_PROFILE",
      payload: ex.response?.data,
    });
  }
};

export const seenNotification = (id) => async (dispatch) => {
  try {
    await IWM.put("/notifications/update/seen-notification/" + id);
    dispatch({ type: "SEEN_NOTIFICATION", payload: id });
  } catch (ex) {
    dispatch({
      type: "CANNOT_SEEN_NOTIFICATION",
      payload: ex.response?.data,
    });
  }
};

export const updateNotificationToken = (token) => async (dispatch) => {
  try {
    await IWM.put("/notifications/update/token", {
      expoToken: token,
    });
    dispatch({ type: "UPDATED_NOTIFICATION_TOKEN", payload: token });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: "CANNOT_UPDATE_NOTIFICATION_TOKEN",
      payload: ex.response?.data,
    });
  }
};

export const seenNotifications = () => async (dispatch) => {
  try {
    await IWM.put("/notifications/update/seen-notifications");
    dispatch({ type: "SEEN_NOTIFICATIONS" });
  } catch (ex) {
    dispatch({
      type: "CANNOT_SEEN_NOTIFICATIONS",
      payload: ex.response?.data,
    });
  }
};

export const handleNotificationPress = (data, navigate, dispatch) => {
  switch (data.type) {
    case "pickup-assigned":
      navigate("/pickup/" + data.pickup);
      dispatch(seenNotification(data._id));
      return;

    case "pickup-accepted":
      navigate("/pickup/" + data.pickup);
      dispatch(seenNotification(data._id));
      return;

    case "driver-navigation-started":
      navigate("/pickup/" + data.pickup);
      dispatch(seenNotification(data._id));
      return;

    case "driver-navigation-ended":
      navigate("/pickup/" + data.pickup);
      dispatch(seenNotification(data._id));
      return;

    case "pickup-completed":
      navigate("/pickup/" + data.pickup);
      dispatch(seenNotification(data._id));
      return;

    case "price-change-request":
      navigate("/pickups");
      dispatch(seenNotification(data._id));
      return;

    case "price-change-counter":
      navigate("/pickups");
      dispatch(seenNotification(data._id));
      return;

    case "price-change-accepted":
      navigate("/pickups");
      dispatch(seenNotification(data._id));
      return;

    default:
      return;
  }
};
