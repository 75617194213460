import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import InviteAccount from "./InviteAccount";
import { Modal } from "react-materialize";
import RModal from "react-responsive-modal";
import {
  getInvitations,
  rescindAccountInvitation,
  deleteAccountAction,
} from "../../../redux/action/organization";
import { notify } from "../../../helpers/helper";

function Users() {
  const { organization, invitations, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const { account } = useSelector((state) => state.account);
  const { members } = organization;
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  function deleteUser(id) {
    dispatch(deleteAccountAction(id));
  }

  useEffect(() => {
    dispatch(getInvitations());
  }, [dispatch]);

  return (
    <div>
      <div className="flex space-between">
        <p className="bold-title"></p>

        <RModal
          open={open}
          onClose={() => setOpen(false)}
          // id="invitation-modal"
          // actions={[]}
        >
          <InviteAccount />
        </RModal>
        <button
          className="btn-small mt-1"
          disabled={!account?.permissions?.includes("add-member")}
          onClick={() => setOpen(true)}
        >
          Add New User
        </button>
      </div>

      <div className="full-width box mt-2">
        <table
          className={"striped  payment-history  full-width responsive-table"}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>PHONE NUMBER </th>
              <th>ROLE</th>
              <th>STATUS</th>
              <th>ACTION</th>
            </tr>
          </thead>

          <tbody>
            {members?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{item.account.name}</td>

                  <td className="lowercase">{item.account?.email}</td>
                  <td>+{item.account?.phoneNumber}</td>
                  <td className="capitalize">{item.role}</td>
                  <td className="green-text">
                    {item.enabled ? "Active" : "Deactivated"}
                  </td>
                  <td>
                    <Dropdown
                      trigger={
                        <div>
                          <i class="material-symbols-outlined">more_vert</i>
                        </div>
                      }
                    >
                      <span>
                        {item.enabled
                          ? "Deactivate Account"
                          : "Activate Account"}
                      </span>

                      <Modal
                        actions={[]}
                        id="Modal-10"
                        open={false}
                        bottomSheet={false}
                        trigger={
                          <span node="button">
                            {item.enabled
                              ? "Delete Account"
                              : "Activate Account"}
                          </span>
                        }
                        header="Delete Account"
                      >
                        <p>Are you sure you want to Delete this account?</p>
                        <div className="flex  justify-end gap-2  ">
                          <Button
                            className="green btn-small "
                            onClick={() => deleteUser(item._id)}
                            modal="close"
                          >
                            Yes
                          </Button>

                          <Button
                            className="red btn-small"
                            node="button"
                            modal="close"
                          >
                            No
                          </Button>
                        </div>
                      </Modal>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tbody>
            {invitations?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{members.length + index + 1}.</td>
                  <td className="capitalize">N/A</td>

                  <td className="lowercase">{item.email}</td>
                  <td>N/A</td>
                  <td className="capitalize">{item.role}</td>
                  <td className={item.enabled ? "green-text" : "red-text"}>
                    {item.enabled ? "Active" : "INACTIVE"}
                  </td>
                  <td>
                    <Modal
                      header="Cancel Invitation"
                      id="cancel-invitation"
                      trigger={
                        <button className="btn-small white cercle-purple-text">
                          {" "}
                          Cancel Invite
                        </button>
                      }
                      actions={[]}
                    >
                      <p>
                        Are you sure you want to cancel this invitation to{" "}
                        {item.email}?
                      </p>

                      <div className="flex gap-1 mt-1">
                        <Button
                          modal="close"
                          className="btn-small "
                          onClick={() => {
                            dispatch(
                              rescindAccountInvitation(item._id, () => {
                                dispatch(getInvitations());
                                notify("Invitation Cancelled");
                                const elem =
                                  document.getElementById("cancel-invitation");
                                const instance =
                                  window.M.Modal.getInstance(elem);
                                instance.close();
                              })
                            );
                          }}
                          disabled={
                            !account?.permissions?.includes("add-member") ||
                            loadingAccounts
                          }
                        >
                          {" "}
                          Cancel Invite
                        </Button>

                        <Button
                          className="btn-small white cercle-purple-text"
                          modal="close"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {(members?.length === 0 || !members) && (
          <div className="flex column align-center full-width white  mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "3rem" }}
            >
              group
            </span>
            <p className="grey-text mt-1 ">
              Currently you don&apos;t have any user.
            </p>

            <p className="grey-text">
              You can invite other colleagues to use this dashboard
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Users;
