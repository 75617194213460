import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-materialize";
import { getAccount, updateAccount } from "../../../redux/action/auth";
import { notify } from "../../../helpers/helper";
import { updateOrganizationMember } from "../../../redux/action/organization";

function EditUserInfo() {
  const dispatch = useDispatch();
  const [editAccountModal, setEditAccountModal] = useState(false);

  const { account, loadingAccount } = useSelector((state) => state.account);
  const [userName, setUsername] = useState("");
  const [role, setRole] = React.useState("");
  const [customRole, setCustomRole] = React.useState("");

  useEffect(() => {
    if (!account) return;
    setUsername(account.name);
    setRole(account.roleDescription ? account.roleDescription : "");
  }, [account]);

  function onSavechange(e) {
    e.preventDefault();

    if (userName !== account.name) {
      dispatch(
        updateAccount({ name: userName }, () => {
          notify("Updated successfully", "Organization name has been updated");
          dispatch(getAccount());
          const elem = document.getElementById("edit-account-modal");
          const instance = window.M.Modal.getInstance(elem);
          instance.close();
        })
      );
    }

    if (role === "other" && customRole) {
      dispatch(
        updateOrganizationMember({ roleDescription: customRole }, () => {
          notify("Updated Successfully", "your title has been updated");
          const elem = document.getElementById("edit-account-modal");
          const modal = window.M.Modal.getInstance(elem);
          modal.close();
          dispatch(getAccount());
        })
      );
    } else if (
      role !== (account.roleDescription ? account.roleDescription : "")
    ) {
      dispatch(
        updateOrganizationMember({ roleDescription: role }, () => {
          notify("Updated Successfully", "your title has been updated");
          dispatch(getAccount());
          const elem = document.getElementById("edit-account-modal");
          const modal = window.M.Modal.getInstance(elem);
          modal.close();
        })
      );
    }
  }

  return (
    <Modal
      id="edit-account-modal"
      trigger={
        <button
          node="button"
          className="btn-outline flex align-center  purple-text"
        >
          Edit
        </button>
      }
      actions={[]}
      header="Edit Account"
    >
      <form>
        <div className="row align-center  ">
          <div className=" col s6">
            <label>Your Title In Your Company</label>
            <select
              onChange={(e) => {
                setRole(e.target.value);
              }}
              value={role}
              className="browser-default"
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              <option value="">-select-</option>
              <option value="sustainability-head">Sustainability Head</option>
              <option value="site-manager">Site Manager</option>
              <option value="other">other</option>
            </select>
            <span className="mt-1  ">
              <label>Enter Your Name</label>
              <input
                className="browser-default input-field input-style "
                type="text"
                placeholder="Enter Your Name"
                value={userName}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </span>
            {role === "other" && (
              <div className="mt-1 full-width">
                <label>Enter your role here</label>
                <input
                  type="text"
                  placeholder="Eg. Sustainability Head"
                  className="browser-default"
                  value={customRole}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => {
                    setCustomRole(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end mv-1">
          <button
            className="btn"
            onClick={onSavechange}
            disabled={
              (userName === account.name &&
                role ===
                  (account.roleDescription ? account.roleDescription : "")) ||
              loadingAccount
            }
          >
            Save Changes
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditUserInfo;
