import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { Button, Modal } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import ChangePrice from "./ChangePrice";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { ClipLoader } from "react-spinners";
import { IWMURL } from "../../../redux/config/urlConfig";
import CounterPrice from "./CounterPrice";
import { acceptPrice } from "../../../redux/action/contracts";

const ScrapContracts = ({ selectedNetwork }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 724px)" });
  const { contracts, loading } = useSelector((state) => state.contracts);
  const priceChanges = contracts
    .filter(
      (contract) =>
        contract?.processor?._id === selectedNetwork?.organization?._id
    )
    .filter((contract) => !isEmpty(contract?.priceChange.processor));
  const dispatch = useDispatch();

  return (
    <div>
      {priceChanges.length ? (
        <div className="mb-2">
          <p className="chart-header mb-1 small-text">PRICE CHANGE UPDATES</p>
          {priceChanges.map((priceChange, i) => {
            return (
              <div
                key={i}
                className="box p-1 flex align-center gap-1 white space-between"
              >
                <div className="flex">
                  <img
                    src={`${IWMURL}/scraps/read/image/${priceChange.scrap._id}`}
                    style={{ width: "50px", height: "50px" }}
                    alt="scrap"
                  />

                  <div className="ml-1">
                    <p style={{ textTransform: "capitalize" }}>
                      {priceChange.scrap.name} - {priceChange.scrap.variant}
                    </p>
                    <p className="small-text grey-text">
                      {selectedNetwork?.organization?.name} wants to buy at
                      {"  "}
                      <span
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        {priceChange?.priceChange?.processor?.to + "/kg"}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="flex ">
                  <button
                    className="btn "
                    onClick={() => {
                      dispatch(acceptPrice(priceChange._id, () => {}));
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader color="white" size={20} />
                    ) : (
                      "Accept"
                    )}
                  </button>

                  <Modal
                    id="counter-price-modal"
                    trigger={
                      <button className="btn-outline  ml-1">Counter</button>
                    }
                    actions={[]}
                    options={{
                      onCloseEnd: () => {},
                    }}
                  >
                    <CounterPrice selectedContract={priceChange} />
                  </Modal>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {priceChanges.length ? (
        <p className="chart-header mb-1 small-text">YOUR SCRAPS</p>
      ) : null}
      <div className="flex flex-wrap gap-1">
        {contracts
          .filter(
            (contract) =>
              contract?.processor?._id === selectedNetwork?.organization?._id
          )
          .map((scrap, i) => {
            return (
              <div
                className={
                  !isMobile
                    ? "scrap-card"
                    : " flex box align-start  white  p-1 space-between gap-1 full-width "
                }
                key={i}
              >
                <div className="flex align-center flex-wrap ">
                  <img
                    src={`${IWMURL}/scraps/read/image/${scrap.scrap._id}`}
                    className="dashboard-card-img"
                    alt="dashboard-scrap"
                  />
                  <div className="ml-1 mr-1">
                    <div className="flex">
                      <p
                        className="bold cercle-purple-text"
                        style={{ textTransform: "uppercase" }}
                      >
                        {scrap?.scrap?.name}-{scrap?.scrap?.variant}
                      </p>
                      <p className="ml-1 bold">{scrap.price + "/kg"}</p>
                    </div>

                    {!scrap.accepted ? (
                      <p className="orange-text">Pending Approval</p>
                    ) : (
                      <p className="grey-text capitalize">
                        {" "}
                        {scrap.priceType} Price
                      </p>
                    )}

                    <div className="flex gap-1">
                      <div>
                        <p className="small-text cercle-purple-text">
                          START DATE
                        </p>
                        <p className="small-text grey-text">
                          {new Date(scrap.start).toDateString()}
                        </p>
                      </div>

                      <div>
                        <p className="small-text cercle-purple-text">
                          END DATE
                        </p>
                        <p className="small-text grey-text">
                          {new Date(scrap.end).toDateString()}
                        </p>
                      </div>
                    </div>
                  </div>

                  {scrap.priceType === "variable" &&
                  scrap.accepted &&
                  priceChanges.filter(
                    (priceChange) => priceChange._id === scrap._id
                  ).length === 0 ? (
                    <Modal
                      trigger={
                        <Button
                          className="btn-floating purple lighten-5 "
                          tooltip={
                            scrap.priceType === "fixed"
                              ? "Price change not allowed for fixed price contracts"
                              : "Change Scrap Price"
                          }
                        >
                          <FiEdit2 color="#6f2da8" />
                        </Button>
                      }
                      id={"change-price-modal"+i}
                      actions={[]}
                    >
                      <ChangePrice
                        selectedContract={scrap}
                        setWaste={() => {}}
                        index={i}
                      />
                    </Modal>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ScrapContracts;
