import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import aggregators from "./children/aggregators.json";
import AvalibaleToBeREcycled from "./components/AvalibaleToBeREcycled";
import {
  getInventory,
  getRecycledBatches,
  markAsRecycled
} from "../../redux/action/pickups";
import LotHistory from "./children/LotHistory";
import { ClipLoader } from "react-spinners";
import { Checkbox, Modal, Tab, Tabs } from "react-materialize";
import InvCard from "./components/InvCard";
import { Link, useNavigate } from "react-router-dom";
import { getReports } from "../../redux/action/analytics";
import MarkAsRecycledModal from "./components/MarkAsRecycledModal";
import MaterialInward from "./components/MaterialInward";
const Inventory = () => {
  const {
    inventory,
    loadingInventory,
    loadingRecycledBatches,
    creatingRecycledBatch
  } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();
  const { reports } = useSelector((state) => state.analytics);
  const [contaminationLevel, setContaminationLevel] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);

  useEffect(() => {
    dispatch(getInventory());
    dispatch(getRecycledBatches());
    dispatch(getReports());
  }, [dispatch]);

  return (
    <div className="p-1">
      <div className="flex space-between">
        <p className="bold">My Inventory</p>

        <div className="flex gap-1 align-center">
          {loadingInventory || loadingRecycledBatches ? (
            <ClipLoader color="#6f2da8" size={20} />
          ) : null}

          <Modal
            trigger={
              <button
                className="btn-small"
                disabled={selected.length === 0}
                onClick={() => {
                  navigate("/recycled-batch/" + selected[0]);
                }}
              >
                {" "}
                Mark As Recycled Batch
              </button>
            }
            actions={[]}
            id="mark-as-recycled"
          >
            <MarkAsRecycledModal
              selected={selected}
              setSelected={setSelected}
              inventory={inventory}
              contaminationLevel={contaminationLevel}
              setContaminationLevel={setContaminationLevel}
              creatingRecycledBatch={creatingRecycledBatch}
              quantity={quantity}
              setQuantity={setQuantity}
              totalQuantity={selected.reduce((acc, curr) => {
                const item = inventory.find((item) => item._id === curr);
                return acc + item.quantity;
              }, 0)}
            />
          </Modal>
        </div>
      </div>

      {/*     <div className="flex mb-1 mt-1 gap-1">
        <InvCard
          title="TOTAL QUANTITY RECYCLED"
          amount={`${reports?.totalQuantityRecycled} Tonnes`}
          desc="Recycled So Far"
          img="../images/calendar.png"
        />
        <InvCard
          title="QUANTITY AVAILABLE TO BE RECYCLED"
          amount={
            inventory.reduce((acc, curr) => acc + curr.quantity, 0) + " Tonnes"
          }
          desc="Material Available to be Recycled"
          img="../images/calendar.png"
        />
        <InvCard
          title="QUANTITY SELECTED"
          amount={`${
            selected.length > 0
              ? selected.reduce((acc, curr) => {
                  const item = inventory.find((item) => item._id === curr);
                  return acc + item.quantity;
                }, 0)
              : 0
          } 
             Tonnes`}
          desc="Material Available to be Recycled"
          img="../images/calendar.png"
        />
        </div>
      
      */}

      <div className="mt-2">
        <Tabs>
          <Tab title="material inward" idx="material-inward">
            <MaterialInward />
          </Tab>

          <Tab title="Available To Be Recycled" idx="available">
            <div className="flex mb-1 mt-1 space-between">
              <div className="flex gap-1 ">
                <InvCard
                  title="TOTAL QUANTITY RECYCLED"
                  amount={`${reports?.totalQuantityRecycled} Tonnes`}
                  desc="Recycled So Far"
                  img="../images/calendar.png"
                />
                <InvCard
                  title="QUANTITY AVAILABLE TO BE RECYCLED"
                  amount={
                    inventory.reduce((acc, curr) => acc + curr.quantity, 0) +
                    " Tonnes"
                  }
                  desc="Material Available to be Recycled"
                  img="../images/calendar.png"
                />
                <InvCard
                  title="QUANTITY SELECTED"
                  amount={`${
                    selected.length > 0
                      ? selected.reduce((acc, curr) => {
                          const item = inventory.find(
                            (item) => item._id === curr
                          );
                          return acc + item.quantity;
                        }, 0)
                      : 0
                  } 
             Tonnes`}
                  desc="Material Available to be Recycled"
                  img="../images/calendar.png"
                />
              </div>
              <Link to={"/create-lot"}>
                <button className="btn cercle-btn">Create new lot</button>
              </Link>
            </div>
            {inventory.length === 0 ? (
              <div className="flex justify-center">
                <div className="flex column align-center full-width  mv-2 p-2">
                  <i
                    className="material-symbols-outlined grey-text"
                    style={{ fontSize: 80 }}
                  >
                    inventory_2
                  </i>
                  <p className="grey-text mt-1 ">
                    You have no raw materials in your inventory.
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex-wrap align-center align-center gap-1 flex ">
                {aggregators.map((item, index) => {
                  return (
                    <AvalibaleToBeREcycled
                      data={item.pickups}
                      name={item.name}
                      colors={item.colors}
                      style={{ width: "40vw" }}
                    />
                  );
                })}
              </div>
            )}
          </Tab>

          <Tab title="Recycled Batches" idx="recycled">
            {/* <RecycledBatches /> */}
            <LotHistory />
          </Tab>
        </Tabs>
      </div>
      {/* <VirtualBins />
                <SankeyExample />  */}
    </div>
  );
};

export default Inventory;

{
  /* <table className="striped  payment-history  ">
<thead>
  <tr>
    <th>select</th>
    <th>Scrap</th>
    <th>Date</th>
    <th>Location</th>
    <th>Price</th>
    <th>Quantity</th>
    <th>Action</th>
  </tr>
</thead>
<tbody>
  {inventory?.map((item, index) => {
    return (
      <tr key={index}>
        <td>
          <Checkbox
            id={item._id}
            value={item._id}
            checked={selected.includes(item._id)}
            filledIn
            onChange={(e) => {
              if (e.target.checked) {
                setSelected([...selected, e.target.value]);
              } else {
                setSelected(
                  selected.filter(
                    (item) => item !== e.target.value
                  )
                );
              }
            }}
          />
        </td>
        <td>
          <span className="flex align-center gap-1 ">
            <img
              src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
              className="scrap-image mr-1"
              alt="scrap"
            />

            <p>
              {item.scrap.name}-{item.scrap.variant}
            </p>
          </span>
        </td>
        <td>{new Date(item.date).toDateString()}</td>
        <td>{item.pickupLocation?.address}</td>
        <td>INR {item.price}/Kg</td>
        <td>{item.quantity}</td>

        <td style={{ width: "20%" }}>
          <button
            className="btn-small white cercle-purple-text"
            onClick={() => navigate("/pickup/" + item._id)}
          >
            View Timeline
          </button>
        </td>
      </tr>
    );
  })}
</tbody>
</table> */
}
