import React, { useState } from "react";
import { Link } from "react-router-dom";
import lotsHistory from "./lotsHistory.json";
import ApexCharts from "react-apexcharts";
import { Collapsible, CollapsibleItem, RadioGroup } from "react-materialize";

function BlockchainLot() {
  const [testReport, setTestReport] = useState(false);
  const stackedBarOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      stacked: true
    },

    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    xaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: false,
      borderColor: "transparent",
      position: "back"
    },
    colors: ["#6f2da8", "#EC3710", "#55EC10", "#10EC9A"],
    tooltip: {
      enabled: true,
      intersect: true,
      custom: function ({ series3, seriesIndex, dataPointIndex, w }) {
        console.log(series3, seriesIndex);
        return `<div class="custom-tooltip">
                   hello
              </div>`;
      }
    }
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "treemap",
      toolbar: { show: false }
    },
    title: {
      text: "",
      align: "left",
      enabled: false
    },

    colors: ["#6f2da8", "#6f2da8", "#EC9800", "#9EDE00"]
  });
  return (
    <div>
      {lotsHistory.map((item, key) => {
        if (key === 0)
          return (
            <div
              className="white box block-chain-lot "
              style={{ width: "30vw" }}
            >
              <div className="flex align-start space-between ">
                <span className="white-text">
                  <b>{item.wasteType}&nbsp;</b>
                  <b className="bold cercle-purple-text ">
                    {item.totalQuantity}(MT)
                  </b>
                  <p className="small-text white-text  ">{item.date}</p>
                </span>

                <span className="flex gap-1">
                  <button className="blockchain-btn  btn cercle-btn ">
                    Async Alockchain
                  </button>
                  <span className="flex column ">
                    <b
                      className="bold small-text"
                      style={{
                        backgroundColor: item.colors[0],
                        padding: "2px 10px",
                        borderRadius: "2px",
                        marginBottom: "2px"
                      }}
                    >
                      {item.lotCode}
                    </b>
                    <div className="flex ">
                      {[1, 2, 3, 4, 5].map((item, key) => {
                        return (
                          <span
                            className="material-symbols-outlined cercle-purple-text"
                            style={{ fontSize: "14px" }}
                          >
                            star
                          </span>
                        );
                      })}
                    </div>
                  </span>
                </span>
              </div>
              <Collapsible accordion popout>
                <CollapsibleItem
                  expanded
                  header={
                    <div className="flex align-center space-between full-width block-chain-header  ">
                      <span className="flex align-center gap-1 ">
                        <span class="material-symbols-outlined normal-text white-text ">
                          leaderboard
                        </span>
                        <b>Lot Quantity Analytics</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                  className="collapsible-block-chain"
                >
                  <div style={{ height: "45vh" }}>
                    <ApexCharts
                      options={stackedBarOptions}
                      series={item.quantityAnalysis}
                      type="bar"
                      height={100}
                    />
                    <ApexCharts
                      options={chartOptions}
                      series={item.lotData}
                      type="treemap"
                      height={"80%"}
                      width={"95%"}
                    />
                  </div>
                </CollapsibleItem>
                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width block-chain-header ">
                      <span className="flex align-center gap-1 ">
                        <span class="material-symbols-outlined normal-text white-text ">
                          recycling
                        </span>
                        <b>Recycled Content Summary</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                  className="collapsible-block-chain"
                >
                  <div className="flex column white-text">
                    <span className="flex white-text align-center gap-1  ">
                      <span className="material-symbols-outlined normal-text ">
                        vaccines
                      </span>
                      <b className="bold title  ">Type of waste:</b>
                    </span>
                    <div className="ml-2 ">
                      <p className="normal-text">{item.wasteType}</p>
                    </div>
                    <hr color="#efefef" />

                    <span className="flex white-text align-center gap-1 ">
                      <span className="material-symbols-outlined normal-text ">
                        account_tree
                      </span>
                      <b className="bold title  ">
                        Input & Output Quantity Tracking:
                      </b>
                    </span>

                    <div className="ml-2 ">
                      <p className="normal-text">
                        Input Quantity:&nbsp;{item.totalQuantity}(MT)
                      </p>
                      <p className="normal-text">
                        Output Quantity:&nbsp;{item.outputQuantity} (MT)
                      </p>
                    </div>
                    <ApexCharts
                      options={stackedBarOptions}
                      series={item.recycledAnalysis}
                      type="bar"
                      height={85}
                    />

                    <hr color="#efefef" />

                    <span className="flex cercle-purple-text align-center gap-1 mt-1 white-text ">
                      <span className="material-symbols-outlined normal-text ">
                        vaccines
                      </span>
                      <b className="bold title  ">Recycled Content Overview:</b>
                    </span>

                    <div className="ml-2 white-text">
                      <RadioGroup
                        label=""
                        name=" "
                        options={[
                          {
                            label: "Post Consumer",
                            value: "post-consumer"
                          },
                          {
                            label: "Post Industrial ",
                            value: "Post-Industrial "
                          }
                        ]}
                        value="post-consumer"
                        withGap
                      />
                    </div>
                  </div>
                </CollapsibleItem>

                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width block-chain-header ">
                      <span className="flex align-center gap-1 ">
                        <span class="material-symbols-outlined normal-text white-text ">
                          description
                        </span>
                        <b>Attachments and Documents</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                  className="collapsible-block-chain"
                >
                  <div>
                    <div>
                      <span className="flex align-center space-between ">
                        <b className="white-text">Update Test report</b>
                        <label for="file-input" className="vehicle-photo">
                          <span class="material-symbols-outlined upload-photo-icon ">
                            cloud_upload
                          </span>
                          {testReport && (
                            <span className="flex align-center gap-1 ml-2 ">
                              <p className="verify-btn">Verify</p>
                              <p
                                className="reject-btn"
                                onClick={() => setTestReport(false)}
                              >
                                reject
                              </p>
                            </span>
                          )}
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>

                      <span className="flex align-center space-between ">
                        <b className="white-text">Invoices and Receipts</b>
                        <label for="file-input" className="vehicle-photo">
                          <span class="material-symbols-outlined upload-photo-icon ">
                            cloud_upload
                          </span>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>
                      <span className="flex align-center space-between mb-1 ">
                        <b className="white-text">processing report</b>
                        <a
                          className="view-btn "
                          rel="noreferrer"
                          target="_blank"
                          href="https://staging.api.wasteknot.thinktrash.co/recycledbatch/read/document/653b59c3d1853ba55ec9cd38"
                        >
                          View
                        </a>
                      </span>

                      <span className="flex align-center space-between mt-1/">
                        <b className="white-text">Traceability certificate </b>
                        <button className="view-btn ">View</button>
                      </span>
                    </div>

                    <span className="flex align-start space-between mt-1">
                      <b className="white-text">View lot journey</b>
                      <span className="flex column ">
                        <img
                          src="../images/track-qr.png"
                          style={{ height: "120px", width: "120px" }}
                        />
                        <button
                          className="view-btn  "
                          style={{ width: "120px" }}
                        >
                          View
                        </button>
                      </span>
                    </span>
                  </div>
                </CollapsibleItem>

                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width block-chain-header ">
                      <span className="flex align-center gap-1 ">
                        <span class="material-symbols-outlined normal-text white-text ">
                          compost
                        </span>
                        <b>Sustainability Credits Earned</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                  className="collapsible-block-chain"
                >
                  <div className="flex flex-wrap gap-1 ">
                    <span
                      className="flex  column justify-center align-center p-1 box block-chin-sustainability "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/co2.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px"
                        }}
                      />
                      <b>CO2</b>
                      <p>3.48</p>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box  block-chin-sustainability"
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/save-water.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px"
                        }}
                      />
                      <b>water saved</b>
                      <p>530L</p>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box block-chin-sustainability"
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/carbon-footprint.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px"
                        }}
                      />
                      <b>Carbon FootPrint</b>
                      <p>3.4068</p>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box block-chin-sustainability "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/energy-saving.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px"
                        }}
                      />
                      <b>Energy Saving</b>
                      <p>800</p>
                    </span>
                  </div>
                </CollapsibleItem>
              </Collapsible>
            </div>
          );
      })}
    </div>
  );
}

export default BlockchainLot;
