import React, { useEffect, useState } from "react";
import { Switch } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import { notify, notifyError } from "../../../helpers/helper";
import {
  getInvitations,
  inviteAccount,
} from "../../../redux/action/organization";
import { permissions } from "../../../helpers/permissions";

const InviteAccount = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [allPermissions, setAllPermissions] = useState([]);
  const [disAllowedPermissions, setDisAllowedPermissions] = useState([]);
  const dispatch = useDispatch();
  const { loadingAccounts } = useSelector((state) => state.organization);

  function sendInvitation(e) {
    if (loadingAccounts || !email || !role)
      return notifyError("Please fill all fields");

    e.preventDefault();

    const data = {
      email,
      role,
      permissions: allPermissions,
      disAllowedPermissions,
    };

    dispatch(
      inviteAccount(data, () => {
        dispatch(getInvitations());
        let elem = document.getElementById("invitation-modal");
        let instance = M.Modal.getInstance(elem);
        instance.close();
        notify("Invitation sent to user", "cercle-purple");
        setEmail("");
        setRole("");
        setAllPermissions([]);
      })
    );
  }

  useEffect(() => {
    if (role === "admin")
      setAllPermissions([...permissions["industry"], ...permissions["admin"]]);
    else if (role === "manager")
      setAllPermissions([...permissions["industry"]]);
    else setAllPermissions([]);
  }, [role]);

  return (
    <section>
      <p className="bold" style={{ margin: 0 }}>
        Invite An Account
      </p>
      <label htmlFor="">Invite a new account to your organization.</label>
      <div className="flex mt-1" style={{ gap: "20px" }}>
        <div className="full-width">
          <div className="">
            <p className="">Enter Email Of The Account</p>
            <div class="row">
              <input
                className="browser-default input-style "
                type="text"
                placeholder="Eg. johndoe@example.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>

          <div>
            <p>Select Role</p>
            <div className="row  ">
              <select
                className="input-style full-width browser-default "
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value={""}>--select--</option>
                <option value={"admin"}>Admin</option>
                <option value={"manager"}>Site Manager</option>
              </select>
            </div>
          </div>

          <div>
            <p>Standard Permissions</p>
            <ul>
              {permissions["industry"].map((permission) => (
                <li>
                  <div className="flex space-between">
                    <p className="small-text grey-text">{permission}</p>
                    <Switch
                      offLabel="Off"
                      onChange={function noRefCheck(c) {
                        if (allPermissions.includes(permission)) {
                          let index = allPermissions.findIndex(
                            (item) => item === permission
                          );
                          allPermissions.splice(index, 1);
                          setAllPermissions([...allPermissions]);

                          disAllowedPermissions.push(permission);
                          setDisAllowedPermissions([...disAllowedPermissions]);
                        } else {
                          allPermissions.push(permission);
                          setAllPermissions([...allPermissions]);

                          disAllowedPermissions.splice(
                            disAllowedPermissions.findIndex(
                              (item) => item === permission
                            ),
                            1
                          );
                          setDisAllowedPermissions([...disAllowedPermissions]);
                        }
                      }}
                      onLabel="On"
                      checked={
                        allPermissions.includes(permission) ? true : false
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-1 full-width">
          <p className="mb">Admin Permissions</p>

          <ul>
            {[
              "remove-location",
              "assign-location",
              "add-location",
              "remove-network",
              "add-member",
              "add-scraps",
              "accept-invitation",
              "remove-member",
              "remove-scrap",
              "add-network",
              "update-network",
            ].map((permission) => (
              <li>
                <div className="flex space-between">
                  <p className="small-text grey-text">{permission}</p>
                  <Switch
                    offLabel="Off"
                    onChange={function noRefCheck(c) {
                      if (allPermissions.includes(permission)) {
                        let index = allPermissions.findIndex(
                          (item) => item === permission
                        );
                        allPermissions.splice(index, 1);
                        setAllPermissions([...allPermissions]);

                        disAllowedPermissions.push(permission);
                        setDisAllowedPermissions([...disAllowedPermissions]);
                      } else {
                        allPermissions.push(permission);
                        setAllPermissions([...allPermissions]);

                        disAllowedPermissions.splice(
                          disAllowedPermissions.findIndex(
                            (item) => item === permission
                          ),
                          1
                        );
                        setDisAllowedPermissions([...disAllowedPermissions]);
                      }
                    }}
                    onLabel="On"
                    checked={allPermissions.includes(permission)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex justify-center mt-1">
        <button className="btn mt-1" onClick={sendInvitation}>
          {loadingAccounts ? "Sending Invitation..." : "Send Invitation"}
        </button>
      </div>
    </section>
  );
};

export default InviteAccount;
