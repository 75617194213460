import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "react-apexcharts";
import SingleAggregator from "../components/SingleAggregator";
import aggregators from "./aggregators.json";
import { Link } from "react-router-dom";

function CreateLot() {
  const [lotUniqueKey, setLotUniqueKey] = useState(Math.random());
  const [analyticsKey, setAnalticsKey] = useState(Math.random());
  const [wasteType, setWaistType] = useState("Plastic-LLDPE");

  const [lotData, setLotData] = useState([
    {
      data: []
    }
  ]);

  const filteredAggregators = () => {
    setAnalticsKey(Math.random());
  };

  const aggregatorName = [];
  const [quantityAnalysis, setQuantityAnalysis] = useState(aggregatorName);

  const updateData = (newQuantity, aggregatorName) => {
    const itemIndex = quantityAnalysis.findIndex(
      (item) => item.name == aggregatorName
    );
    if (itemIndex !== -1) {
      const newData = [...quantityAnalysis];
      newData[itemIndex].quantity += newQuantity;

      const totalQuantity = newData.reduce(
        (total, item) => total + item.quantity,
        0
      );

      newData.forEach((item) => {
        item.data[0] = ((item.quantity / totalQuantity) * 100).toFixed(2);
      });

      setQuantityAnalysis(newData);
      setAnalticsKey(Math.random());
    }
  };

  const stackedBarOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      stacked: true
    },

    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    xaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: false,
      borderColor: "transparent",
      position: "back"
    },
    colors: ["#6f2da8", "#EC3710", "#55EC10", "#10EC9A"],
    tooltip: {
      enabled: true,
      intersect: true,
      custom: function ({ series3, seriesIndex, dataPointIndex, w }) {
        console.log(series3, seriesIndex);
        return `<div class="custom-tooltip">
             hello
        </div>`;
      }
    }
  };
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "treemap",
      toolbar: { show: false },
      events: {
        click: (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) => {
          handleLotData(dataPointIndex);
        }
      }
    },
    title: {
      text: "",
      align: "left",
      enabled: false
    },

    colors: ["#6f2da8", "#6f2da8", "#EC9800", "#9EDE00"]
  });

  const handleLotData = (index) => {};
  const addItem = () => {
    aggregators.map((item) => {
      const newItem = { name: item.name, data: [], quantity: 0 };
      aggregatorName.push(newItem);
    });
  };

  useEffect(() => {
    addItem();
  }, [aggregators]);

  return (
    <div className="p-1">
      <div className="flex align-center space-between mb-1 ">
        <span className="flex  column ">
          <b className="bold  ">Create Lot</b>
          <p className="normal-text">20PP00CX</p>
        </span>
        <div>
          <select
            className="icons browser-default "
            style={{ width: "15vw" }}
            onChange={(e) => {
              setWaistType(e.target.value);
              filteredAggregators();
            }}
          >
            <option value="" disabled selected>
              Choose your option
            </option>
            <option value="Plastic-LDPE" class="left">
              Plastic-LDPE
            </option>
            <option value="Plastic-PP" class="left">
              Plastic-PP
            </option>
            <option value="Plastic-LLDPE" class="left">
              Plastic-LLDPE
            </option>
          </select>
        </div>
      </div>

      <div className="row gap-2 flex ">
        <div className="col  s7">
          <div className="flex-wrap align-center align-center gap-1 flex ">
            {aggregators.map((item, index) => {
              return (
                <SingleAggregator
                  data={item.pickups}
                  name={item.name}
                  colors={item.colors}
                  setLotData={setLotData}
                  lotData={lotData}
                  key={index}
                  setLotUniqueKey={setLotUniqueKey}
                  updateData={updateData}
                  virtualBinsCode={item.virtualBinsCode}
                  wasteType={wasteType}
                />
              );
            })}
          </div>
        </div>
        <div className="col s5 white box " style={{ padding: "10px" }}>
          <div className=" flex align-center space-between ">
            <b>New Lot</b>
          </div>
          <ApexCharts
            options={stackedBarOptions}
            series={quantityAnalysis}
            type="bar"
            height={120}
            key={analyticsKey}
          />
          <ApexCharts
            key={lotUniqueKey}
            options={chartOptions}
            series={lotData}
            type="treemap"
            height={"65%"}
            width={"100%"}
          />
          <div className="align-end flex justify-end mt-1 ">
            <Link to={"/lot-history"}>
              <button
                className="btn cercle-btn "
                disabled={lotData[0].data.length == 0}
              >
                Submit
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateLot;
