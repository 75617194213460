import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { toast } from "react-toastify";

const AddLocation = ({ addLocation, loading, values, setValues, google }) => {
  const [address, setAddress] = React.useState("");
  const [mapCenter, setMapCenter] = React.useState({
    longitude: 0,
    latitude: 0,
  });

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setMapCenter({
          longitude: latLng.lng,
          latitude: latLng.lat,
        });

        setValues({
          ...values,
          location: {
            nickname: values.location.nickname,
            longitude: latLng.lng,
            latitude: latLng.lat,
            address,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  async function moveMarker(e, m) {
    try {
      setMapCenter({
        latitude: m.position.lat(),
        longitude: m.position.lng(),
      });

      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${m.position.lat()},${m.position.lng()}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`
      );
      setAddress(data.results[0].formatted_address);
      setValues({
        ...values,
        location: {
          longitude: m.position.lng(),
          latitude: m.position.lat(),
          address: data.results[0].formatted_address,
          nickname: values.location.nickname,
        },
      });
    } catch (err) {
      toast.error("Error getting address", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  }

  return (
    <div className="add-location-container">
      <div className="map-content">
        <div className="mt-1 mb-2">
          <label htmlFor="">Nickname</label>
          <input
            type="text"
            id="id"
            onChange={(e) => {
              setValues({
                ...values,
                location: {
                  ...values.location,
                  nickname: e.target.value,
                },
              });
            }}
            className="browser-default input-style"
            placeholder="Enter A Nickname for the address"
            value={values.location.nickname}
          />
        </div>

        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label>Search Location</label>
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "browser-default input-style select-item",
                })}
                style={{ padding: "5px" }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                        padding: 5,
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        padding: 5,
                      };
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <button
          className="btn mt-2"
          onClick={addLocation}
          disabled={
            loading || !values.location.nickname || !values.location.address
          }
        >
          Add Location
        </button>
      </div>

      <div className="add-location-map">
        <Map
          google={google}
          zoom={mapCenter.latitude ? 15 : 1}
          center={{
            lat: mapCenter.latitude,
            lng: mapCenter.longitude,
          }}
          containerStyle={{
            position: "static",
            boxShadow:
              "4px 4px 8px rgba(0,0,0,0.2),-4px -4px 8px rgba(255,255,255,1)",
          }}
        >
          {mapCenter.latitude && mapCenter.longitude && (
            <Marker
              title={"Your location"}
              position={{
                lat: mapCenter.latitude,
                lng: mapCenter.longitude,
              }}
              draggable={true}
              onDragend={moveMarker}
            />
          )}
        </Map>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo",
})(AddLocation);
