export const contractsReducer = function (
  state = { loading: false, contracts: [], summary: {}, priceUpdates: [] },
  action
) {
  switch (action.type) {
    case "GETTING_CONTRACTS":
      return { ...state, loading: true };

    case "GOT_CONTRACTS":
      return { ...state, loading: false, contracts: action.payload };

    case "GOT_SUMMARY":
      return { ...state, loading: false, summary: action.payload };

    case "CANNOT_GET_CONTRACT":
      return { ...state, loading: false };

    case "ADDING_CONTRACT":
      return { ...state, loading: true };

    case "ADDED_CONTRACT":
      return { ...state, loading: false };

    case "CANNOT_ADD_CONTRACT":
      return { ...state, loading: false };

    case "UPDATING_CONTRACT":
      return { ...state, loading: true };

    case "UPDATED_CONTRACT":
      return { ...state, loading: false };

    case "CANNOT_UPDATE_CONTRACT":
      return { ...state, loading: false };

    default:
      return state;
  }
};
