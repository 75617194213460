import React from "react";
import { Link } from "react-router-dom";
import virtualCodeData from "../pages/inventory/children/virtualCodeData.json";

function CodeInputSearch() {
  const [singleVirtualCodeData, setSingleVirtualCodeData] =
    React.useState(null);
  const [virtualCode, setVirtualCode] = React.useState("");
  const [codeNotFound, setCodeNotFound] = React.useState(false);

  function findItemByCode(codeToFind) {
    for (const item of virtualCodeData) {
      for (const virtualBin of item.virtualBins) {
        console.log(virtualBin);
        if (virtualBin.code === codeToFind) {
          const updatedObject = { ...item };
          updatedObject.virtualBin = virtualBin;
          setSingleVirtualCodeData(updatedObject);
          return;
        }
      }
    }
    setCodeNotFound(true);
    return;
  }

  if (codeNotFound) {
    return (
      <div className="flex column align-center justify-center mt-1 ">
        <p>Code Not Found</p>
        <u
          className="cercle-purple-text"
          onClick={() => setCodeNotFound(false)}
        >
          search
        </u>
      </div>
    );
  }

  return (
    <div style={{ height: "15vh" }}>
      {!singleVirtualCodeData ? (
        <div className="p-1 flex column ">
          <b>Enter Virtual Bin Code</b>
          <span className="flex align-center gap-1 ">
            <input
              type="text"
              className="browser-default search-input"
              style={{ width: "85%" }}
              placeholder="Enter Virtual Code..."
              onChange={(e) => setVirtualCode(e.target.value)}
            />
            <div
              style={{
                backgroundColor: "#efefef",
                width: "40px",
                height: "40px"
              }}
              className="circle flex align-center justify-center "
              onClick={() => {
                findItemByCode(virtualCode);
              }}
            >
              <span className="material-symbols-outlined normall-text ">
                search
              </span>
            </div>
          </span>
        </div>
      ) : (
        <div className="p-1 flex column ">
          <span className="flex align-center space-between ">
            <b>Virtual Bin Code Details: </b>
            <Link to={"/aggregators"}>
              <u className="cercle-purple-text normal-text ">View All</u>
            </Link>
          </span>
          <hr />
          <span className="flex gap-1 align-center mt-1 ">
            <b>Virtual Bins Code:</b>
            <p className=" grey-text ">
              {singleVirtualCodeData.virtualBin.code}
            </p>
          </span>
          <span className="flex gap-1 align-center  ">
            <b>Aggregator Name:</b>
            <p className=" grey-text ">{singleVirtualCodeData.aggregatore}</p>
          </span>
          <span className="flex gap-1 align-center  ">
            <b>Available Quantity:</b>
            <p className=" grey-text ">
              {singleVirtualCodeData.virtualBin.quantity}
            </p>
          </span>
        </div>
      )}
    </div>
  );
}

export default CodeInputSearch;
