import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-materialize";
import { editAppTitle } from "../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";

function Aside({ openAside, setOpenAside }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.organization);
  const logoutUser = () => {
    dispatch(logoutAction());
  };
  return (
    <aside className={openAside ? "aside-list-open" : " aside-list"}>
      <ul className="aside-items-list">
        <li
          className={location.pathname === "/" ? "active-aside-items-list" : ""}
          onClick={() => {
            navigate("/");
            setOpenAside(false);
            editAppTitle("Dashboard");
          }}
        >
          <span className="material-symbols-outlined icon ">dashboard</span>
          {openAside && <p className="semi-bold">Dashboard</p>}
        </li>

        <li
          className={
            location.pathname === "/pickups" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/pickups");
            setOpenAside(false);
            editAppTitle("Pickups");
          }}
        >
          <span className="material-symbols-outlined icon ">
            local_shipping
          </span>
          {openAside && <p className="semi-bold">Pickups</p>}
        </li>

        {organization?.type === "recycler" && (
          <li
            className={
              location.pathname === "/inventory"
                ? "active-aside-items-list"
                : ""
            }
            onClick={() => {
              navigate("/inventory");
              setOpenAside(false);
              editAppTitle("Pickups");
            }}
          >
            <span className="material-symbols-outlined icon ">inventory</span>
            {openAside && <p className="semi-bold">Inventory</p>}
          </li>
        )}

        <li
          className={
            location.pathname === "/network" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/network");
            setOpenAside(false);
            editAppTitle("Network");
          }}
        >
          <span className="material-symbols-outlined icon ">
            <span class="material-symbols-outlined icon">groups</span>
          </span>
          {openAside && <p className="semi-bold">Network</p>}
        </li>

        <li
          className={
            location.pathname === "/organization"
              ? "active-aside-items-list"
              : ""
          }
          onClick={() => {
            navigate("/organization");
            setOpenAside(false);
            editAppTitle("Organization");
          }}
        >
          <span className="material-symbols-outlined icon ">person</span>
          {openAside && <p className="semi-bold">Users</p>}
        </li>

        <li
          className={
            location.pathname === "/reports" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/reports");
            setOpenAside(false);
            editAppTitle("Reports");
          }}
        >
          <span className="material-symbols-outlined">summarize</span>
          {openAside && <p className="semi-bold">Reports</p>}
        </li>

        <li
          className={
            location.pathname === "/setting" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/setting");
            setOpenAside(false);
            editAppTitle("Settings");
          }}
        >
          <span className="material-symbols-outlined icon ">
            manage_accounts
          </span>
          {openAside && <p className="semi-bold">Settings</p>}
        </li>
      </ul>

      <img src="/images/wasteknot_logo.png" alt="logo" />
    </aside>
  );
}

export default Aside;
