import React from "react";
import {
  XAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subMonths } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getQuantityOverTime } from "../../../redux/action/analytics";

function QuantityOverTime({ organization }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const dispatch = useDispatch();

  const { quantityOverTime, quantityOverTimeLoading } = useSelector(
    (state) => state.analytics
  );

  useEffect(() => {
    const start = subMonths(new Date(), 1);
    setStartDate(start);
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    dispatch(getQuantityOverTime(startDate, endDate, organization));
  }, [startDate, endDate, dispatch, organization]);

  console.log(startDate, endDate);

  return (
    <div className=" p-2 mb-2 box white " style={{ height: "300px" }}>
      <div className="flex space-between">
        <p className="bold">Waste Tonnage Channelized</p>

        {quantityOverTimeLoading ? (
          <ClipLoader color="#6f2da8" size={20} />
        ) : (
          <div className="flex hover ">
            <div className="flex align-center gap-1 chart-date-picker">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="browser-default "
              />
              <span className="material-symbols-outlined">calendar_month</span>
            </div>
          </div>
        )}
      </div>

      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          data={quantityOverTime}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="1" x2="0">
              <stop offset="20%" stopColor="#FF9100" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#FF507D" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis dataKey="quantity" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="quantity"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default QuantityOverTime;
