import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWMURL } from "../../../redux/config/urlConfig";
import { Button, Dropdown, Modal as MModal } from "react-materialize";
import { removeScraps } from "../../../redux/action/organization";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import AddMoreScraps from "./AddMoreScraps";
import Modal from "react-responsive-modal";
import { useState } from "react";

const Scraps = () => {
  const { organization } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const [open, setOpen] = useState(false);

  console.log(account.permissions);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        classNames={{
          modal: "customModal",
        }}
      >
        <AddMoreScraps />
      </Modal>
      <div className="flex mt-1 space-between align-center">
        <p>Scraps you've added to your organization.</p>

        <button
          className="btn mt-1"
          onClick={() => {
            setOpen(true);
          }}
          disabled={!account.permissions.includes("add-scraps")}
        >
          Add More Scraps
        </button>
      </div>

      <div className="full-width box mt-2">
        <table
          className={
            !isTablet
              ? "striped  payment-history  full-width"
              : " striped  payment-history small-text full-width isTablet"
          }
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>name</th>
              {/* <th>Action </th> */}
            </tr>
          </thead>

          <tbody>
            {organization.scraps?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="lowercase">
                    <img
                      src={`${IWMURL}/scraps/read/image/${item._id}`}
                      alt="scrap"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                  </td>
                  <td className="capitalize">
                    {item?.name}-{item.variant}
                  </td>

                  {account.permissions.includes("remove-scrap") && (
                    <td>
                      <Dropdown
                        trigger={
                          <div>
                            <i class="material-symbols-outlined">more_vert</i>
                          </div>
                        }
                        id={"scrap" + index}
                      >
                        {account.permissions.includes("remove-scrap") && (
                          <MModal
                            header="Remove Scrap"
                            actions={[]}
                            trigger={<span>{"Remove Scrap"}</span>}
                          >
                            <p>
                              Are you sure you want to remove this scrap from
                              your organization?
                            </p>

                            <div className="flex gap-1 mt-1">
                              <button
                                className="btn-small"
                                onClick={() => {
                                  dispatch(
                                    removeScraps({ scraps: [item._id] }, () => {
                                      toast.success(
                                        "Scrap removed successfully!",
                                        {
                                          position: toast.POSITION.BOTTOM_LEFT,
                                          hideProgressBar: true,
                                          autoClose: 3000,
                                        }
                                      );
                                    })
                                  );
                                }}
                              >
                                Yes
                              </button>

                              <Button
                                className="btn-small white purple-text"
                                modal="close"
                              >
                                Cancel
                              </Button>
                            </div>
                          </MModal>
                        )}
                      </Dropdown>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Scraps;
