import React, { useState } from "react";
import { Button, Modal } from "react-materialize";
import { notify, notifyError } from "../../../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getClosestCercleXNetwork,
  getNetworkInvitations,
  inviteNetwork,
} from "../../../redux/action/organization";
import { ClipLoader } from "react-spinners";

const AddExternalNetwork = () => {
  const [location, setLocation] = useState("");
  const [scrap, setScrap] = useState("");
  const [selected, setSelected] = useState([]);
  const { organization, networkLoading, closestNetwork, invitations } =
    useSelector((state) => state.organization);
  const dispatch = useDispatch();

  function getClosest(e) {
    e.preventDefault();
    const loc = organization.locations.find((loc) => loc._id === location);

    if (!loc)
      return toast.error("Please select a location", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const scrapDetailed = organization.scraps.find((sp) => sp._id === scrap);

    if (!scrapDetailed)
      return toast.error("Please select a scrap", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const { latitude, longitude } = loc;

    dispatch(
      getClosestCercleXNetwork({
        latitude,
        longitude,
        dealsWith: scrapDetailed.type,
      })
    );
  }

  return (
    <div>
      <div className="mb-2 flex align-center column">
        <p className="bold text-center">Add from Cercle X Network</p>
        <p className="small-text">
          Onboard Cercle X's network of verified, authorized waste processors.
        </p>
      </div>

      <div className="flex full-width gap-1 justify-center ">
        <div className="full-width flex column align-center">
          <label htmlFor="">Select your location</label>
          <div
            className="input-style flex align-center"
            style={{
              width: "100%",
              padding: "0 10px",
              borderRadius: "15px",
              height: "40px",
              border: "1px solid #ccc",
            }}
          >
            <span className="material-symbols-outlined grey-text mr-1">
              location_on
            </span>
            <select
              className="browser-default "
              onChange={(e) => setLocation(e.target.value)}
              value={location}
            >
              <option value={""}>--select--</option>
              {organization.locations?.map((location) => {
                return (
                  <option value={location._id}>
                    {location.nickname}-{location.address}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="full-width flex column align-center ">
          <label htmlFor="">Select scrap</label>
          <div
            className="input-style flex align-center"
            style={{
              width: "100%",
              padding: "0 10px",
              borderRadius: "15px",
              height: "40px",
              border: "1px solid #ccc",
            }}
          >
            <span className="material-symbols-outlined grey-text mr-1">
              category
            </span>
            <select
              className="browser-default "
              onChange={(e) => setScrap(e.target.value)}
              value={scrap}
            >
              <option value={""}>--select--</option>
              {organization.scraps?.map((scrap) => {
                return (
                  <option value={scrap._id}>
                    {scrap.name}-{scrap.variant}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="flex align-end">
          <button className="btn-rounded " onClick={getClosest}>
            <span className="material-symbols-outlined grey-text ">search</span>
          </button>
        </div>
      </div>

      <div className="mt-2 full-width  flex-wrap flex">
        {networkLoading ? (
          <div className="flex justify-center full-width">
            <ClipLoader size={24} color="#6f2da8" />
          </div>
        ) : closestNetwork.length === 0 ? (
          <p className="grey-text text-center full-width">No network found</p>
        ) : (
          closestNetwork.map((network, i) => {
            return (
              <Modal
                key={i}
                actions={[]}
                trigger={
                  <div
                    className={
                      selected.includes(network._id)
                        ? "scrap-selected"
                        : "scrap-select"
                    }
                    style={{ height: "220px" }}
                    role="button"
                    onClick={() => {
                      if (selected.includes(network._id))
                        setSelected(selected.filter((s) => s !== network._id));
                      else setSelected([...selected, network._id]);
                    }}
                  >
                    <img
                      src={`images/factory.png`}
                      alt="scrap"
                      style={{ objectFit: "contain" }}
                    />
                    <div
                      className="flex column align-center"
                      style={{ padding: "0 10px" }}
                    >
                      <p className="cercle-purple-text full-width truncate text-center">
                        {network.organization}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.name}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.location?.city}
                      </p>
                    </div>
                  </div>
                }
              >
                <p className="bold mb-1">Add To Your Network</p>

                <div className="mt-1">
                  <div className="mb-1">
                    <label htmlFor="">Organization </label>
                    <p>{network?.organization}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">POC </label>
                    <p className="capitalize">{network?.name}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">Location</label>
                    <p>{network?.location?.address}</p>
                  </div>
                </div>

                <div
                  className=" flex  gap-1 align-center mb-1 rounded"
                  style={{ padding: "5px" }}
                >
                  <span
                    className="material-symbols-outlined grey-text
  cercle-purple-text"
                  >
                    info
                  </span>
                  <p className="cercle-purple-text small-text">
                    An Invitation will be sent through Email and SMS to the
                    selected organization
                  </p>
                </div>

                <div className="flex gap-1">
                  <Button
                    className="btn  mt-1  "
                    modal="close"
                    onClick={() => {
                      let invitation = invitations.find(
                        (i) => i.organizationName === network.organization
                      );

                      if (invitation)
                        return notifyError("Network Added already!");

                      dispatch(
                        inviteNetwork(
                          {
                            organizationName: network.organization,
                            phoneNumber: network.phoneNumber,
                            role: "trader/aggregator",
                            scraps: [scrap],
                            location: location,
                          },
                          () => {
                            let element =
                                document.getElementById("invitation-modal"),
                              instance = window.M.Modal.getInstance(element);
                            instance.close();
                            dispatch(getNetworkInvitations());
                            notify("Invitation sent successfully");
                          }
                        )
                      );
                    }}
                  >
                    Add Network
                  </Button>

                  <Button
                    modal="close"
                    className="btn-outline  mt-1 first-time-but "
                  >
                    Cancel
                  </Button>
                </div>
              </Modal>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AddExternalNetwork;
