import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllScraps } from "../../../redux/action/scraps";
import { useQuery } from "../../../helpers/helper";
import SelectNetworkOptions from "./SelectNetworkOptions";
import PersonalNetwork from "./PersonalNetwork";
import ExternalNetwork from "./ExternalNetwork";

const Step5 = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const networkType = query.get("network");

  useEffect(() => {
    dispatch(getAllScraps());
  }, [dispatch]);

  return (
    <div className="flex grey lighten-4">
      {!networkType && <SelectNetworkOptions />}
      {networkType === "personal" && <PersonalNetwork />}
      {networkType === "external" && <ExternalNetwork />}
    </div>
  );
};

export default Step5;
