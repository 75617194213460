import React, { useEffect } from "react";
import Chips from "./Chips";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWMURL } from "../../../redux/config/urlConfig";
import { ClipLoader } from "react-spinners";
import "react-responsive-modal/styles.css";
import {
  finishSetup,
  getClosestCercleXNetwork,
  getInvitations,
  getNetworkInvitations,
  inviteNetwork,
  rescindInvitation,
} from "../../../redux/action/organization";
import { toast } from "react-toastify";
import { Button } from "react-materialize";
import Modal from "react-responsive-modal";
import { notify, notifyError } from "../../../helpers/helper";

const ExternalNetwork = () => {
  const [location, setLocation] = useState("");
  const [scrap, setScrap] = useState("");
  const { network } = useSelector((state) => state.account);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { loading, organization, networkLoading, closestNetwork, invitations } =
    useSelector((state) => state.organization);
  const dispatch = useDispatch();

  function getClosest(e) {
    e.preventDefault();
    const loc = organization.locations.find((loc) => loc._id === location);

    if (!loc)
      return toast.error("Please select a location", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const scrapDetailed = organization.scraps.find((sp) => sp._id === scrap);

    if (!scrapDetailed)
      return toast.error("Please select a scrap", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const { latitude, longitude } = loc;

    dispatch(
      getClosestCercleXNetwork({
        latitude,
        longitude,
        dealsWith: scrapDetailed.type,
      })
    );
  }

  return (
    <>
      <Modal open={showModal} onClose={() => {}} center>
        <p className="bold mb-1">Add To Your Network</p>

        <div className="mt-1">
          <div className="mb-1">
            <label htmlFor="">Organization </label>
            <p>{selectedNetwork?.organization}</p>
          </div>

          <div className="mb-1">
            <label htmlFor="">POC </label>
            <p className="capitalize">{selectedNetwork?.name}</p>
          </div>

          <div className="mb-1">
            <label htmlFor="">Location</label>
            <p>{selectedNetwork?.location?.address}</p>
          </div>
        </div>

        <div
          className=" flex  gap-1 align-center mb-1 rounded"
          style={{ padding: "5px" }}
        >
          <span
            className="material-symbols-outlined grey-text
        cercle-purple-text"
          >
            info
          </span>
          <p className="cercle-purple-text small-text">
            An Invitation will be sent through Email and SMS to the selected
            organization
          </p>
        </div>

        <div className="flex gap-1">
          <Button
            className="btn  mt-1  "
            onClick={() => {
              let invitation = invitations.find(
                (i) => i.organizationName === selectedNetwork.organization
              );

              if (invitation) return notifyError("Network Added already!");

              console.log("selectedNetwork", selectedNetwork);

              dispatch(
                inviteNetwork(
                  {
                    organizationName: selectedNetwork.organization,
                    phoneNumber: selectedNetwork.phoneNumber,
                    role: "trader/aggregator",
                    scraps: [scrap],
                    location: location,
                  },
                  () => {
                    dispatch(getNetworkInvitations());
                    notify("Invitation sent successfully");
                    setShowModal(false);
                  }
                )
              );
            }}
          >
            Add Network
          </Button>

          <Button
            modal="close"
            className="btn-outline  mt-1 first-time-but "
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div className="container  first-time-container flex column align-center space-between">
        <div className="flex column align-center">
          <Chips active={5} />
          <p className="bold mt-2 mb-2">Step 5 out of 5 </p>

          <h3>We've got you covered</h3>
          <p className="grey-text" style={{ fontSize: "20px" }}>
            Onboard Cercle X's network of verified, authorized waste processors.
          </p>
        </div>

        <div
          className="dialogue-box"
          style={{ height: "70vh", marginBottom: 0, overflowY: "auto" }}
        >
          <div className="flex full-width gap-1 justify-center ">
            <div className="full-width flex column align-center">
              <label htmlFor="">Select your location</label>
              <div
                className="input-style flex align-center"
                style={{
                  width: "100%",
                  padding: "0 10px",
                  borderRadius: "15px",
                  height: "40px",
                  border: "1px solid #ccc",
                }}
              >
                <span className="material-symbols-outlined grey-text mr-1">
                  location_on
                </span>
                <select
                  className="browser-default "
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                >
                  <option value={""}>--select--</option>
                  {organization.locations?.map((location) => {
                    return (
                      <option value={location._id}>
                        {location.nickname}-{location.address}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="full-width flex column align-center ">
              <label htmlFor="">Select scrap</label>
              <div
                className="input-style flex align-center"
                style={{
                  width: "100%",
                  padding: "0 10px",
                  borderRadius: "15px",
                  height: "40px",
                  border: "1px solid #ccc",
                }}
              >
                <span className="material-symbols-outlined grey-text mr-1">
                  category
                </span>
                <select
                  className="browser-default "
                  onChange={(e) => setScrap(e.target.value)}
                  value={scrap}
                >
                  <option value={""}>--select--</option>
                  {organization.scraps?.map((scrap) => {
                    return (
                      <option value={scrap._id}>
                        {scrap.name}-{scrap.variant}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="flex align-end">
              <button className="btn-rounded " onClick={getClosest}>
                <span className="material-symbols-outlined grey-text ">
                  search
                </span>
              </button>
            </div>
          </div>

          <div className="mt-2 full-width flex">
            {networkLoading ? (
              <div className="flex justify-center full-width">
                <ClipLoader size={24} color="#6f2da8" />
              </div>
            ) : closestNetwork.length === 0 ? (
              <p className="grey-text text-center full-width">
                No network found
              </p>
            ) : (
              closestNetwork.map((network, i) => {
                return (
                  <div
                    className={"scrap-select"}
                    style={{ height: "220px" }}
                    role="button"
                    onClick={() => {
                      // if (selected.includes(network._id))
                      //   setSelected(selected.filter((s) => s !== network._id));
                      // else setSelected([...selected, network._id]);
                      setSelectedNetwork(network);
                      setShowModal(true);
                    }}
                  >
                    <img
                      src={`images/factory.png`}
                      alt="scrap"
                      style={{ objectFit: "contain" }}
                    />
                    <div
                      className="flex column align-center"
                      style={{ padding: "0 10px" }}
                    >
                      <p className="cercle-purple-text full-width truncate text-center">
                        {network.organization}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.name}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.location?.city}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <button
          className="btn  mb-1 mt-1 first-time-but green"
          onClick={() => {
            dispatch(
              finishSetup({}, () => {
                window.location.href = "/";
              })
            );
          }}
          disabled={loading || !invitations.length}
        >
          {loading ? <ClipLoader size={20} color="#fff" /> : "Finish"}
        </button>
      </div>

      <SideBar hideOnSmallDevices={true} />
    </>
  );
};

const SideBar = ({ hideOnSmallDevices }) => {
  const { scraps } = useSelector((state) => state.scraps);
  const { organization, invitations, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNetworkInvitations());
  }, [dispatch]);
  return (
    <div
      className={`first-time-aside ${
        hideOnSmallDevices && "hide-on-med-and-down"
      }`}
    >
      <div className="flex align-center gap-1 ">
        <h3>Your Added Network</h3>
        <ClipLoader size={20} color="#6f2da8" loading={loadingAccounts} />
      </div>

      <div className="first-time-aside-list-container full-width">
        {!invitations.length ? (
          <div
            className="flex justify-center column align-center  p-2 "
            style={{ height: "70%" }}
          >
            <i
              className="material-symbols-outlined grey-text"
              style={{ fontSize: 40 }}
            >
              info
            </i>
            <p className="grey-text small-text">No network found</p>
          </div>
        ) : (
          invitations.map((network, index) => (
            <div
              className="grey lighten-4 full-width p-1 flex column align-end"
              style={{ borderBottom: "5px solid #fff" }}
              key={index}
            >
              <div className="full-width flex space-between">
                <div className="flex ">
                  <img
                    src={`images/factory.png`}
                    alt="network"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />

                  <div>
                    <p>{network.organizationName}</p>
                    <p className="small-text">
                      {
                        scraps.find((scrap) => scrap._id === network.scraps[0])
                          ?.name
                      }
                      -
                      {
                        scraps.find((scrap) => scrap._id === network.scraps[0])
                          ?.variant
                      }
                    </p>
                    <p className="small-text">{network.location?.address}</p>
                    <p className="small-text flex">
                      {
                        organization.locations?.find(
                          (location) => location._id === network.location
                        )?.nickname
                      }
                    </p>
                  </div>
                </div>

                <button
                  className="btn-outline"
                  onClick={() => {
                    dispatch(
                      rescindInvitation(network._id, () => {
                        notify("Invitation Revoked");
                        dispatch(getNetworkInvitations());
                      })
                    );
                  }}
                  disabled={loadingAccounts}
                >
                  Cancel
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ExternalNetwork;
