import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addGroup } from "../../redux/action/general";
import { toast } from "react-toastify";

function AddGroup() {
  const dispatch = useDispatch();
  const [newGroup, setNewGroup] = useState({
    name: "",
    location: "",
    description: "",
  });

  const handelSubmitGroup = (e) => {
    e.preventDefault();
    if (newGroup.name === "") {
      toast.error(" can't create group without name");
    } else if (newGroup.location === "") {
      toast.error("can't create group without location");
    } else if (newGroup.location === "") {
      toast.error("can't create group without description");
    } else {
      dispatch(addGroup(newGroup));
      setNewGroup({ name: "", location: "", description: "" });
    }
  };

  return (
    <div className="row">
      <form onSubmit={handelSubmitGroup} class=" col s6">
        <div className="input-style ">
          <span class="material-symbols-outlined primary ">groups</span>
          <input
            className="browser-default input-field "
            placeholder="Group Name"
            type="text"
            value={newGroup.name}
            onChange={(e) => {
              setNewGroup({ ...newGroup, name: e.target.value });
            }}
          />
        </div>

        <div className="input-style ">
          <span class="material-symbols-outlined primary ">location_on</span>
          <input
            className="browser-default input-field "
            placeholder="Group Location"
            type="text"
            value={newGroup.location}
            onChange={(e) => {
              setNewGroup({ ...newGroup, location: e.target.value });
            }}
          />
        </div>

        <div className="text-area-style">
          <span class="material-symbols-outlined primary ">mail</span>
          <textarea
            className="browser-default input-field "
            placeholder="Description..."
            type="text"
            value={newGroup.description}
            onChange={(e) => {
              setNewGroup({ ...newGroup, description: e.target.value });
            }}
          />
        </div>

        <div className="submit-btn-modal mv-2 ">
          <button className="btn-large" type="submit">
            Create Group
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddGroup;
