import React, { useEffect } from "react";
import { Button, Modal } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  acceptPickup,
  getAvailablePickups,
  getOrders,
  ignorePickup
} from "../../redux/action/pickups";
import { IWMURL } from "../../redux/config/urlConfig";
import { notify } from "../../helpers/helper";
import { ClipLoader } from "react-spinners";
import DashboardPickupHistoryTable from "../dashboard/components/DashboardPickupsHistory";
import CreateOrder from "./components/CreateOrder";
import OrderBox from "./components/OrderBox";

const PickupsReycler = () => {
  const { availablePickups, contract, accepting, loading } = useSelector(
    (state) => state.pickups
  );
  const { organization } = useSelector((state) => state.organization);
  const [location, setLocation] = React.useState("");
  const { orders, orderLoading } = useSelector((state) => state.pickups);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailablePickups());
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <div className="p-1">
      <div className="flex align-center gap-1 mb-1 space-between ">
        <h3 className="bold">Pickups & Orders</h3>

        <Modal
          trigger={
            <Button
              className="btn"
              tooltip="Source scrap from your network. Create an order for scrap  now"
            >
              Place An Order For Scrap
            </Button>
          }
          actions={[]}
          id="create-order-modal"
        >
          <CreateOrder />
        </Modal>
      </div>

      <div className="flex gap-1 order-container">
        {orderLoading ? (
          <div className="flex justify-center full-width mt-2 mb-2">
            <ClipLoader color="#6f2da8" size={20} />
          </div>
        ) : !orders.length ? (
          <div className="flex justify-center">
            <div className="flex column align-center full-width  mv-2 p-2">
              <i
                className="material-symbols-outlined grey-text"
                style={{ fontSize: 80 }}
              >
                inventory_2
              </i>
              <p className="grey-text mt-1 ">
                You have no orders in your inventory.
              </p>
            </div>
          </div>
        ) : (
          orders.map((order, index) => {
            return <OrderBox order={order} key={index} />;
          })
        )}
      </div>

      <div className="box p-1 mb-1 white">
        <div className="flex space-between">
          <p className="bold">Available Pickups</p>

          {loading && <ClipLoader color="#6f2da8" size={20} />}
        </div>

        {availablePickups.length === 0 ? (
          <div className="flex justify-center">
            <div className="flex column align-center full-width  mv-2 p-2">
              <img
                src="../images/pickup.png"
                style={{ width: "130px", height: "100px" }}
                alt="pickup"
              />
              <p className="grey-text mt-1 ">
                No Pickups available at the moment.
              </p>
            </div>
          </div>
        ) : (
          <table className="striped  payment-history  ">
            <thead>
              <tr>
                <th>Scrap</th>
                <th>Date</th>
                <th>Location</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {availablePickups?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span className="flex align-center gap-1 ">
                        <img
                          src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
                          className="scrap-image"
                          alt="scrap"
                        />

                        <p>
                          {item.scrap.name}-{item.scrap.variant}
                        </p>
                      </span>
                    </td>
                    <td>{new Date(item.date).toDateString()}</td>
                    <td>{item.pickupLocation?.address}</td>
                    <td>INR {item.price}/Kg</td>
                    <td>{item.quantity}</td>

                    <td style={{ width: "20%" }}>
                      <div className={"flex full-width align-center gap-1 "}>
                        <Modal
                          id={"accept-pickup-modal-" + index}
                          trigger={
                            <button className="btn waves-effect waves-purple">
                              Accept
                            </button>
                          }
                          actions={[]}
                        >
                          <p className="bold ">Accept pickup</p>

                          <div className="">
                            <label className="black-text">
                              Select a drop location for the pickup
                            </label>
                            <div className="row  ">
                              <select
                                className="input-style full-width browser-default "
                                name="location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              >
                                <option value={""}>--select--</option>
                                {organization.locations?.map((location, i) => (
                                  <option value={location._id} key={i}>
                                    {location.nickname}-{location.address}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="flex gap-1 mt-1">
                            <button
                              className="btn waves-effect waves-purple"
                              onClick={(e) => {
                                const elem = document.getElementById(
                                  "accept-pickup-modal-" + index
                                );
                                const instance =
                                  window.M.Modal.getInstance(elem);
                                e.preventDefault();
                                if (!location) {
                                  return notify(
                                    "Please select a drop location"
                                  );
                                }
                                const loc = organization.locations.find(
                                  (loc) => loc._id === location
                                );
                                dispatch(
                                  acceptPickup(
                                    item._id,
                                    { dropLocation: loc },
                                    () => {
                                      notify("Pickup accepted successfully!");
                                      dispatch(getAvailablePickups());
                                      instance.close();
                                    }
                                  )
                                );
                              }}
                            >
                              {accepting ? (
                                <ClipLoader color="#fff" size={20} />
                              ) : (
                                "Accept Pickup"
                              )}
                            </button>
                            <button
                              className="btn-outline waves-effect waves-purple "
                              onClick={(e) => {
                                const elem = document.getElementById(
                                  "accept-pickup-modal-" + index
                                );
                                const instance =
                                  window.M.Modal.getInstance(elem);

                                e.preventDefault();
                                instance.close();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </Modal>

                        <Link
                          to="/"
                          className="purple-text ml-1"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(ignorePickup(item._id, contract?._id));
                          }}
                        >
                          Ignore
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <div className="mt-2">
        <DashboardPickupHistoryTable />
      </div>
    </div>
  );
};

export default PickupsReycler;
