import React from "react";
import { useNavigate } from "react-router-dom";

function ArrowBack() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="center hover " onClick={() => navigate(-1)}>
        <span class="material-symbols-outlined  ">arrow_back</span>
      </div>
    </div>
  );
}

export default ArrowBack;
