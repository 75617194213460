export const generalReducer = (
  state = {
    overviewLoading: false,
    overview: {},
    reportsLoading: false,
    reports: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_OVERVIEW":
      return { ...state, overviewLoading: true };

    case "GOT_OVERVIEW":
      return { ...state, overviewLoading: false, overview: action.payload };

    case "CANNOT_GET_OVERVIEW":
      return { ...state, overviewLoading: false };

    case "GETTING_REPORTS":
      return { ...state, reportsLoading: true };

    case "GOT_REPORTS":
      return { ...state, reportsLoading: false, reports: action.payload };

    case "CANNOT_GET_REPORTS":
      return { ...state, reportsLoading: false };

    default:
      return state;
  }
};
