import React from "react";
import { useSelector } from "react-redux";
import EditUserInfo from "./EditUserInfo";
import { IWMURL } from "../../../redux/config/urlConfig";
import EditOrganization from "./EditOrganization";

function AccountInfo() {
  const { account } = useSelector((state) => state.account);
  const { organization } = useSelector((state) => state.organization);

  return (
    <section className=" p-2  ">
      <div className="container mb-2">
        <div className="flex space-between  align-center">
          <p className="bold">Your Account Information</p>
          <EditUserInfo />
        </div>

        <div className="">
          <div>
            <div className="flex s_b mb-1">
              <p>Your Name:</p>
              <p className="gray semi-bold capitalize ml-1">{account.name}</p>
            </div>

            <div className="flex s_b mb-1 ">
              <p>Mobile Number:</p>
              <p className="gray semi-bold ml-1">+{account.phoneNumber}</p>
            </div>

            <div className="flex s_b mb-1">
              <p>Email: </p>
              <p className="gray semi-bold ml-1">{account.email || "N/A"}</p>
            </div>

            <div className="flex s_b mb-1">
              <p>Role: </p>
              <p className="gray semi-bold ml-1">
                {account.roleDescription || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-2">
        <div className="flex space-between  align-center">
          <p className="bold">Your Organization</p>
          <EditOrganization />
        </div>

        <div className="">
          <div className="flex s_b mb-1">
            <p>Organization Name: </p>
            <p className="gray semi-bold ml-1">{organization.name || "N/A"}</p>
          </div>

          <div className="flex s_b mb-1">
            <p>Address: </p>
            <p className="gray semi-bold ml-1">
              {organization.address || "N/A"}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AccountInfo;
