import React from "react";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";

function ChangeMobileNumber() {
  const { account } = useSelector((state) => state.account);

  return (
    <section className="white p-2">
      <div className="container">
        <p className="bold ">Change Mobile Number</p>
        <p className="small-text grey-text">
          Change Your Current Account Mobile Number To Another One
        </p>

        <div className="mt-2">
          <div className="mb-1">
            <p className="capitalize">{account.name}</p>
            <p className="cercle-purple-text bold">+{account.phoneNumber}</p>
          </div>

          <a
            href={
              "https://accounts.thinktrash.co/change-mobile-number/" +
              account.phoneNumber
            }
            className="btn-small"
            target="_blank"
            rel="noreferrer"
          >
            Change Mobile Number
          </a>
        </div>
      </div>
    </section>
  );
}

export default ChangeMobileNumber;
