import React from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import {
  getInventory,
  getRecycledBatches,
  markAsRecycled,
} from "../../../redux/action/pickups";
import { getReports } from "../../../redux/action/analytics";
import { notifyError } from "../../../helpers/helper";

const MarkAsRecycledModal = ({
  selected,
  setSelected,
  inventory,
  contaminationLevel,
  quantity,
  setQuantity,
  setContaminationLevel,
  creatingRecycledBatch,
  totalQuantity,
}) => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = React.useState([
    {
      name: "",
      file: "",
    },
  ]);

  function submit(e) {
    e.preventDefault();

    if (contaminationLevel === "")
      return notifyError("Please enter the contamination level");

    if (quantity === "")
      return notifyError("Please enter the quantity of recycled material");

    if (parseInt(quantity) > totalQuantity)
      return notifyError(
        "Quantity of recycled material cannot be greater than the total quantity selected"
      );

    const formData = new FormData();
    formData.append("quantity", quantity);
    formData.append("contaminationLevel", contaminationLevel);
    formData.append("pickups", JSON.stringify(selected));
    documents.forEach((doc) => {
      formData.append(doc.name, doc.file);
    });

    dispatch(
      markAsRecycled(formData, () => {
        dispatch(getRecycledBatches());
        dispatch(getInventory());
        dispatch(getReports());
        setSelected([]);
        window.M.Modal.getInstance(
          document.getElementById("mark-as-recycled")
        ).close();
      })
    );
  }

  return (
    <form className="flex column " onSubmit={submit}>
      <div className="mb-1">
        <p className="bold ">Mark the selected inventory as recycled batch</p>
        <p>You are about to mark the selected inventory as a recycled batch</p>
      </div>

      <div className="mb-">
        <label htmlFor="">Material</label>
        <p>
          {inventory.find((item) => item._id === selected[0])?.scrap?.name}-{" "}
          {inventory.find((item) => item._id === selected[0])?.scrap?.variant}
        </p>
      </div>

      <div>
        <label htmlFor="">Total Quantity Selected</label>
        <p>
          {selected.reduce((acc, curr) => {
            const item = inventory.find((item) => item._id === curr);
            return acc + item.quantity;
          }, 0) + " Tonnes"}
        </p>
      </div>

      <div className="mt-1">
        <label>
          Enter Quantity Recycled (In Tonnes)
          <span className="red-text"> * </span>
        </label>

        <input
          className="browser-default input-style "
          type="number"
          min="0"
          placeholder="Eg. 2 Tonnes"
          name="quantity"
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
          value={quantity}
          required
        />
      </div>

      <div className="mt-1">
        <label>
          Enter Quantity of Contaminated Material (In Kgs)
          <span className="red-text"> * </span>
        </label>

        <input
          className="browser-default input-style "
          type="number"
          min="0"
          placeholder="Eg. 20 Kgs"
          name="quantity"
          onChange={(e) =>
            setContaminationLevel(e.target.value.replace(/[^0-9]/g, ""))
          }
          value={contaminationLevel === 0 ? "" : contaminationLevel}
          required
        />
      </div>

      <div className="mt-1">
        <label>Upload Certificates And Other Documents</label>

        {documents.map((doc, i) => {
          return (
            <div className="flex gap-1 align-center " key={i}>
              <input
                className="browser-default input-style "
                type="text"
                placeholder="File Name. Eg.recycling certificate"
                name="quantity"
                onChange={(e) => {
                  setDocuments(
                    documents.map((doc, index) => {
                      if (index === i) {
                        return {
                          ...doc,
                          name: e.target.value,
                        };
                      }
                      return doc;
                    })
                  );
                }}
                value={doc.name}
                required
                style={{ width: "50%" }}
              />

              <input
                type="file"
                name="file"
                id="file"
                accept="application/pdf"
                onChange={(e) => {
                  const files = e.target.files;
                  const file = files[0];

                  setDocuments(
                    documents.map((doc, index) => {
                      if (index === i) {
                        return {
                          ...doc,
                          file: file,
                        };
                      }
                      return doc;
                    })
                  );
                }}
              />

              <i
                className="material-symbols-outlined pointer"
                role="button"
                onClick={() => {
                  setDocuments(documents.filter((doc, index) => index !== i));
                }}
              >
                remove_circle
              </i>
            </div>
          );
        })}

        <div className="flex justify-end mt-1">
          <button
            className="btn-small  blue"
            onClick={() => {
              setDocuments([...documents, { name: "", file: "" }]);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="flex gap-1 mt-1">
        <button className="btn-small cercle-purple" type="submit">
          {creatingRecycledBatch ? (
            <ClipLoader color="#fff" size={20} />
          ) : (
            "Yes, Mark as Recycled Batch"
          )}
        </button>
        <button
          className="btn-small white cercle-purple-text"
          onClick={() => {
            window.M.Modal.getInstance(
              document.getElementById("mark-as-recycled")
            ).close();
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default MarkAsRecycledModal;
