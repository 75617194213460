import React from "react";
import Chips from "./Chips";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../helpers/helper";
import { updateOrganizationMember } from "../../../redux/action/organization";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const Step2 = () => {
  const { account } = useSelector((state) => state.account);
  const { organization, loading } = useSelector((state) => state.organization);
  const [role, setRole] = React.useState("");
  const [customRole, setCustomRole] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function submit(e) {
    e.preventDefault();
    if (role === "other") {
      if (!customRole) return notify("Please enter your role");
      dispatch(
        updateOrganizationMember(
          {
            roleDescription: customRole,
          },
          () => {
            navigate("/step3");
          }
        )
      );
    }

    dispatch(
      updateOrganizationMember(
        {
          roleDescription: role,
        },
        () => {
          navigate("/step3");
        }
      )
    );
  }

  return (
    <form
      className="container  first-time-container flex column align-center space-between "
      onSubmit={submit}
    >
      <div className="flex column align-center">
        <Chips active={2} />
        <p className="bold mt-2 mb-2">Step 2 out of 5 </p>

        <h3>What is your role in {organization.name}?</h3>
        <p className="grey-text" style={{ fontSize: "20px" }}>
          Hi 👋 {account?.name}, choose your current role in your company.
        </p>
      </div>

      <div className="dialogue-box">
        <p className="small-text">Select your role</p>

        <select
          onChange={(e) => {
            setRole(e.target.value);
          }}
          value={role}
          className="browser-default"
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          <option value="">-select-</option>
          <option value="sustainability-head">Sustainability Head</option>
          <option value="site-manager">Site Manager</option>
          <option value="other">other</option>
        </select>

        {role === "other" && (
          <div className="mt-1 full-width">
            <p className="small-text">Enter your role here</p>
            <input
              type="text"
              placeholder="Eg. Sustainability Head"
              className="browser-default"
              value={customRole}
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
              }}
              onChange={(e) => {
                setCustomRole(e.target.value);
              }}
            />
          </div>
        )}
      </div>

      <button
        className="btn  mb-1 mt-1 first-time-button green"
        type="submit"
        disabled={!role || loading}
      >
        {loading ? <ClipLoader size={20} color="#fff" /> : "Next"}
      </button>
    </form>
  );
};

export default Step2;
