export const scrapsReducer = (
  state = {
    scraps: [],
    loadingScraps: false,
    scrap: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_ALL_SCRAPS":
      return { ...state, loadingScraps: true };

    case "GOT_ALL_SCRAPS":
      return { ...state, scraps: action.payload, loadingScraps: false };

    case "CANNOT_GET_ALL_SCRAPS":
      return { ...state, loadingScraps: false };

    case "GETTING_SCRAP":
      return { ...state, loadingScrap: true };

    case "GOT_SCRAP":
      return { ...state, scrap: action.payload, loadingScrap: false };

    case "CANNOT_GET_SCRAP":
      return { ...state, loadingScrap: false };

    default:
      return state;
  }
};
