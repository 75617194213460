import React from "react";
import Chips from "./Chips";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { isTabletOrMobile, notify, notifyError } from "../../../helpers/helper";
import {
  finishSetup,
  getInvitations,
  getNetworkInvitations,
  inviteNetwork,
  rescindInvitation,
} from "../../../redux/action/organization";
import { useEffect } from "react";

const PersonalNetwork = () => {
  const { loading, invitations, organization, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const [scrap, setScrap] = useState("");

  const [values, setValues] = useState({
    email: "",
    organizationName: "",
    role: "",
    phoneNumber: "",
    location: "",
  });

  const dispatch = useDispatch();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function addNetwork(e) {
    e.preventDefault();
    if (values.phoneNumber.length <= 10)
      return notifyError("Include country code in phone number");
    const data = {
      ...values,
      scraps: [scrap],
    };
    dispatch(
      inviteNetwork(data, () => {
        dispatch(getNetworkInvitations());
        notify("Invitation sent successfully");
        setValues({
          email: "",
          organizationName: "",
          role: "",
          phoneNumber: "",
          location: "",
        });
        setScrap("");
      })
    );
  }

  useEffect(() => {
    dispatch(getNetworkInvitations());
  }, [dispatch]);

  return (
    <>
      <div className="container  first-time-container flex column align-center space-between">
        <div className="flex column align-center">
          <Chips active={5} />
          <p className="bold mt-2 mb-2">Step 5 out of 5 </p>

          <h3>Start Adding Your Network</h3>
          <p className="grey-text" style={{ fontSize: "20px" }}>
            Fill in the details of your network of waste processors.
          </p>
        </div>

        <div
          className="dialogue-box"
          style={{ height: "70vh", marginBottom: 0, overflowY: "auto" }}
        >
          <form className="full-width" onSubmit={addNetwork}>
            <div
              className={
                isTabletOrMobile
                  ? "full-width flex gap-1 flex-wrap"
                  : "full-width flex gap-1 align-center"
              }
            >
              <div className="full-width">
                <div className="mt-1">
                  <label className="black-text">
                    Organization's Name <span className="red-text">*</span>{" "}
                  </label>
                  <div className="row">
                    <input
                      className="browser-default input-style "
                      type="text"
                      placeholder="Eg. ABC Recyclers "
                      name="organizationName"
                      onChange={changeHandler}
                      value={values.organizationName}
                      required
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="black-text">Enter Email (optional)</label>
                  <div className="row">
                    <input
                      className="browser-default input-style "
                      type="text"
                      placeholder="Eg. ABC Recyclers "
                      name="email"
                      onChange={changeHandler}
                      value={values.email}
                    />
                  </div>
                </div>

                <div className="mt-1">
                  <label className="black-text">
                    Enter phone Number <span className="red-text">*</span>
                  </label>
                  <div className="row">
                    <input
                      className="browser-default input-style "
                      type="text"
                      placeholder="Eg. +91 000 000 0000 "
                      name="phoneNumber"
                      onChange={changeHandler}
                      value={values.phoneNumber}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="full-width">
                <div className="mt-1">
                  <label className="black-text">
                    Select Role <span className="red-text">*</span>{" "}
                  </label>
                  <div className="row  ">
                    <select
                      className="input-style full-width browser-default "
                      name="role"
                      onChange={changeHandler}
                      value={values.role}
                      required
                    >
                      <option value={""}>--select--</option>
                      <option value={"trader/aggregator"}>
                        Trader or Aggregator
                      </option>
                      <option value={"recycler"}>Recycler</option>
                    </select>
                  </div>
                </div>

                <div className="mt-1">
                  <label className="black-text">
                    Associate a scrap to this network{" "}
                    <span className="red-text">*</span>{" "}
                  </label>
                  <div className="row  ">
                    <select
                      className="input-style full-width browser-default "
                      name="scrap"
                      onChange={(e) => setScrap(e.target.value)}
                      value={scrap}
                      required
                    >
                      <option value={""}>--select--</option>
                      {organization.scraps?.map((scrap, i) => (
                        <option value={scrap._id} key={i}>
                          {scrap.name}-{scrap.variant}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mt-1">
                  <label className="black-text">
                    Associate a location to this network{" "}
                    <span className="red-text">*</span>{" "}
                  </label>
                  <div className="row  ">
                    <select
                      className="input-style full-width browser-default "
                      name="location"
                      onChange={changeHandler}
                      required
                    >
                      <option value={""}>--select--</option>
                      {organization.locations?.map((location, i) => (
                        <option value={location._id} key={i}>
                          {location.nickname}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className=" flex  gap-1 align-center mb-1 rounded"
              style={{ padding: "5px" }}
            >
              <span
                className="material-symbols-outlined grey-text
        cercle-purple-text"
              >
                info
              </span>
              <p className="cercle-purple-text small-text">
                An Invitation will be sent through Email and SMS to the provided
                email and phone number.
              </p>
            </div>

            <div className="flex justify-center">
              <button
                className="btn green"
                type="submit"
                disabled={
                  !values.organizationName ||
                  !values.phoneNumber ||
                  !values.role ||
                  !values.location ||
                  !scrap[0] ||
                  loadingAccounts
                }
              >
                {loading ? <ClipLoader size={20} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>

        <button
          className="btn mb-1 mt-1 first-time-but green"
          disabled={!invitations.length}
          onClick={() => {
            dispatch(
              finishSetup({}, () => {
                window.location.href = "/";
              })
            );
          }}
        >
          {loading ? <ClipLoader size={20} color="#fff" /> : "Finish"}
        </button>
      </div>

      <SideBar loading={loading} hideOnSmallDevices={true} />
    </>
  );
};

const SideBar = ({ loading, hideOnSmallDevices }) => {
  const { scraps } = useSelector((state) => state.scraps);
  const { organization, invitations, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const dispatch = useDispatch();

  return (
    <div
      className={`first-time-aside ${
        hideOnSmallDevices && "hide-on-med-and-down"
      }`}
    >
      <div className="flex align-center gap-1 ">
        <h3>Your Added Networks</h3>
        <div className="ml-1">
          <ClipLoader size={20} color="#6f2da8" loading={loadingAccounts} />
        </div>
      </div>

      <div className="first-time-aside-list-container full-width">
        {!invitations.length ? (
          <div
            className="flex justify-center column align-center  p-2 "
            style={{ height: "70%" }}
          >
            <i
              className="material-symbols-outlined grey-text"
              style={{ fontSize: 40 }}
            >
              info
            </i>
            <p className="grey-text small-text">No network found</p>
          </div>
        ) : (
          invitations.map((network, index) => (
            <div
              className="grey lighten-4 full-width p-1 flex column align-end"
              style={{ borderBottom: "5px solid #fff" }}
              key={index}
            >
              <div className="full-width flex space-between">
                <div className="flex ">
                  <img
                    src={`images/factory.png`}
                    alt="network"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />

                  <div>
                    <p>{network.organizationName}</p>
                    <p className="small-text">
                      {
                        scraps.find((scrap) => scrap._id === network.scraps[0])
                          ?.name
                      }
                      -
                      {
                        scraps.find((scrap) => scrap._id === network.scraps[0])
                          ?.variant
                      }
                    </p>
                    <p className="small-text">{network.location?.address}</p>
                    <p className="small-text flex">
                      {
                        organization.locations?.find(
                          (location) => location._id === network.location
                        )?.nickname
                      }
                    </p>
                  </div>
                </div>

                <button
                  className="btn-small white cercle-purple-text"
                  onClick={() => {
                    dispatch(
                      rescindInvitation(network._id, () => {
                        notify("Invitation Rescinded");
                        dispatch(getInvitations());
                      })
                    );
                  }}
                  disabled={loadingAccounts}
                >
                  Cancel
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PersonalNetwork;
