import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function NextMilestone() {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { overview } = useSelector((state) => state.analytics);
  const { organization } = useSelector((state) => state.organization);

  let { currentMilestone, totalQuantityChannelized, totalQuantityRecycled } =
    overview;

  if (!currentMilestone) currentMilestone = 0;
  if (!totalQuantityChannelized) totalQuantityChannelized = 0;
  if (!totalQuantityRecycled) totalQuantityRecycled = 0;

  const percentage =
    organization.type === "recycler"
      ? (totalQuantityRecycled / currentMilestone) * 100
      : (totalQuantityChannelized / currentMilestone) * 100;

  return (
    <div
      className={
        isTablet
          ? "mv-1 full-width white p-1 box "
          : "full-width  p-1 box white "
      }
    >
      <b className="bold">Your Current Milestone</b>
      <div className="recycled-waste">
        <p className="grey-text">
          Road To{" "}
          <span className="primary" style={{ fontSize: 22 }}>
            {currentMilestone}
          </span>{" "}
          Tonnes Of Properly Processed Waste
        </p>
        <div className="line1  ">
          {percentage < 45 && (
            <div className="current-milestone  ">
              {percentage?.toFixed(2) < 10 && <b>{percentage?.toFixed(2)}%</b>}
            </div>
          )}

          <div className="line2 ">
            <div
              className="progress-rate"
              style={{ width: percentage ? percentage + "%" : 0 }}
            >
              {percentage > 45 && ` ${percentage?.toFixed(2)}%`}
            </div>
            <img src="../images/car.svg" className="vector-img" alt="car" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NextMilestone;
