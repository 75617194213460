import React from "react";

function ComponentHeader({ title, link }) {
  return (
    <div className="component-haeder flex s_b ">
      <h4 >{title}</h4>
      {link && (
        <div className="view-all-btn">
          <p>View All</p>
        </div>
      )}
    </div>
  );
}

export default ComponentHeader;
