import React from "react";

const Chips = ({ active }) => {
  const count = [1, 2, 3, 4,5];
  return (
    <div style={styles.progressWrapper}>
      {count.map((item) => {
        return (
          <div
            key={item}
            style={
              item === active
                ? styles.progressChipActive
                : item < active
                ? styles.progressChipCompleted
                : styles.progressChip
            }
          ></div>
        );
      })}
    </div>
  );
};

export default Chips;

const styles = {
  progressWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 5,
  },

  progressChip: {
    backgroundColor: "#ccc",
    borderRadius: 5,
    padding: 3,
    width: "20%",
    marginVertical: 5,
  },
  progressChipActive: {
    backgroundColor: "limegreen",
    borderRadius: 5,
    padding: 3,
    width: "20%",
    marginVertical: 5,
  },
  progressChipCompleted: {
    backgroundColor: "#6f2da8",
    borderRadius: 5,
    padding: 3,
    width: "20%",
    marginVertical: 5,
  },
};
