import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import isEmpty from "lodash/isEmpty";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  acceptPickup,
  getSingleOrder,
  ignorePickup,
} from "../../../redux/action/pickups";
import { IWMURL } from "../../../redux/config/urlConfig";
import { Modal } from "react-materialize";
import { notify } from "../../../helpers/helper";

const SingleOrder = () => {
  const { order, orderLoading } = useSelector((state) => state.pickups);
  const id = useParams().id;
  const dispatch = useDispatch();
  const [location, setLocation] = React.useState("");
  const { organization } = useSelector((state) => state.organization);
  const { accepting } = useSelector((state) => state.pickups);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, [dispatch, id]);

  const totalQuantity = order.pickups?.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );

  return (
    <section className="p-1">
      <p className="bold">Order Details</p>
      <div className="container">
        <div className="">
          {orderLoading ? (
            <div className="flex justify-center full-width mt-2 mb-2">
              <ClipLoader color="#6f2da8" size={20} />
            </div>
          ) : isEmpty(order) ? (
            <div className="flex justify-center ">
              <div className="flex column align-center full-width  mv-2 p-2">
                <i
                  className="material-symbols-outlined grey-text"
                  style={{ fontSize: 80 }}
                >
                  inventory_2
                </i>
                <p className="grey-text mt-1 ">
                  This order does not exist or has been deleted.
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="box white order-box">
                <div className="">
                  <div className="flex align-center gap-1 space-between">
                    <div className="flex align-center gap-1">
                      <img
                        src={`${IWMURL}/scraps/read/image/${order.scrap._id}`}
                        className="scrap-image mr-1"
                        alt="scrap"
                      />

                      <div>
                        <p className="">
                          {order.scrap.name}-{order.scrap.variant}
                        </p>
                        <p className="small-text grey-text">
                          {new Date(order.createdAt).toDateString()}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex align-center space-between gap-1 mt-1">
                    <div className="flex column">
                      <label>Quantity</label>
                      <p style={{ marginTop: 0 }}>{order.quantity} Tonnes</p>
                    </div>
                    <div className="flex column ">
                      <label>Price</label>
                      <p style={{ marginTop: 0 }}>Rs. {order.price}/Kg</p>
                    </div>
                    <div className="flex column ">
                      <label>Pending Quantity</label>
                      <p style={{ marginTop: 0 }}>
                        {order.quantity - totalQuantity} Tonnes
                      </p>
                    </div>
                  </div>

                  <div class="progress-container">
                    <div
                      class="progress-bar"
                      id="myProgressBar"
                      style={{
                        width: (totalQuantity / order.quantity) * 100 + "%",
                      }}
                    >
                      {Math.round((totalQuantity / order.quantity) * 100)}%
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-1 mb-">
                <p>Pickups Associated with this order</p>

                <table className="striped  payment-history  ">
                  <thead>
                    <tr>
                      <th>Scrap</th>
                      <th>Date</th>
                      <th>Location</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.pickups?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="flex align-center gap-1 ">
                              <img
                                src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
                                className="scrap-image"
                                alt="scrap"
                              />

                              <p>
                                {item.scrap.name}-{item.scrap.variant}
                              </p>
                            </span>
                          </td>
                          <td>{new Date(item.date).toDateString()}</td>
                          <td>{item.pickupLocation?.address}</td>
                          <td>INR {item.price}/Kg</td>
                          <td>{item.quantity}</td>

                          {item.accepted ? (
                            <td>
                              <button
                                className="btn-small white cercle-purple-text"
                                onClick={() => navigate("/pickup/" + item._id)}
                              >
                                View Timeline
                              </button>
                            </td>
                          ) : (
                            <td>
                              <div
                                className={
                                  "flex full-width align-center gap-1 "
                                }
                              >
                                <Modal
                                  id={"accept-pickup-modal-" + index}
                                  trigger={
                                    <button className="btn waves-effect waves-purple">
                                      Accept
                                    </button>
                                  }
                                  actions={[]}
                                >
                                  <p className="bold ">Accept pickup</p>

                                  <div className="">
                                    <label className="black-text">
                                      Select a drop location for the pickup
                                    </label>
                                    <div className="row  ">
                                      <select
                                        className="input-style full-width browser-default "
                                        name="location"
                                        value={location}
                                        onChange={(e) =>
                                          setLocation(e.target.value)
                                        }
                                      >
                                        <option value={""}>--select--</option>
                                        {organization.locations?.map(
                                          (location, i) => (
                                            <option
                                              value={location._id}
                                              key={i}
                                            >
                                              {location.nickname}-
                                              {location.address}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="flex gap-1 mt-1">
                                    <button
                                      className="btn waves-effect waves-purple"
                                      onClick={(e) => {
                                        const elem = document.getElementById(
                                          "accept-pickup-modal-" + index
                                        );
                                        const instance =
                                          window.M.Modal.getInstance(elem);
                                        e.preventDefault();
                                        if (!location) {
                                          return notify(
                                            "Please select a drop location"
                                          );
                                        }
                                        const loc = organization.locations.find(
                                          (loc) => loc._id === location
                                        );
                                        dispatch(
                                          acceptPickup(
                                            item._id,
                                            { dropLocation: loc },
                                            () => {
                                              notify(
                                                "Pickup accepted successfully!"
                                              );
                                              dispatch(getSingleOrder(id));
                                              instance.close();
                                            }
                                          )
                                        );
                                      }}
                                    >
                                      {accepting ? (
                                        <ClipLoader color="#fff" size={20} />
                                      ) : (
                                        "Accept Pickup"
                                      )}
                                    </button>
                                    <button
                                      className="btn-outline waves-effect waves-purple "
                                      onClick={(e) => {
                                        const elem = document.getElementById(
                                          "accept-pickup-modal-" + index
                                        );
                                        const instance =
                                          window.M.Modal.getInstance(elem);

                                        e.preventDefault();
                                        instance.close();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </Modal>

                                <Link
                                  to="/"
                                  className="purple-text ml-1"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(ignorePickup(item._id));
                                  }}
                                >
                                  Ignore
                                </Link>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SingleOrder;
