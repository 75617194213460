module.exports.permissions = {
  base: [
    "read-organization",
    "read-account",
    "read-contracts",
    "read-vehicles",
    "read-pickups",
  ],
  admin: [
    "remove-location",
    "assign-location",
    "add-location",
    "remove-vehicle",
    "remove-network",
    "add-member",
    "add-scraps",
    "accept-invitation",
    "remove-member",
    "remove-scrap",
    "add-network",
    "update-network",
  ],

  industry: [
    "create-contract",
    "update-contract",
    "create-auction",
    "raise-pickup",
    "read-analytics",
  ],

  "trader/aggregator": [
    "accept-contract",
    "accept-pickups",
    "assign-pickups",
    "update-contract",
    "read-auctions",
    "add-vehicle",
    "add-scraps",
  ],
  recycler: [
    "create-contract",
    "update-contract",
    "create-auction",
    "raise-pickup",
    "accept-pickups",
    "assign-pickups",
    "read-analytics",
    "add-scraps",
  ],

  "partial-admin": [
    "remove-location",
    "add-location",
    "remove-vehicle",
    "remove-network",
    "accept-invitation",
    "remove-scrap",
  ],

  "partial-industry": ["update-contract", "raise-pickup", "read-analytics"],

  "partial-trader/aggregator": [
    "accept-contract",
    "accept-pickups",
    "assign-pickups",
    "update-contract",
    "read-auctions",
    "add-vehicle",
  ],

  "partial-recycler": [
    "accept-contract",
    "update-contract",
    "accept-pickups",
    "assign-pickups",
    "read-analytics",
    "add-vehicle",
  ],

  //complete-pickup is added when he completes profile
  //refer @routes/account/update/complete-profile
  driver: ["read-pickups", "cancel-pickup", "add-documents"],
};
