import React from "react";
import AddScrapContract from "../../pickups/components/AddScrapContract";
import { Modal } from "react-materialize";
import { ClipLoader } from "react-spinners";
import ScrapContracts from "../../pickups/components/ScrapContract";
import RecentTransactionTable from "../../pickups/components/RecentTransactionTable";
import { PickupHistoryTable } from "../../../modules";
import QuantityOverTime from "../../pickups/components/QuantityOverTime";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { getHistoryWithANetwork } from "../../../redux/action/pickups";
import { getNetwork } from "../../../redux/action/organization";
import { getContracts } from "../../../redux/action/contracts";
import { useParams } from "react-router-dom";

const SingleNetwork = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { network: myNetwork } = useSelector((state) => state.organization);
  const { networkHistory } = useSelector((state) => state.pickups);
  const { contracts, loading } = useSelector((state) => state.contracts);
  const [scrapContracts, setScrapContracts] = useState([]);
  const [network, setNetwork] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (isEmpty(myNetwork)) dispatch(getNetwork());
    if (isEmpty(contracts)) dispatch(getContracts());
  }, []);

  useEffect(() => {
    const network = myNetwork.find(
      (network) => network.organization?._id === id
    );
    if (network) setNetwork(network);
    setNetwork(network);
  }, [id, myNetwork]);

  useEffect(() => {
    setScrapContracts(
      contracts.filter(
        (contract) => contract?.processor?._id === network?.organization?._id
      )
    );
  }, [contracts, network]);

  useEffect(() => {
    if (isEmpty(network)) return;
    dispatch(getHistoryWithANetwork(network?.organization?._id));
  }, [dispatch, network]);

  if (isEmpty(network))
    return (
      <div className="flex align-center justify-center mt-2 mb-2">
        <p>Network not found </p>
      </div>
    );

  return (
    <div className="p-1">
      <h4 className="cercle-purple-text">{network.organization?.name}</h4>
      <div className="mt-1 p-1">
        <div className="flex space-between align-center mb-1 ">
          <p className="bold "> Scrap Contracts</p>

          <Modal
            id="add-new-scrap"
            trigger={<button className="btn-small"> Add Scrap Contract</button>}
            actions={[]}
          >
            <AddScrapContract processor={network?.organization?._id} />
          </Modal>
        </div>

        {loading ? (
          <div className="flex align-center justify-center mt-2 mb-2">
            <ClipLoader color="#6f2da8" loading={true} size={30} />
          </div>
        ) : !scrapContracts?.length ? (
          <div className="flex column align-center full-width  mv-2 p-2">
            <span
              className="material-symbols-outlined grey-text"
              style={{ fontSize: "2rem" }}
            >
              file_copy
            </span>
            <p className="grey-text mt-1 ">
              Currently you don’t have any scraps added.
            </p>
        </div>
        ) : (
          <ScrapContracts selectedNetwork={network} />
        )}
      </div>

      <div className={isTablet ? "column" : "flex gap-1  mt-1"}>
        <RecentTransactionTable data={networkHistory} />
      </div>

      <div className="mt-2">
        <PickupHistoryTable data={networkHistory} />
      </div>

      <div className="mt-2">
        <QuantityOverTime organization={network?.organization?._id} />
      </div>
    </div>
  );
};
export default SingleNetwork;
