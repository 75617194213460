import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tabs, Tab } from "react-materialize";
import {useMediaQuery} from 'react-responsive'

function SingleGroup({ name, location, number, description, id }) {
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });


  return (
    <div className="box single-group align-center p-1 white " style={{ width:!isMobile ?"31vw":"100%"  }} >
      <div className="flex space-between mb-1">
        <span class="material-symbols-outlined primary icon-bg icon ">
          groups
        </span>
        <Dropdown
          id="Dropdown_14"
          options={{
            alignment: "right",
          }}
          trigger={
            <a href="#!">
              <span class="material-symbols-outlined">more_vert</span>
            </a>
          }
        >
          <span style={{ color: "#ccc", background: "#ececec" }}>Edit</span>
        </Dropdown>
      </div>
      <p className="bold capitalize">{name}</p>
      <p className="grey-text">{description}</p>
      <div className="mv-2">
        <div className="flex">
          <span class="material-symbols-outlined purple-text   ">account_tree</span>
          <p className="semi-bold ml-1"> {number} members</p>
        </div>
        <div className="flex">
          <span class="material-symbols-outlined purple-text">location_on</span>
          <p className="semi-bold ml-1"> {location}</p>
        </div>
      </div>

      <div className="flex s_b ">
        <Link to={"/groups/" + id} className="purple-text ">
          View more
        </Link>

        <div className="add-ico">
          {/* <span class="material-symbols-outlined">add</span> */}
        </div>
      </div>
    </div>
  );
}

export default SingleGroup;
