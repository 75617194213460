import React from "react";
import { ComponentHeader } from "../../modules";
import { Tabs, Tab } from "react-materialize";
import Users from "./components/Accounts.js";
import Network from "../networks/Network";
import Locations from "./components/Locations";
import Scraps from "./components/Scraps";

function Organization() {
  return (
    <div className=" p-1">
      <div className="mb-1">
        <ComponentHeader title="Management" />
      </div>

      <Tabs>
        <Tab options={{ swipeable: false }} title="Accounts" idx="accounts">
          <Users />
        </Tab>
        <Tab options={{ swipeable: false }} title="Locations" idx="locations">
          <Locations />
        </Tab>

        <Tab options={{ swipeable: false }} title="Added Scraps" idx="scraps">
          <Scraps />
        </Tab>

        {/* <Tab
          options={{
            swipeable: false,
          }}
          title="Network"
        >
          <Network />
        </Tab> */}
      </Tabs>
    </div>
  );
}

export default Organization;
