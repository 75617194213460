import React, { useEffect, useState } from "react";
import { ComponentHeader } from "../modules";
import { IWMURL } from "../redux/config/urlConfig";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPickup } from "../redux/action/pickups";
import PickupTimeLine from "./PickupTimeLine";
import { useMediaQuery } from "react-responsive";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { ProgressBar } from "react-materialize";
import PickupTimeLineRecyclers from "./PickupTimeLineRecyclers";

const SinglePickup = () => {
  const { pickup, loading } = useSelector((state) => state.pickups);
  const { id } = useParams();
  const [showDetails, setShowDetails] = useState(true);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:740px)" });

  console.log(pickup.dropLocation);
  useEffect(() => {
    dispatch(getPickup(id));
  }, [dispatch, id]);

  if (loading) return <ProgressBar />;

  return (
    <div className="p-1">
      <div>
        <div className="mt-1">
          <ComponentHeader title="Pickup Details" />
        </div>

        <div className="flex mb-2">
          <SinglePickupMap pickup={pickup} />
        </div>

        <div className="mt-1 ml-2">
          <p
            className="chart-header"
            style={{ textTransform: "uppercase", marginBottom: "5px" }}
          >
            Scrap
          </p>
          <div
            className="box p-1 flex align-center gap-1 white space-between scrap-card"
            style={{ width: "fit-content" }}
          >
            <div className="flex">
              <img
                src={`${IWMURL}/scraps/read/image/${pickup.scrap?._id}`}
                style={{ width: "50px", height: "50px" }}
                alt="scrap"
              />

              <div className="ml-1">
                <p
                  className="cercle-purple-text"
                  style={{ textTransform: "uppercase" }}
                >
                  {pickup.scrap?.name} - {pickup.scrap?.variant}
                </p>
                <p className=" grey-text"> Rs.{pickup.price}/Kg</p>
              </div>
            </div>
          </div>
        </div>

        {pickup.assigned && (
          <>
            <p
              className="chart-header"
              style={{ textTransform: "uppercase", marginBottom: "5px" }}
            >
              Logistics Information
            </p>
            <div className="box white">
              {pickup?.drivers?.map((driver, index) => {
                return (
                  <div key={index}>
                    <div className="flex align-end space-between flex-wrap">
                      <div className="flex mt-1">
                        <img
                          src={`${IWMURL}/account/read/photo/${driver._id}`}
                          alt="driver"
                          style={{
                            width: "50px",
                            height: "50px"
                          }}
                        />

                        <div className="ml-1">
                          <p>{driver.name}</p>
                          <p>{driver.phoneNumber} </p>
                        </div>
                      </div>

                      <div className={"flex gap-1" + isMobile ? " mt-1 " : ""}>
                        <a
                          href={`${IWMURL}/organization/read/driver/doc/${driver._id}?type=aadhar`}
                          className="btn-outline-small"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Aadhar Card
                        </a>
                        <a
                          href={`${IWMURL}/organization/read/driver/doc/${driver._id}?type=drivingLicense`}
                          className="btn-outline-small"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Driving License
                        </a>
                      </div>
                    </div>

                    <div className="flex align-end space-between flex-wrap">
                      <div className="flex mt-1" key={index}>
                        <img
                          src={`${IWMURL}/vehicles/read/image/${pickup?.vehicles[index]?._id}`}
                          alt="driver"
                          style={{
                            width: "50px",
                            height: "50px"
                          }}
                        />

                        <div className="ml-1">
                          <p>{pickup?.vehicles[index]?.vehicleNumber}</p>
                          <p>{pickup?.vehicles[index]?.capacity} Tonnes</p>
                        </div>
                      </div>

                      <div className={"flex gap-1" + isMobile ? " mt-1 " : ""}>
                        <a
                          href={`${IWMURL}/vehicles/read/registrationCertificate/${pickup?.vehicles[index]?._id}`}
                          className="btn-outline-small"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Registration Certificate
                        </a>
                        <a
                          href={`${IWMURL}/vehicles/read/pollutionCertificate/${pickup?.vehicles[index]?._id}`}
                          className="btn-outline-small"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Pollution Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}

        <p
          className="chart-header mt-1"
          style={{ textTransform: "uppercase", marginBottom: "5px" }}
        >
          Locations
        </p>
        <div className="box white">
          <p
            className="chart-header"
            style={{ textTransform: "uppercase", marginBottom: "5px" }}
          >
            Pickup Location
          </p>

          <div className="flex gap-1 align-center mb-1">
            <span class="material-symbols-outlined cercle-purple-text">
              location_on
            </span>

            <div className="ml-1">
              <b>{pickup?.pickupLocation?.nickname}</b>
              <p>{pickup?.pickupLocation?.address}</p>
            </div>
          </div>

          <p
            className="chart-header"
            style={{ textTransform: "uppercase", marginBottom: "5px" }}
          >
            Drop Location
          </p>

          <div className="flex gap-1 align-center">
            <span class="material-symbols-outlined cercle-purple-text">
              location_on
            </span>

            <div className="ml-1">
              <b>{pickup?.dropLocation?.nickname}</b>
              <p>{pickup?.dropLocation?.address}</p>
            </div>
          </div>
        </div>
      </div>

      {pickup.completed && (
        <>
          <p
            className="chart-header mt-1"
            style={{ textTransform: "uppercase", marginBottom: "5px" }}
          >
            Sustainability Metrics
          </p>
          <div className=" box flex flex-wrap  p-1  gap-1 mt-1 white">
            <div
              className={
                isMobile
                  ? "full-width flex  p-1 "
                  : "mh-2 p-1 flex gap-1   p-1 "
              }
            >
              <img src="../images/carbon.png" alt="" />
              <div className="semi-bold ">
                <p className="bold-title">{pickup.co2_avoided}</p>
                <p className="gray">Carbon Avoided</p>
              </div>
            </div>
            <div
              className={
                isMobile ? "flex  p-1 full-width " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/carbon-footprint.png" alt="" />
              <div className="semi-bold">
                <p className="bold-title">{pickup?.carbon_footPrint}</p>
                <p className="gray">Carbon Footprint</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/energy-saving.png" alt="energy-saving" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.energy_saved}</p>
                <p className="gray">Energy Savings (KWH)</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/landfills.png" alt="landfills" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.landfills_saved}</p>
                <p className="gray">Landfills Saved</p>
              </div>
            </div>

            <div
              className={isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex  "}
            >
              <img src="../images/trees-saving.png" alt="trees-saving" />
              <div className="semi-bold ml-1">
                <p className="bold-title">{pickup.trees_saved}</p>
                <p className="gray">Trees Saved</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex ml-1  "
              }
            >
              <img src="../images/water-saved.png" alt="water-saved" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.water_saved}</p>
                <p className="gray">Water Saved (kL)</p>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-2">
        <p className="chart-header ">TIMELINE OF EVENTS</p>
        {pickup.path === "trader/aggregator-to-recycler" ? (
          <PickupTimeLineRecyclers
            pickup={pickup}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        ) : (
          <PickupTimeLine
            pickup={pickup}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        )}
      </div>
    </div>
  );
};

const SinglePickupMap = GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo"
})(({ pickup, google }) => {
  const mapRef = React.useRef(null);
  useEffect(() => {
    // Ensure that mapRef and pickup locations are available
    if (mapRef.current && pickup.pickupLocation && pickup.dropLocation) {
      // Create LatLngBounds object to include both pickup and drop locations
      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds to include pickup and drop locations
      bounds.extend(
        new window.google.maps.LatLng(
          pickup.pickupLocation.latitude + 0.2,
          pickup.pickupLocation.longitude + 0.2
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          pickup.dropLocation.latitude,
          pickup.dropLocation.longitude
        )
      );

      // Fit the map to the calculated bounds
      mapRef.current.map.fitBounds(bounds);
    }
  }, [pickup.pickupLocation, pickup.dropLocation]);

  return (
    <div className="single-pickup-map box">
      <Map
        google={google}
        initialCenter={{
          lat: pickup.pickupLocation?.latitude,
          lng: pickup.pickupLocation?.longitude
        }}
        center={{
          lat: pickup.pickupLocation?.latitude  ,
          lng: pickup.pickupLocation?.longitude 
        }}
        containerStyle={{
          position: "relative",
          width: "auto",
          height: 400,
          marginTop: "10px"
        }}
        zoom={14}
        ref={mapRef}
      >
        <Marker
          title={"Drop Location"}
          position={{
            lat: pickup.dropLocation?.latitude ,
            lng: pickup.dropLocation?.longitude 
          }}
        />
        <Marker
          title={"Drop Location"}
          position={{
            lat: pickup.pickupLocation?.latitude,
            lng: pickup.pickupLocation?.longitude
          }}
        />

        <Polyline
          path={[
            {
              lat: pickup.dropLocation?.latitude ,
              lng: pickup.dropLocation?.longitude
            },
            {
              lat: pickup.pickupLocation?.latitude,
              lng: pickup.pickupLocation?.longitude
            }
          ]}
          options={{
            strokeColor: "#FF0000", // Color of the polyline
            strokeOpacity: 0.8, // Opacity of the line
            strokeWeight: 2 // Thickness of the line
          }}
        />
      </Map>
    </div>
  );
});

export default SinglePickup;
