import React from "react";
import { useMediaQuery } from "react-responsive";

function InvCard({ title, amount, desc, img }) {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div
      className={
        "flex box p-1 white full-height align-center" + (isTablet ? "red" : "")
      }
    >
      <img src={img} className="dashboard-card-img" alt="bashboard-box" />
      <div className=" flex column ml-1">
        <p className="cercle-purple-text">{title}</p>
        <b className="bold-card-title" style={{ paddingTop: "5px" }}>
          {amount}
        </b>
        <p className="semi-bold gray "> {desc}</p>
      </div>
    </div>
  );
}

export default InvCard;
