import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
import { formatNumber } from "../../../helpers/helper";
function PDFReport({
  reportData,
  reportLoading,
  organizationName,
  address,
  endDate,
  startDate
}) {
  const Table = ({ children }) => <View style={styles.table}>{children}</View>;

  const TableCell = ({ children, style }) => (
    <View style={styles.tableCell}>
      <Text
        style={{
          color: "#263238",
          fontSize: 9.5,
          ...style
        }}
      >
        {children}
      </Text>
    </View>
  );

  const TableHeader = ({ children }) => (
    <View style={styles.headerRow}>{children}</View>
  );

  const HeaderText = ({ children, style }) => (
    <View style={styles.tableCell}>
      <Text
        style={{
          fontWeight: 700,
          color: "#7C8DB5",
          fontSize: 9.5,
          ...style
        }}
      >
        {children}
      </Text>
    </View>
  );

  const TableRow = ({ children }) => (
    <View style={styles.tableRow}>{children}</View>
  );

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff"
    },

    table: {
      display: "table",
      width: "100%",
      borderRadius: 5,
      padding: 2,
      marginVertical: 7
    },
    tableRow: {
      flexDirection: "row",
      height: 26,
      width: "100%",
      backgroundColor: "#fff",
      borderWidth: 0.8,
      borderColor: "#DADAE7",
      borderRadius: 4,
      gap: 1,
      marginVertical: 2
    },
    tableCell: {
      marginVertical: 5,
      width: "15%",
      marginHorizontal: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    headerRow: {
      backgroundColor: "#EAECF0",
      height: 33,
      width: "100%",
      flexDirection: "row",
      gap: 1,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      borderBottomWidth: 1,
      borderColor: "#DADAE7",
      marginVertical: 5
    },
    largeTitle: {
      fontSize: 50,
      fontWeight: 1600,
      textAlign: "center",
      color: "#fff"
    },
    firstPageHeader: {
      backgroundColor: "#6f2da8",
      height: "75%",
      width: "100%",
      justifyContent: "center"
    },

    titleText: {
      fontSize: 20,
      fontWeight: 900
    },

    normalText: {
      fontSize: 15,
      fontWeight: 400
    },
    firstPageBottom: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 20
    },
    pdfBody: {
      padding: 10,
      backgroundColor: "#fff"
    },
    analyticsTitle: {
      fontSize: 15,
      fontWeight: 800
    },
    analyticsItem: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "#DADAE7",
      width: "100%",
      paddingHorizontal: 25,
      paddingVertical: 10
    },
    analyticsValue: {
      color: "#6f2da8",
      fontWeight: 800,
      fontSize: 20
    },
    sustainablilityItem: {
      flexDirection: "row",
      gap: 10,
      alignItems: "center",
      justifyContent: "start",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "#DADAE7",
      display: "flex",
      padding: "10px 20px",
      width: "100%",
      margin: "2.5px 0"
    },
    sustainablilityValue: {
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      gap: 2
    },

    pageHeader: {
      backgroundColor: "#fbeeff",
      height: "35px",
      width: "100%",
      color: "#000",
      justifyContent: "center",
      fontSize: 800,
      display: "flex",
      paddingLeft: 10
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.firstPageHeader}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Image
              src={"../images/wasteknot_logo.png"}
              style={{
                width: 100,
                height: 100
              }}
            />
            <Text style={styles.largeTitle}>Wasteknot Report</Text>
            <Text
              style={{ ...styles.normalText, color: "#fff", marginTop: 10 }}
            >
              {new Date(startDate).toDateString()}-{" "}
              {new Date(endDate).toDateString()}
            </Text>
          </View>
        </View>

        <View style={styles.firstPageBottom}>
          <View></View>
          <View style={{ gap: 7 }}>
            <Text style={styles.titleText}>Specially designed for</Text>
            <Text style={styles.titleText}>{organizationName}</Text>
            <Text style={styles.normalText}>
              {address.nickname}: {address.address}
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.pdfBody}>
        <View style={{ ...styles.pageHeader }}>
          <Text style={{ ...styles.titleText, fontSize: 15 }}>
            Report Analytics
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            justifyContent: "space-between",
            marginVertical: 5
          }}
        >
          <View style={{ ...styles.analyticsItem }}>
            <Text style={{ ...styles.analyticsTitle }}>Total Pickups</Text>
            <Text style={{ ...styles.analyticsValue }}>
              {reportData.totalPickups}
            </Text>
          </View>

          <View style={{ ...styles.analyticsItem }}>
            <Text style={{ ...styles.analyticsTitle }}>Total Quantity</Text>
            <Text style={{ ...styles.analyticsValue }}>
              {reportData.totalQuantity}
            </Text>
          </View>
        </View>

        <View style={{ ...styles.pageHeader }}>
          <Text style={{ ...styles.titleText, fontSize: 15 }}>
            Pickups History:
          </Text>
        </View>

        <Table>
          <TableHeader>
            <HeaderText style={{ width: 10 }}>#</HeaderText>
            <HeaderText>Pickup Date</HeaderText>
            <HeaderText>Quantity</HeaderText>
          </TableHeader>

          {reportData.pickups?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: 10 }}>{index + 1}</TableCell>
              <TableCell>{new Date(row.date).toDateString()}</TableCell>
              <TableCell>{row.quantity} MT</TableCell>
            </TableRow>
          ))}
        </Table>
      </Page>

      <Page size="A4" style={styles.pdfBody}>
        <View style={{ ...styles.pageHeader }}>
          <Text style={{ ...styles.titleText, fontSize: 15 }}>
            Sustainability Metrics:
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/carbon.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>Carbon Avoided</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.co2_avoided)}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/energy-saving.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>
                Energy Savings (KWH)
              </Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.energy_saved)}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/carbon-footprint.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>Carbon Footprint</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.carbon_footPrint)}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/trees-saving.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>Trees Saved</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.trees_saved)}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            justifyContent: "space-between",
            marginTop: "10px"
          }}
        >
          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/cerclex.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>CERCLEX CREDITS</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.cercle_credits)}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.sustainablilityItem }}>
            <Image
              src={"../images/landfills.png"}
              style={{
                width: 40,
                height: 40
              }}
            />
            <View style={{ ...styles.sustainablilityValue }}>
              <Text style={{ ...styles.analyticsTitle }}>Landfills Saved</Text>
              <Text style={{ ...styles.analyticsValue }}>
                {formatNumber(reportData.landfills_saved)}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ ...styles.sustainablilityItem, margin: "5px 0" }}>
          <Image
            src={"../images/water-saved.png"}
            style={{
              width: 40,
              height: 40
            }}
          />
          <View style={{ ...styles.sustainablilityValue }}>
            <Text style={{ ...styles.analyticsTitle }}>Water Saved (kL)</Text>
            <Text style={{ ...styles.analyticsValue }}>
              {formatNumber(reportData.water_saved)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFReport;
