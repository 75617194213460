import { toast } from "react-toastify";
import { notify } from "../../helpers/helper";
import { IWM } from "../config/urlConfig";

export const getContracts = () => async (dispatch) => {
  dispatch({ type: "GETTING_CONTRACTS" });
  IWM.get("/contracts/read")
    .then((res) => {
      dispatch({ type: "GOT_CONTRACTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_CONTRACT" });
    });
};

export const addContract = (data, callback) => (dispatch) => {
  dispatch({ type: "ADDING_CONTRACT" });
  IWM.post("/contracts/create", data)
    .then((res) => {
      dispatch({ type: "ADDED_CONTRACT", payload: res.data });
      callback();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ADD_CONTRACT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getSummary = (contractId) => async (dispatch) => {
  dispatch({ type: "GETTING_CONTRACTS" });
  IWM.get("/contract/read/summary/" + contractId)
    .then((res) => {
      dispatch({ type: "GOT_SUMMARY", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_CONTRACT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const changePrice = (data, id, cb) => (dispatch) => {
  dispatch({ type: "UPDATING_CONTRACT" });
  IWM.put("/contracts/update/price-change-request/" + id, data)
    .then(() => {
      dispatch({ type: "UPDATED_CONTRACT" });
      cb();
      dispatch(getContracts());
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_UPDATE_CONTRACT" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const counterPrice = (data, id, cb) => (dispatch) => {
  dispatch({ type: "UPDATING_CONTRACT" });
  IWM.put("/contracts/update/price-change-counter/" + id, data)
    .then(() => {
      dispatch({ type: "UPDATED_CONTRACT" });
      dispatch(getContracts());
      cb();
      dispatch(getContracts());
    })
    .catch((ex) => {
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const acceptPrice = (contractId, cb) => (dispatch) => {
  dispatch({ type: "UPDATING_CONTRACT" });
  IWM.put(`/contracts/update/price-change-accept/${contractId}`)
    .then(() => {
      dispatch({ type: "UPDATED_CONTRACT" });
      dispatch(getContracts());
      cb();
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_UPDATE_CONTRACT" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};
