import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify, notifyError } from "../../../helpers/helper";
import { Button } from "react-materialize";
import {
  getNetworkInvitations,
  inviteNetwork
} from "../../../redux/action/organization";
import { ClipLoader } from "react-spinners";

const AddPersonalNetwork = () => {
  const { loading, organization, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const [scrap, setScrap] = useState("");

  const [values, setValues] = useState({
    email: "",
    organizationName: "",
    role: "",
    phoneNumber: "",
    location: ""
  });

  const dispatch = useDispatch();

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function addNetwork(e) {
    e.preventDefault();
    if (values.phoneNumber.length <= 10)
      return notifyError("Include country code in phone number");
    const data = {
      ...values,
      scraps: [scrap]
    };
    dispatch(
      inviteNetwork(data, () => {
        let element = document.getElementById("invitation-modal");
        let instance = window.M.Modal.getInstance(element);
        instance.close();
        dispatch(getNetworkInvitations());
        notify("Invitation sent successfully");
        setValues({
          email: "",
          organizationName: "",
          role: "",
          phoneNumber: "",
          location: ""
        });

        setScrap("");
      })
    );
  }

  return (
    <div className="">
      <p className="bold">Add A Personal Network</p>
      <form className="full-width" onSubmit={addNetwork}>
        <div className="full-width flex gap-1">
          <div className="full-width">
            <div className="mt-1">
              <label className="black-text">
                Organization's Name <span className="red-text">*</span>{" "}
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Eg. ABC Recyclers "
                  name="organizationName"
                  onChange={changeHandler}
                  value={values.organizationName}
                  required
                />
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text">
                Enter Email <span className="red-text">*</span>
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Eg. ABC Recyclers "
                  name="email"
                  onChange={changeHandler}
                  value={values.email}
                />
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text">
                Enter phone Number <span className="red-text">*</span>
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Eg. +91 000 000 0000 "
                  name="phoneNumber"
                  onChange={changeHandler}
                  value={values.phoneNumber}
                  required
                />
              </div>
            </div>
          </div>

          <div className="full-width">
            <div className="mt-1">
              <label className="black-text">
                Select Role <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <select
                  className="input-style full-width browser-default "
                  name="role"
                  onChange={changeHandler}
                  value={values.role}
                  required
                >
                  <option value={""}>--select--</option>
                  <option value={"trader/aggregator"}>
                    Trader or Aggregator
                  </option>
                  <option value={"recycler"}>Recycler</option>
                </select>
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text">
                Associate a scrap to this network{" "}
                <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <select
                  className="input-style full-width browser-default "
                  name="scrap"
                  onChange={(e) => setScrap(e.target.value)}
                  value={scrap}
                  required
                >
                  <option value={""}>--select--</option>
                  {organization.scraps?.map((scrap, i) => (
                    <option value={scrap._id} key={i}>
                      {scrap.name}-{scrap.variant}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text">
                Associate a location to this network{" "}
                <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <select
                  className="input-style full-width browser-default "
                  name="location"
                  onChange={changeHandler}
                  required
                >
                  <option value={""}>--select--</option>
                  {organization.locations?.map((location, i) => (
                    <option value={location._id} key={i}>
                      {location.nickname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" flex  gap-1 align-center mb-1 rounded"
          style={{ padding: "5px" }}
        >
          <span
            className="material-symbols-outlined grey-text
  cercle-purple-text"
          >
            info
          </span>
          <p className="cercle-purple-text small-text">
            An Invitation will be sent through Email and SMS to the provided
            email and phone number.
          </p>
        </div>

        <div className="flex justify-end gap-2 ">
          <Button className="btn-outline" modal="close" type="button" flat  node="button">
            Cancel
          </Button>
          <button
            className="btn green"
            type="submit"
            disabled={
              !values.organizationName ||
              !values.phoneNumber ||
              !values.role ||
              !values.location ||
              !scrap[0] ||
              loadingAccounts
            }
          >
            {loading ? <ClipLoader size={20} color="#fff" /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPersonalNetwork;
