import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify, notifyError } from "../../../helpers/helper";
import { createOrder, getOrders } from "../../../redux/action/pickups";
import { ClipLoader } from "react-spinners";

const CreateOrder = () => {
  const { organization } = useSelector((state) => state.organization);
  const { orders, orderLoading } = useSelector((state) => state.pickups);
  const [scrap, setScrap] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [price, setPrice] = React.useState("");
  const dispatch = useDispatch();

  function submit() {
    if (!scrap || !quantity || !price)
      return notifyError("Please fill all the fields");

    dispatch(
      createOrder({ scrap, quantity, price }, () => {
        dispatch(getOrders());
        notify("Order created successfully!");
        setScrap("");
        setQuantity("");
        setPrice("");
        window.M.Modal.getInstance(
          document.getElementById("create-order-modal")
        ).close();
      })
    );
  }

  return (
    <div>
      <p className="bold">Create An Order For Scrap</p>
      <p>
        Source scrap from your network. A notification will be sent to your
        network with your scrap requirements.
      </p>

      <div className="mt-1">
        <label className="black-text">
          Select The Scrap You Wish to Buy<span className="red-text">*</span>{" "}
        </label>
        <div className="row  ">
          <select
            className="input-style full-width browser-default "
            name="scrap"
            onChange={(e) => setScrap(e.target.value)}
            value={scrap}
            required
          >
            <option value={""}>--select--</option>
            {organization.scraps?.map((scrap, i) => (
              <option value={scrap._id} key={i}>
                {scrap.name}-{scrap.variant}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-1">
        <label className="black-text">
          Enter Quantity Required (In Tonnes){" "}
          <span className="red-text">*</span>
        </label>
        <div className="row">
          <input
            className="browser-default input-style "
            type="text"
            placeholder="Eg. 4 Tonnes"
            name="quantity"
            onChange={(e) => setQuantity(e.target.value.replace(/[^0-9]/g, ""))}
            value={quantity}
            required
          />
        </div>
      </div>

      <div className="mt-1">
        <label className="black-text">
          Enter Preferred Price
          <span className="red-text">*</span>
        </label>
        <div className="row">
          <input
            className="browser-default input-style "
            type="number"
            placeholder="4 INR/Kg"
            name="price"
            onChange={(e) => setPrice(e.target.value.replace(/[^0-9]/g, ""))}
            value={price}
            required
          />
        </div>
      </div>

      <div className="mt-1 flex gap-1">
        <button className="btn waves-effect waves-purple" onClick={submit}>
          {orderLoading ? (
            <ClipLoader color={"white"} size={20} />
          ) : (
            "Create Order"
          )}
        </button>
        <button
          className="btn white cercle-purple-text waves-effect waves-purple"
          onClick={() => {
            setScrap("");
            setQuantity("");
            setPrice("");
            window.M.Modal.getInstance(
              document.getElementById("create-order-modal")
            ).close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateOrder;
