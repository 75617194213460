import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IWMURL } from "../../../redux/config/urlConfig";
import { CompanyLogo } from "../../../modules";
import { Modal } from "react-materialize";
import UploadImage from "./UploadImage";
import Accountanalytics from "./Accountanalytics";

function AccountInfoHeader() {
  const { account } = useSelector((state) => state.account);
  const [src, setSrc] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const { organization } = useSelector((state) => state.organization);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setOpenCrop(true);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <div className="flex  p-2 box mb-1 space-between align-center white  ">
        <div className="flex align-center gap-1  ">
          <div className="account-img">
            <img
              style={{
                width: 100,
                height: 100,
                borderRadius: 50
              }}
              src={`${IWMURL}/account/read/photo/${account._id}`}
              alt="profile"
              crossOrigin="anonymous"
            />

            <div className="edit-btn ">
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: "none" }}
              />
              <label htmlFor="imageInput" className="upload-button">
                <span
                  class="material-symbols-outlined white-text upload-button"
                  htmlFor="imageInput"
                  style={{ fontSize: 18 }}
                >
                  edit
                </span>
              </label>
              <Modal id="crop-img" open={openCrop}>
                <UploadImage src={src} setOpenCrop={setOpenCrop} />
              </Modal>
            </div>
          </div>
          <div>
            <b>{account.name}</b>
            <p className="grey-text">{account.phoneNumber}</p>
            <p className="grey-text">{account.email}</p>
            {organization?.type === "recycler" && (
              <span className="flex align-center gap-1 verified  ">
                <span class="material-symbols-outlined ">
                  workspace_premium
                </span>
                <b>CPCB verified </b>
              </span>
            )}
          </div>
        </div>
        <div>
          <CompanyLogo
            style={{ width: "70px", height: "70px", objectFit: "cover" }}
          />
        </div>
      </div>
      {organization?.type === "recycler" && <Accountanalytics />}
    </div>
  );
}

export default AccountInfoHeader;
