import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { calculateInvoiceTotal, calculateTotal } from "../helpers/helper";
import { ComponentHeader } from "../modules";
import { useEffect } from "react";
import { useState } from "react";

const PaymentsHistory = () => {
  const { history } = useSelector((state) => state.pickups);
  const [completed, setCompleted] = useState([]);

  useEffect(() => {
    const completed = history.filter((item) => item.completed).slice(-4);
    setCompleted(completed);
  }, [history]);

  return (
    <section className="p-1">
      <ComponentHeader title="Payments History" />
      <div className="box  full-width white ">
        <table className="striped  full-width ">
          <thead>
            <tr>
              <th>Invoice Date</th>

              <th>Price</th>
              <th>Quantity</th>
              <th>GST</th>
              <th>Amount</th>
              <th>TCS</th>
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            {completed?.map((item, index) => {
              return (
                <tr key={index} className="tableHover">
                  <td>{new Date(item.date).toDateString()}</td>
                  <td>Rs. {item.price}/kg</td>
                  <td>{item.quantity}</td>
                  <td>5%</td>
                  <td>Rs. {calculateTotal(item.price, item.quantity)}/kg</td>
                  <td>Rs.{item.TCS}</td>
                  <td>
                    Rs.
                    {calculateInvoiceTotal(item.price, item.quantity, item.TCS)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {completed?.length === 0 && (
          <div className="flex column align-center full-width  mv-2 p-2">
            <img
              src="../images/pickup.png"
              style={{ width: "130px", height: "100px" }}
              alt="pickup"
            />
            <p className="grey-text mt-1 ">
              Currently you don’t have any pickups.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default PaymentsHistory;
