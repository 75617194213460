export const notificationsReducer = (
  state = {
    notifications: [],
    newNotifications: 0,
    loading: false,
    error: false,
    profile: {},
  },
  action
) => {
  switch (action.type) {
    case "GETTING_NOTIFICATIONS":
      return { ...state, loading: true };

    case "GOT_NOTIFICATIONS":
      return { ...state, loading: false, notifications: action.payload };

    case "CANNOT_GET_NOTIFICATIONS":
      return { ...state, loading: false, error: true };

    case "GETTING_NOTIFICATIONS_PROFILE":
      return { ...state, loading: true };

    case "GOT_NOTIFICATIONS_PROFILE":
      return { ...state, loading: false, profile: action.payload };

    case "CANNOT_GET_NOTIFICATIONS_PROFILE":
      return { ...state, loading: false, error: true };

    case "SEEN_NOTIFICATION":
      HandleSeenNotification(state, action);
      return { ...state };

    case "SEEN_NOTIFICATIONS":
      return { ...state, profile: { ...state.profile, new: false, count: 0 } };

    case "READ_NOTIFICATION":
      return action.payload;

    case "GOT_PROFILE":
      return {
        ...state,
        newNotifications: action.payload.newNotifications,
      };

    default:
      return state;
  }
};

const HandleSeenNotification = (state, action, dispatch) => {
  const index = state.notifications.findIndex(
    (notification) => notification._id === action.payload
  );
  if (index === -1) return state;
  state.notifications[index].read = true;
  dispatch({ type: "READ_NOTIFICATION", payload: state });
};


