import React from "react";
import InvCard from "../../inventory/components/InvCard";

function Accountanalytics() {
  return (
    <div className="mb-1 flex gap-1 ">
      <InvCard
        title=" Overall production capacity (MT)"
        amount={"20,000"}
        desc="Overall production capacity"
        img="../images/track.png"
      />
      <InvCard
        title="Volume processed (MT)"
        amount={"10,500"}
        desc="Volume processed "
        img="../images/track.png"
      />
      <InvCard
        title="Available Capacity (MT)"
        amount={"9,500"}
        desc="Available Capacity"
        img="../images/track.png"
      />
    </div>
  );
}

export default Accountanalytics;
