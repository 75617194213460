import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-materialize";
import { getGroups } from "../redux/action/organization";
import { useSelector } from "react-redux";
import { AddGroup, SingleGroup } from "../components/groups";
import { useMediaQuery } from "react-responsive";
function Groups() {
  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.organization);
  const isTable = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  return (
    <div>
      <div className="flex space-between align-center mt-1  ">
        <b className="card-title">Groups</b>

        <Modal
          actions={[
            <Button flat modal="close" node="button" waves="green">
              Close
            </Button>,
          ]}
          bottomSheet={true}
          fixedFooter={false}
          header="Create Group"
          id="Modal-10"
          open={false}
          trigger={
            <button node="button" className="btn" disabled>
              Add Group
            </button>
          }
        >
          <AddGroup />
        </Modal>
      </div>

      <div className={!isTable ? "group flex space-between box align-center " :" white p-1 box "}>
        <div>
          <b className="normal-size">Let's get started</b>
          <p className=" mv-1 mt-2 ">
            {" "}
            More hands and the exchange of experiences makes the work more
            professional and brilliance.
          </p>
        </div>
        <img
          src="../images/group-icon.svg"
          width={ isTable ? "90%" : 270 }
          alt="people chatting"
          className="mr-3"
        />
      </div>

      <div className="flex flex-wrap gap-1 mt-2">
        {groups.map((grp) => {
          return (
            <SingleGroup
              id={grp._id}
              name={grp.name}
              description={grp.description}
              location={grp.location}
              number={grp.accounts?.length}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Groups;
