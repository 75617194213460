export const authReducer = (
  state = {
    account: {},
    OTPDetails: "",
    network: [],
    loadingAccount: false,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_USER_INFO_SUCCESS":
      return { userInfo: action.payload };

    case "SET_GRANT":
      return { grant: action.payload };

    case "SENT_OTP":
      return { OTPDetails: action.payload };

    case "GETTING_ACCOUNT":
      return { ...state, loadingAccount: true };

    case "GOT_ACCOUNT":
      return { ...state, account: action.payload, loadingAccount: false };

    case "UPDATING_ACCOUNT":
      return { ...state, loadingAccount: true };

    case "UPDATED_ACCOUNT":
      return { ...state, loadingAccount: false };

    case "CANNOT_UPDATE_ACCOUNT":
      return { ...state, loadingAccount: false };

    case "CANNOT_GET_ACCOUNT":
      return { loadingAccount: false };

    case "LOGGING_IN":
      return { ...state, loading: true };

    case "LOGGED_IN":
      return { ...state, loading: false };

    case "CANNOT_LOGIN":
      return { ...state, loading: false };

    case "REQUESTING_OTP":
      return { ...state, loading: true };

    case "REQUESTED_OTP":
      return { ...state, loading: false };

    case "CANNOT_REQUEST_OTP":
      return { ...state, loading: false };

    case "CONFIRMING_OTP":
      return { ...state, loading: true };

    case "CONFIRMED_OTP":
      return { ...state, loading: false };

    case "CANNOT_CONFIRM_OTP":
      return { ...state, loading: false };

    default:
      return state;
  }
};

export const changeMobileNumberReducer = (
  state = { changeMobileNumber: {} },
  action
) => {
  switch (action.type) {
    case "UPDATE_PHONE_NUMBER":
      return { changeMobileNumber: action.payload };
    default:
      return state;
  }
};
