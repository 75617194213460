import React from "react";
import Gallery from "react-photo-gallery";

function PhotoGallery() {
  const photos = [
    {
      src: "../images/trace1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "../images/trace2.jpg",
      width: 3,
      height: 3
    },
    {
      src: "../images/trace3.jpg",
      width: 2,
      height: 2
    },
    {
      src: "../images/trace4.jpg",
      width: 2,
      height: 2
    }
  ];

  const CustomImageComponent = ({ index, onClick, photo, margin }) => (
    <img
      key={index}
      style={{
        width: photo.width,
        height: photo.height,
        margin: `${margin}px`
      }}
      {...photo}
    />
  );

  return (
    <div>
      <Gallery photos={photos} ImageComponent={CustomImageComponent} />
    </div>
  );
}

export default PhotoGallery;
