import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { Tooltip } from "react-tooltip";

function SingleAggregatorWithTracks({
  data,
  name,
  colors,
  virtualBinsCode,
  wasteType,
  updateFilter,
  setSingleTrack
}) {
  const [uniqueKey, setUniqueKey] = useState(Math.random());

  const handleLotData = (index) => {
    if (index >= 0 && index < series[0].data.length) {
      const valueToMove = series[0].data[index];
      setSingleTrack({
        traderName: name,
        quantity: valueToMove.x,
        contaminationLevel: 0,
        formOfInput: "",
        wasteType: wasteType,
        virtualBinsCode: virtualBinsCode,
        id: valueToMove.id,
        pickupDate: valueToMove.pickupDate,
        receivedDate: valueToMove.receivedDate,
        index: index
      });
    }
  };
  const [series, setSeries] = useState([
    {
      data: data
    }
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: { show: false },
      type: "treemap",
      width: 200,
      height: 200,
      events: {
        click: (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) => {
          handleLotData(dataPointIndex);
        }
      }
    },
    title: {
      text: "",
      align: "left",
      enabled: false
    },

    colors: colors
  });

  useEffect(() => {
    setUniqueKey(Math.random());
  }, [updateFilter]);

  return (
    <div className="white  box" style={{ width: "49.5%" }}>
      <div
        className="flex    align-center space-between "
        data-tooltip-id={name}
      >
        <span>
          <p style={{ fontWeight: "900" }}>#{name}</p>
          <span className="flex align-center  ">
            <img
              src="../images/Plastic-PP.png"
              style={{ width: "20px", height: "20px" }}
            />
            <p className="small-text ">{wasteType}</p>
          </span>
        </span>
        <span
          style={{
            fontSize: "11px",
            fontWeight: "700",
            backgroundColor: colors[0],
            padding: "0 5px",
            borderRadius: "2px",
            color: "#fff"
          }}
          className="flex align-center justify-center gap-1 "
        >
          <span className=" small-text material-symbols-outlined">code</span>
          <b> {virtualBinsCode}</b>
        </span>

        <Tooltip id={name} style={{ backgroundColor: "#111" }}>
          <div>
            <h5>{name}</h5>
            <span className="flex gap-1 align-center ">
              <p>Total Quantity:</p> <p>12MT</p>
            </span>
            <span className="flex gap-1 align-center ">
              <p>Pending Tracks:</p> <p>120MT</p>{" "}
            </span>
          </div>
        </Tooltip>
      </div>
      <ApexCharts
        key={data.length}
        options={chartOptions}
        series={[{ data }]}
        type="treemap"
      />
    </div>
  );
}

export default SingleAggregatorWithTracks;
