import React, { useEffect } from "react";
import { IWMURL } from "../../../redux/config/urlConfig";
import { useNavigate } from "react-router-dom";

function OrderBox({ order }) {
  const navigate = useNavigate();

  const totalQuantity = order.pickups.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );
  return (
    <div className="box white order-box">
      <div className="">
        <div className="flex align-center gap-1 space-between">
          <div className="flex align-center gap-1">
            <img
              src={`${IWMURL}/scraps/read/image/${order.scrap._id}`}
              className="scrap-image mr-1"
              alt="scrap"
            />

            <div>
              <p className="">
                {order.scrap.name}-{order.scrap.variant}
              </p>
              <p className="small-text grey-text">
                {new Date(order.createdAt).toDateString()}
              </p>
            </div>
          </div>
        </div>

        <div className="flex align-center space-between gap-1 mt-1">
          <div className="flex column">
            <label>Quantity</label>
            <p style={{ marginTop: 0 }}>{order.quantity} Tonnes</p>
          </div>
          <div className="flex column ">
            <label>Price</label>
            <p style={{ marginTop: 0 }}>Rs. {order.price}/Kg</p>
          </div>
          <div className="flex column ">
            <label>Pending Quantity</label>
            <p style={{ marginTop: 0 }}>
              {order.quantity - totalQuantity} Tonnes
            </p>
          </div>
        </div>

        {/* <div class="progress-container">
          <div
            class="progress-bar"
            id="myProgressBar"
            style={{ width: (totalQuantity / order.quantity) * 100 + "%" }}
          >
            {Math.round((totalQuantity / order.quantity) * 100)}%
          </div>
        </div> */}

        {/* <p className="small-text mt-1 grey-text">
          Pickups Raised for this order
        </p>
        <table className="striped  payment-history">
          <thead>
            <tr>
              <th>Date</th>
              <th>Location</th>
              <th>Price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {!order.pickups.length ? (
              <tr>
                <td colSpan="6" className="center">
                  No Pickups Yet
                </td>
              </tr>
            ) : (
              order.pickups?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{new Date(item.date).toDateString()}</td>
                    <td>{item.pickupLocation?.address}</td>
                    <td>INR {item.price}/Kg</td>
                    <td>{item.quantity}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table> */}

        <button
          className="btn-small full-width white cercle-purple-text mt-1"
          onClick={() => navigate("/order/" + order._id)}
        >
          {" "}
          View Order
        </button>
      </div>
    </div>
  );
}

export default OrderBox;
