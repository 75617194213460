import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "react-apexcharts";
import SingleAggregator from "../components/SingleAggregator";
import aggregators from "../children/aggregators.json";
import { RadioGroup } from "react-materialize";
import SingleAggregatorWithTracks from "./SingleAggregatorWithTracks";
import InvCard from "../components/InvCard";
import { Modal } from "react-materialize";
import { Link } from "react-router-dom";
import PhotoGallery from "./PhotoGallery";
import AddTrack from "./AddTrack";

function MaterialInward() {
  const [lotUniqueKey, setLotUniqueKey] = useState(Math.random());
  const [analyticsKey, setAnalticsKey] = useState(Math.random());
  const [testReport, setTestReport] = useState(false);
  const [contentOverview, setContentOverview] = useState("");
  const [data, setData] = useState(aggregators);
  const [inputWatse, setInputWaste] = useState("");
  const [singleTrack, setSingleTrack] = useState({
    traderName: "",
    quantity: 0,
    contaminationLevel: 0,
    formOfInput: "",
    wasteType: "",
    virtualBinsCode: "",
    pickupDate: "",
    receivedDate: "",
    id: "",
    index: ""
  });

  function clearSingleTrack() {
    setSingleTrack({
      traderName: "",
      quantity: 0,
      contaminationLevel: 0,
      formOfInput: "",
      wasteType: "",
      virtualBinsCode: "",
      pickupDate: "",
      receivedDate: "",
      id: "",
      index: ""
    });
  }

  const [wasteType, setWaistType] = useState("Plastic-LLDPE");
  const [lotData, setLotData] = useState([
    {
      data: []
    }
  ]);

  const filteredAggregators = () => {
    setAnalticsKey(Math.random());
  };

  const handleContentOverviewChange = (e) => {
    setContentOverview(e.target.value);
  };

  const handleInputWasteChange = (e) => {
    setInputWaste(e.target.value);
  };

  const acceptPickup = () => {
    const trader = singleTrack.traderName;
    const pickupIndex = singleTrack.index;
    console.log(trader, pickupIndex);
    const newData = [...data];
    const traderIndex = newData.findIndex((item) => item.name == trader);
    if (traderIndex !== -1) {
      const newPickups = newData[traderIndex].pickups.filter(
        (item, index) => index !== pickupIndex
      );
      newData[traderIndex].pickups = newPickups;
      setData(newData);
      setSingleTrack({
        traderName: "",
        quantity: 0,
        contaminationLevel: 0,
        formOfInput: "",
        wasteType: "",
        virtualBinsCode: "",
        pickupDate: "",
        receivedDate: "",
        id: "",
        index: ""
      });
    }
  };

  const aggregatorName = [];
  const [quantityAnalysis, setQuantityAnalysis] = useState(aggregatorName);

  const updateData = (newQuantity, aggregatorName) => {
    const itemIndex = quantityAnalysis.findIndex(
      (item) => item.name == aggregatorName
    );
    if (itemIndex !== -1) {
      const newData = [...quantityAnalysis];
      newData[itemIndex].quantity += newQuantity;

      const totalQuantity = newData.reduce(
        (total, item) => total + item.quantity,
        0
      );

      newData.forEach((item) => {
        item.data[0] = ((item.quantity / totalQuantity) * 100).toFixed(2);
      });

      setQuantityAnalysis(newData);
      setAnalticsKey(Math.random());
    }
  };

  return (
    <div>
      <span className="row full-width flex space-between"></span>

      <div className="flex mb-1 mt-1 gap-1">
        <InvCard
          title="TOTAL QUANTITY AVALIABLE "
          amount={"314 Tonnes"}
          desc="Received So Far"
          img="../images/landfills.png"
        />
        <InvCard
          title="TOTAL TRACKS PENDING "
          amount={"34 Tracks"}
          desc="Received So Far"
          img="../images/track.png"
        />
      </div>

      <div className="row gap-2 flex ">
        <div className="col  s7">
          <div className="flex space-between align-center mb-1 ">
            <b>All Tracks</b>
            <select
              className="icons browser-default "
              style={{ width: "15vw" }}
              onChange={(e) => {
                setWaistType(e.target.value);
                filteredAggregators();
              }}
            >
              <option value="" disabled selected>
                Choose your option
              </option>
              <option value="Plastic-LDPE" class="left">
                Plastic-LDPE
              </option>
              <option value="Plastic-PP" class="left">
                Plastic-PP
              </option>
              <option value="Plastic-LLDPE" class="left">
                Plastic-LLDPE
              </option>
            </select>
          </div>
          <div className="flex-wrap align-center align-center gap-1 flex ">
            {data.map((item, index) => {
              return (
                <SingleAggregatorWithTracks
                  data={item.pickups}
                  name={item.name}
                  colors={item.colors}
                  setLotData={setLotData}
                  lotData={lotData}
                  key={index}
                  setLotUniqueKey={setLotUniqueKey}
                  updateData={updateData}
                  virtualBinsCode={item.virtualBinsCode}
                  wasteType={wasteType}
                  updateFilter={analyticsKey}
                  setSingleTrack={setSingleTrack}
                />
              );
            })}
          </div>
        </div>
        <div className="col  white box s5" style={{ padding: "10px" }}>
          <span className=" flex full-width space-between align-center mb-1">
            <b>Single Track</b>
            <Modal
              id="Modal-10"
              open={false}
              trigger={
                <button className="btn gap-1 cercle-btn">
                  <span class="material-symbols-outlined">new_label</span>
                  <b>Input Truck</b>
                </button>
              }
            >
              <AddTrack />
            </Modal>
          </span>

          {singleTrack.traderName !== "" ? (
            <>
              {" "}
              <div className="flex align-center space-between  mb-1">
                <div>
                  <b className="bold">#{singleTrack.traderName}</b>

                  <span className="flex gap-1 ">
                    <p>{singleTrack.wasteType}</p>
                    <span
                      style={{
                        fontSize: "11px",
                        fontWeight: "700",
                        backgroundColor: "green",
                        padding: "0 5px",
                        borderRadius: "2px",
                        color: "#fff"
                      }}
                      className="flex align-center justify-center gap-1 "
                    >
                      <span className=" small-text material-symbols-outlined">
                        code
                      </span>
                      <b>{singleTrack.virtualBinsCode}</b>
                    </span>
                  </span>
                </div>
                <div className=" flex align-center  gap-2 ">
                  <div>
                    <div className="flex align-center ">
                      <span class="material-symbols-outlined  cercle-purple-text ">
                        calendar_month
                      </span>
                      <b className="cercle-purple-text">Pickup Date</b>
                    </div>
                    <p>{singleTrack.pickupDate}</p>
                  </div>

                  <div>
                    <div className="flex align-center   ">
                      <span class="material-symbols-outlined  cercle-purple-text ">
                        calendar_month
                      </span>
                      <b className="cercle-purple-text">Received Date</b>
                    </div>
                    <p>{singleTrack.receivedDate}</p>
                  </div>
                </div>
              </div>
              <hr color="#efefef" />
              <div className="flex align-center space-between mt-2">
                <div>
                  <span className="flex cercle-purple-text align-center gap-1   ">
                    <b className="bold title  ">Quantity:</b>
                  </span>

                  <div>
                    <b>{singleTrack.quantity}</b>
                  </div>

                  <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                    <b className="bold title  ">Form of input watse:</b>
                  </span>

                  <div key={5}>
                    <RadioGroup
                      label="000"
                      name="input-watse"
                      options={[
                        {
                          label: "Grinded Plastic Waste",
                          value: "Grinded-Plastic-Waste"
                        },
                        {
                          label: "Plastic Waste Bale",
                          value: "Plastic-Waste-Bale",
                        },
                      ]}
                      value=""
                      withGap
                    />
                  </div>

                  <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                    <b className="bold title  ">Recycled Content Overview:</b>
                  </span>

                  <div key={15}>
                    <RadioGroup
                      label="1121"
                      name="content-overview"
                      options={[
                        {
                          label: "Post Consumer",
                          value: "post-consumer"
                        },
                        {
                          label: "Post Industrial",
                          value: "Post-Industrial"
                        }
                      ]}
                      value={contentOverview}
                      withGap
                      onChange={handleContentOverviewChange}
                      key={10}
                    />
                  </div>

                  <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                    <b className="bold title  ">Contamination Level :</b>
                  </span>

                  <div className=" mb-1 ">
                    <input
                      className="browser-default search-input "
                      type="text"
                      placeholder="enter contamination level "
                    />
                  </div>
                </div>

                <div className="flex column ">
                  <b> ID: #{singleTrack.id}</b>
                  <img
                    src="../images/track-qr.png"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <a
                    href="https://trace.thinktrash.co/product-timeline/653b59c3d1853ba55ec9cd37"
                    className="btn-outline cercle-btn  "
                    target="_blank"
                  >
                    VIEW
                  </a>
                </div>
              </div>
              <hr color="#efefef" />
              <div className="mt-2">
                <b className="bold  ">Attachments and Documents:</b>

                <span className="space-between  flex  align-center gap-2 ">
                  <div className=" flex gap-2  mt-1 column   ">
                    <span className=" flex align-center gap-1 ">
                      <span className="flex  space-between column ">
                        <b className="cercle-purple-text">Update Invoices</b>
                        <label
                          for="file-input"
                          className="vehicle-photo flex column "
                          style={{
                            height: "150px",
                            borderRadius: "10px",
                            width: "150px"
                          }}
                        >
                          <span
                            class="material-symbols-outlined upload-photo-icon "
                            style={{
                              fontSize: "100px"
                            }}
                          >
                            cloud_upload
                          </span>
                          <b>Upload</b>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>

                      <span className="flex  space-between column ">
                        <b className="cercle-purple-text">Update E-way bill</b>
                        <label
                          for="file-input"
                          className="vehicle-photo flex column "
                          style={{
                            height: "150px",
                            borderRadius: "10px",
                            width: "150px"
                          }}
                        >
                          <span
                            class="material-symbols-outlined upload-photo-icon "
                            style={{
                              fontSize: "100px"
                            }}
                          >
                            cloud_upload
                          </span>
                          <b>Upload</b>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>
                    </span>

                    <span className=" flex align-center gap-1 ">
                      <span className="flex  space-between column ">
                        <b className="cercle-purple-text">Update GRN File</b>
                        <label
                          for="file-input"
                          className="vehicle-photo flex column "
                          style={{
                            height: "150px",
                            borderRadius: "10px",
                            width: "150px"
                          }}
                        >
                          <span
                            class="material-symbols-outlined upload-photo-icon "
                            style={{
                              fontSize: "100px"
                            }}
                          >
                            cloud_upload
                          </span>
                          <b>Upload</b>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>

                      <span className="flex  space-between column ">
                        <b className="cercle-purple-text">Update Test Report</b>
                        <label
                          for="file-input"
                          className="vehicle-photo flex column "
                          style={{
                            height: "150px",
                            borderRadius: "10px",
                            width: "150px"
                          }}
                        >
                          <span
                            class="material-symbols-outlined upload-photo-icon "
                            style={{
                              fontSize: "100px"
                            }}
                          >
                            cloud_upload
                          </span>
                          <b>Upload</b>
                        </label>
                        <input
                          type="file"
                          id="file-input"
                          onChange={() => setTestReport(true)}
                        />
                      </span>
                    </span>
                  </div>
                  <PhotoGallery />
                </span>
              </div>
              <hr color="#efefef" />
              <div className="flex gap-1 justify-end mt-1">
                <button
                  className="btn-outline cercle-btn "
                  onClick={() => clearSingleTrack()}
                >
                  Reject
                </button>
                <button
                  className="btn cercle-btn green "
                  onClick={acceptPickup}
                >
                  Accept{" "}
                </button>
              </div>
            </>
          ) : (
            <div className="flex column align-center mt-2 ">
              <img
                src="../images/no-track.png"
                style={{ width: "300px", height: "300px" }}
              />
              <p className="grey-text normal-text ">No truck selected.</p>
              <p className="grey-text normal-text ">please select on truck.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MaterialInward;
