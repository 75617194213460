import React from 'react'

function TimeLine({color}) {
  return (
    <div className='time-line' >
     <div className='line'></div>
     <div className='time-line-dot' style={{ backgroundColor:color }}></div>
     <div className='line'></div>
    </div>
  )
}

export default TimeLine