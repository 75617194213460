import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Modal } from "react-materialize";
import {
  assignLocationToNetwork,
  getNetwork,
  getNetworkInvitations,
  rescindInvitation,
} from "../../redux/action/organization";
import AddPersonalNetwork from "./components/AddPersonalNetwork";
import { notify } from "../../helpers/helper";
import AddExternalNetwork from "./components/AddExternalNetwork";
import { useNavigate } from "react-router-dom";
import AddScrapToNetwork from "./components/AddScrapToNetwork";
import { ClipLoader } from "react-spinners";

function Network() {
  const { scraps } = useSelector((state) => state.scraps);
  const {
    network,
    networkLoading,
    invitations,
    loadingAccounts,
    organization: org,
  } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [location, setLocation] = React.useState("");
  const [search, setSearch] = React.useState("");
  const isTablet = window.innerWidth <= 1224;
  const [networkToShow, setNetworkToShow] = React.useState(network);

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getNetworkInvitations());
  }, [dispatch]);

  useEffect(() => {
    setNetworkToShow(
      network.filter((item) =>
        item.organization.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, network]);

  return (
    <div className="p-1">
      <div className="flex space-between">
        <p className="bold">My Network</p>

        <ClipLoader
          color="#6f2da8"
          size={20}
          loading={networkLoading || loadingAccounts}
        />
      </div>
      <div className="flex  space-between flex-wrap align-center">
        <div
          className="input-style flex align-center"
          style={{
            width: isTablet ? "100%" : "40%",
            padding: "0 10px",
            borderRadius: "10px",
            height: "40px",
          }}
        >
          <span className="material-symbols-outlined grey-text mr-1">
            search
          </span>
          <input
            className="browser-default "
            type="text"
            placeholder="Search for network"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="flex gap-1 mb-1">
          <Modal
            trigger={
              <button className="btn-small mt-1 flex">
                Add A Personal Network{" "}
              </button>
            }
            id="invitation-modal"
            actions={[]}
          >
            <AddPersonalNetwork />
          </Modal>
          <Modal
            trigger={
              <button className="btn-small white purple-text mt-1 flex">
                Add from Cercle X Network
              </button>
            }
            id="invitation-modal"
          >
            <AddExternalNetwork />
          </Modal>
        </div>
      </div>

      <div className="full-width box" style={{ backgroundColor: "#fff" }}>
        <table className="striped  payment-history  responsive-table">
          <thead>
            <tr>
              <th className="primary">#</th>
              <th className="primary">Name</th>
              <th className="primary">Role</th>
              <th className="primary">Owner</th>
              <th className="primary">Email</th>
              <th className="primary">Phone Number</th>
              <th className="primary">Assigned Location</th>
              <th className="primary">Associated Scraps</th>
              <th className="primary">Action</th>
            </tr>
          </thead>

          <tbody>
            {networkToShow.map((item, index) => {
              const { organization, role } = item;
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{organization.name}</td>
                  <td className="capitalize">{role}</td>
                  <td className="capitalize">{organization.owner?.name}</td>
                  <td className="lowercase">{organization.owner.email}</td>

                  <td>+{organization.owner.phoneNumber}</td>
                  <td className="capitalize">
                    {org.locations.find((location) =>
                      item.locations.includes(location._id)
                    )?.nickname || (
                      <Modal
                        trigger={
                          <p className="red-text pointer">
                            Click to assign location
                          </p>
                        }
                        id="assign-location-modal"
                      >
                        <p className="bold">Assign Location</p>

                        <div className="mt-1">
                          <label className="black-text">
                            Select a location to be associated with this network
                          </label>
                          <div className="row  ">
                            <select
                              className="input-style full-width browser-default "
                              name="location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                            >
                              <option value={""}>--select--</option>
                              {org.locations?.map((location, i) => (
                                <option value={location._id} key={i}>
                                  {location.nickname}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="flex gap-1">
                          <Button
                            className="btn  mt-1  "
                            onClick={() => {
                              dispatch(
                                assignLocationToNetwork(
                                  {
                                    network: organization._id,
                                    location,
                                  },
                                  () => {
                                    notify("Location Assigned");
                                    const elem = document.getElementById(
                                      "assign-location-modal"
                                    );
                                    const instance =
                                      window.M.Modal.getInstance(elem);
                                    instance.close();
                                  }
                                )
                              );
                            }}
                            disabled={
                              networkLoading ||
                              !location ||
                              !account.permissions.includes("assign-location")
                            }
                          >
                            {networkLoading ? (
                              <ClipLoader color="#fff" size={20} />
                            ) : (
                              "Assign Location"
                            )}
                          </Button>

                          <Button
                            modal="close"
                            className="btn-outline  mt-1 first-time-but "
                          >
                            Cancel
                          </Button>
                        </div>
                      </Modal>
                    )}
                  </td>

                  <td className="capitalize">
                    <div className="flex" style={{ gap: "5px" }}>
                      {!item.scraps.length ? (
                        <p>No Scrap Associated</p>
                      ) : (
                        item.scraps?.map((scrap, i) => (
                          <p>{scraps.find((s) => s._id === scrap)?.name}</p>
                        ))
                      )}
                    </div>
                  </td>

                  <td>
                    <Dropdown
                      trigger={
                        <div>
                          <i class="material-symbols-outlined">more_vert</i>
                        </div>
                      }
                    >
                      <span
                        onClick={() => {
                          navigate("/network/" + organization._id);
                        }}
                      >
                        View Details
                      </span>
                      {account.permissions.includes("update-network") && (
                        <span className="capitalize">
                          <AddScrapToNetwork
                            associatedScraps={item.scraps}
                            network={organization._id}
                          />
                        </span>
                      )}
                    </Dropdown>
                  </td>
                </tr>
              );
            })}

            {invitations.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{network.length + 1 + index}.</td>
                  <td className="capitalize">{item.organizationName}</td>
                  <td className="capitalize">{item.role}</td>
                  <td className="capitalize">N/A</td>
                  <td className="lowecase">
                    {item.email ? item.email : "N/A"}
                  </td>
                  <td>{item.phoneNumber ? "+" + item.phoneNumber : "N/A"}</td>
                  <td className="capitalize">
                    {org.locations.find(
                      (location) => location._id === item.location
                    )?.nickname || <p>No Location</p>}
                  </td>

                  <td className="capitalize">
                    <div className="flex" style={{ gap: "5px" }}>
                      {!item.scraps.length ? (
                        <p>No Scrap Associated</p>
                      ) : (
                        item.scraps?.map((scrap, i) => (
                          <p>{scraps.find((s) => s._id === scrap)?.name}</p>
                        ))
                      )}
                    </div>
                  </td>

                  <td>
                    <Modal
                      trigger={
                        <button className="btn-small white purple-text">
                          Cancel Invite
                        </button>
                      }
                      actions={[]}
                      id={"cancel-invitation-modal" + index}
                    >
                      <p className="bold">Revoke Invitation</p>
                      <p>
                        Are you sure you want to cancel this invitation? This
                        action cannot be undone.
                      </p>

                      <div className="flex mt-1 gap-1">
                        <button
                          className="btn "
                          onClick={() => {
                            dispatch(
                              rescindInvitation(item._id, () => {
                                let elem = document.getElementById(
                                  "cancel-invitation-modal" + index
                                );
                                let instance = window.M.Modal.getInstance(elem);
                                instance.close();
                                notify("Canceled Invitation");
                                dispatch(getNetworkInvitations());
                              })
                            );
                          }}
                          disabled={loadingAccounts}
                        >
                          Revoke
                        </button>

                        <Button className="btn-outline" modal="close">
                          Cancel
                        </Button>
                      </div>
                    </Modal>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {network?.length === 0 && (
          <div className="flex column align-center full-width white mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "3rem" }}
            >
              group
            </span>
            <p className="grey-text mt-1 ">
              You have no accepted network yet. Click on add network button to
              add a new
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Network;
