import React from "react";
import { FadeLoader } from "react-spinners";

function Loading() {
  return (
    <div className=" flex align-center justify-center column mt-2 ">
      <FadeLoader color="#6f2da8" size={10} />
      <p className="cercle-purple-text mt-1 " >loading...</p>
    </div>
  );
}

export default Loading;
